import React, { useEffect, useState } from "react";
import styles from "../styles/MoodsPage.module.css";
import moodsInfo from "../utils/data/moods";
import { useParams } from "react-router-dom";
import { FiShare2 } from "react-icons/fi";
import CustomButton from "../components/CustomButton/CustomButton";
import BgImageDiv from "../components/BgImageDiv/BgImageDiv";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { getSearchResultFaceted } from "../service/SearchService";
import getDirectLink from "../utils/AudioUrlFormated";
import setMetaTags from "../utils/SeoService";
import config from "../utils/config";
import { useSelector } from "react-redux";
import { TbFilterCheck } from "react-icons/tb";
import { IoIosClose } from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import TrackFilter from "../components/CardsComponent/TrackCard/TrackFilter";
import CleanSearchKey from "../utils/CleanSearchKey";

const MoodsPage = () => {
  const [page, setPage] = useState(1); // Dynamic page number
  const [results, setResults] = useState(null); // Store fetched results
  const [isFetching, setIsFetching] = useState(false); // Loading state
  const [showViewMore, setShowViewMore] = useState(false); // Control View More button visibility
  const [noDataFound, setNoDataFound] = useState(false); // State for "No data found"
  // const [initialLoadCompleted, setInitialLoadCompleted] = useState(false); // Track initial auto-fetch temporary
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [filters, setFilters] = useState({}); // state to manage the filters values
  const [sortBy, setSortBy] = useState({ type: "relevance", order: "" });

  const isGlobalPlayerActive = useSelector(
    (state) => state.ui.globalPlayerActive
  );

  const { moodsCode } = useParams(); // Extract the id from the URL

  const moodDataString = moodsInfo[moodsCode]
    ? JSON.stringify(moodsInfo[moodsCode])
    : null; // get the mood data based on code from json file

  const moodData = moodDataString ? JSON.parse(moodDataString) : {}; // parse the fetch info from json file
  // console.log(moodData,'148');

  useEffect(() => {
    setShowViewMore(false);
    setPage(1);
    setResults(null);
    // setIsFetching(false);
    setNoDataFound(false); // Reset "No data found" state
    setFilters({});
    setSortBy({ type: "relevance", order: "" });
    fetchResults();
  }, [moodsCode]);

  // to load the filter on component mounts
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem("trackFilters")) || {};
    setFilters(savedFilters);
  }, []);

  useEffect(() => {
    fetchResults();
    // if (initialLoadCompleted) {
    //   fetchResults();
    // } // temporary if
  }, [page]); // Re-run effect when page changes

  // this use effect will run when ever the filter is changing
  useEffect(() => {
    setPage(1);
    setResults(null);
    setShowViewMore(false);
    setNoDataFound(false); // Reset "No data found" state
    // setInitialLoadCompleted(false); // Reset auto-fetch state temporary
    fetchResults();
    setIsModalOpen(false);
  }, [filters]);

  // console.log(filters);

  // Effect to react to changes in sortBy
  useEffect(() => {
    // console.log("Sort By updated:", sortBy);
    // Logic to fetch or update the UI based on the new sortBy object
    // Check if type is price and max value is present

    setPage(1);
    setResults(null);
    setShowViewMore(false);
    setNoDataFound(false); // Reset "No data found" state
    // setInitialLoadCompleted(false); // Reset auto-fetch state temporary
    fetchResults();
    setIsModalOpen(false);
  }, [sortBy]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;

      // Log values
      // console.log("scrollTop:", scrollTop);
      // console.log("clientHeight:", clientHeight);
      // console.log("scrollHeight:", scrollHeight);
      // console.log("scrollTop + clientHeight:", scrollTop + clientHeight);
      // console.log("scrollHeight - 120:", scrollHeight - 120);

      // Avoid API call when fetching is in progress and when page >= 4
      if (
        scrollTop + clientHeight >= scrollHeight - 350 &&
        !isFetching &&
        page < 4 // Make sure the page is less than 4 before triggering
      ) {
        // console.log("Condition met: Loading more content");

        // Reached bottom and page is less than 4, load more
        setPage((prevPage) => prevPage + 1);
      }
    };

    // window.addEventListener("scroll", handleScroll);
    if (!showViewMore) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFetching, page, showViewMore]);

  // Auto-fetch for pages 1 through 4 temporary
  // useEffect(() => {
  //   const fetchAllPages = async () => {
  //     for (let i = 1; i <= 4; i++) {
  //       console.log(`Fetching page: ${i}`);
  //       setPage(i);
  //       await fetchResults(); // Wait for each fetch before moving to the next
  //     }
  //     setInitialLoadCompleted(true); // Mark auto-fetch as completed
  //   };

  //   if (!initialLoadCompleted) {
  //     fetchAllPages();
  //   }
  // }, [page]);

  // calling search api

  const fetchResults = async () => {
    // console.log("call");
    // console.log(filters, "filter val");

    try {
      setIsFetching(true);
      setNoDataFound(false); // Reset "No data found" state before fetching
      // const payload = {
      //   asset_type: "music",
      //   artists: [],
      //   bpm: { min: 30, max: 110 },
      //   genres: [],
      //   languages: [],
      //   moods: [],
      //   playlists: [],
      //   sfxcategories: [],
      //   sfxsubcategories: [],
      //   subgenres: [],
      //   usecases: [],
      // };

      // Construct the payload with dynamic filters
      const payload = {
        asset_type: "music",
        artists: [], // If no artists, use empty array
        bpm: { min: 1, max: 300 },
        price: { min: sortBy.min || "", max: sortBy.max || "" },
        genres:
          filters?.genres?.map((item) => CleanSearchKey(item.label)) || [],
        languages:
          filters?.languages?.map((item) => CleanSearchKey(item.label)) || [],
        moods:
          [CleanSearchKey(moodData.name)] ||
          filters?.moods?.map((item) => CleanSearchKey(item.label)) ||
          [],
        playlists: [],
        sfxcategories: [],
        sfxsubcategories: [],
        subgenres: filters?.subgenres || [],
        usecases:
          filters?.useCases?.map((item) => CleanSearchKey(item.label)) || [],
        instruments:
          filters?.instruments?.map((item) => CleanSearchKey(item.label)) || [],
      };

      // console.log(payload);

      // console.log(page);

      // Check and update sortby value if type is "relevance"

      let sortby = sortBy.type || "";
      // console.log(sortby, sortBy);

      if (sortby === "relevance") {
        sortby = "owner";
      }

      const order = sortBy.order || "";
      const searchKeyword = "";
      const data = await getSearchResultFaceted(
        searchKeyword,
        payload,
        page,
        sortby,
        order
      );
      const trackData = data.filter((item) => item.type === "music");
      // console.log(trackData, "trackData");

      if (trackData.length === 0) {
        setNoDataFound(true); // Set "No data found" if no results are returned
        if (page > 1) setShowViewMore(false); // Hide "View More" if no more results
      } else {
        setNoDataFound(false); // Reset "No data found" if results are returned

        // Filter out duplicates by track_code
        setResults((prevResults) => {
          const existingTrackCodes = new Set(
            prevResults?.map((track) => track.track_code) || []
          );
          const uniqueTracks = trackData.filter(
            (track) => !existingTrackCodes.has(track.track_code)
          );
          return prevResults ? [...prevResults, ...uniqueTracks] : uniqueTracks;
        });

        if (page >= 4) {
          setShowViewMore(true); // Show "View More" if the page limit is reached
        }
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const seoData = {
      title: `${moodData.name} Songs | Music Licensing | Hoopr Smash`,
      description: `License ${moodData.name} songs with Hoopr Smash. Royalty-free ${moodData.name} music to enhance creativity and add depth to diverse projects.`,
      ogImage: `${config.s3imgUrl}web/moods/${moodsCode}.webp`,
      canonical: window.location.href,
      // lang: "en",
    };
    setMetaTags(seoData);
  }, [moodData]);

  // function to load more track on click of view more btn
  const handleViewMoreClick = () => {
    setPage((prevPage) => prevPage + 1); // Increment page on button click
  };

  // formatting the track cards
  const allTrackList =
    results?.map((track) => {
      const trackSKu = track.sku[0];
      // console.log(trackSKu);
      const parsedSKU = JSON.parse(trackSKu);

      // const SKU = [
      //   {
      //     costPrice: 69999,
      //     sellingPrice: 49999,
      //     gstPercent: 18,
      //     maxUsage: 3,
      //   },
      // ];

      const SKU = [
        {
          costPrice: parsedSKU.cost_price,
          sellingPrice: parsedSKU.selling_price,
          gstPercent: parsedSKU.gst_percent,
          maxUsage: parsedSKU.max_usage,
          id: parsedSKU.id,
        },
      ];

      // Extract costPrice and sellingPrice
      const { costPrice, sellingPrice, id } = SKU[0];

      // Calculate discount percentage
      const discountPercent = (
        ((costPrice - sellingPrice) / costPrice) *
        100
      ).toFixed(0);

      const artistNames = JSON.parse(track.tracks_artist_role || "[]") // Ensure valid JSON
        .filter((item) => item.role === "primary")
        .map((item) => item.artist.name)
        .join(", ");

      return {
        image:
          track.image_url !== "" && track.image_url != null
            ? track.image_url
            : randomDefaultImage("track"),
        title:
          track.name.length > 18
            ? track.name.substring(0, 17) + "..."
            : track.name,
        description:
          artistNames.length > 30
            ? artistNames.substring(0, 29) + "..."
            : artistNames,
        buttonText: "Explore",
        buttonLink: "#",
        discountPercent: discountPercent || "50% OFF",
        costPrice: costPrice.toLocaleString() || "19,999",
        sellingPrice: sellingPrice.toLocaleString() || "79,000",
        name: track.name_slug,
        trackCode: track.track_code,
        waveJson: track.waveform_link,
        trackUrl:
          track.mp3_link || getDirectLink(track.link) || track.waveform_link,
        skuId: id || "",
      };
    }) || [];

  // console.log(allTrackList, "allTrackList");

  // Toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Close the modal if the click happens outside the modalContainer
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleFiltersSelected = (filters) => {
    // console.log("Updated Filters:", filters);
    setFilters(filters);
    // You can use the updated filters object here to fetch data or update the UI
  };

  const handleSortByChange = (e) => {
    const [type, order] = e.target.value.split("-");
    setSortBy({
      type,
      order: order || "",
      min: "",
      max: "",
    });
  };

  // console.log("Global Player Active : ", isGlobalPlayerActive); // Log the value
  const overlayClass = isGlobalPlayerActive
    ? styles.modalOverlayWithPlayer
    : styles.modalOverlay;

  const hasActiveFilters =
    Object.values(filters).some(
      (filter) => Array.isArray(filter) && filter.length > 0
    ) || sortBy.type !== "relevance";

  return (
    <div className={styles.moodContainer}>
      {/* Rotated Button */}
      <div className={styles.rotateButton} onClick={toggleModal}>
        {hasActiveFilters ? (
          <div className={styles.filterActiveContainer}>
            <TbFilterCheck size={22} className={styles.filterActive} />
          </div>
        ) : (
          <FaFilter size={22} className={styles.filterIcon} />
        )}
        <span>Sort & Filters</span>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className={overlayClass} onClick={handleOverlayClick}>
          <div className={styles.modalContainer}>
            {/* header section */}
            <div className={styles.header}>
              <div className={styles.heading}>
                <p className={styles.headingtext}>Sort & Filters</p>
              </div>
              <IoIosClose
                onClick={closeModal}
                color="#000"
                cursor="pointer"
                size={34}
              />
            </div>
            <br></br>
            <div className={styles.filterWapper}>
              {/* Sort By Section */}
              <div className={styles.filtersSection}>
                <div className={styles.filterHeading}>
                  <p>Sort By</p>
                </div>
                <hr style={{ color: "#555" }} />
                <div className={styles.sortOptions}>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="relevance"
                      checked={sortBy.type === "relevance"}
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Relevance
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="price-asc"
                      checked={
                        sortBy.type === "price" && sortBy.order === "asc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Price (low to high)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="price-desc"
                      checked={
                        sortBy.type === "price" && sortBy.order === "desc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Price (high to low)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="name-asc"
                      checked={sortBy.type === "name" && sortBy.order === "asc"}
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Name (Asc)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="name-desc"
                      checked={
                        sortBy.type === "name" && sortBy.order === "desc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Name (Desc)
                  </label>
                </div>
              </div>
              {/* Filter Section */}
              <div style={{ marginTop: 20 }} className={styles.filtersSection}>
                <div className={styles.filterHeading}>
                  <p>Filter</p>
                  {/* <IoIosArrowDown /> */}
                </div>
                <hr style={{ color: "#555" }} />
                <div>
                  <TrackFilter
                    onFiltersSelected={handleFiltersSelected}
                    hideApply={true}
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* mood details */}
      <div className={`${styles.mpContainer} ${styles.outerFull}`}>
        <div className={`${styles.inner} ${styles.innerMp}`}>
          <div className={styles.mpDetailsSection}>
            <BgImageDiv
              type="mood"
              id={moodsCode}
              divClass={styles.mpImage}
            ></BgImageDiv>
            {/* mood details */}
            <div className={styles.mpDetails}>
              <div className={styles.mpNames}>
                <h1>{moodData.name || "moodName"}</h1>
                <h2>{moodData.description || ""}</h2>
              </div>
            </div>
          </div>
          {/* like share button */}
          <div className={styles.mpActionButton}>
            <div>
              <CustomButton
                isIconOnly={true}
                backgroundColor="#F3F3F3"
                borderRadius="50px"
                iconColor="#000"
                icon={<FiShare2 />}
                width="34px"
                height="34px"
                iconSize="16px"
              />
            </div>
          </div>
        </div>
      </div>
      {/* mood based tracks section */}
      <div className={`${styles.moodTracks} ${styles.inner}`}>
        <div className={styles.mtHeading}>
          <h1>{moodData.name} Songs</h1>
          {hasActiveFilters && (
            <p
              onClick={() => {
                setFilters({});
                localStorage.removeItem("trackFilters");
                setSortBy({ type: "relevance", order: "" });
              }}
              className={styles.clearFilterText}
            >
              Clear Filters
            </p>
          )}
        </div>
        <div className={styles.mtTrack}>
          <TrackCard trackType="contemporary" trackCard={allTrackList} />
          {isFetching && (
            <p style={{ textAlign: "center", marginTop: "30px" }}>
              Loading more tracks...
            </p>
          )}
          {noDataFound && (
            <p style={{ textAlign: "center", marginTop: "30px" }}>
              No data found.
            </p>
          )}
        </div>
      </div>
      {showViewMore && !noDataFound && (
        <button className={styles.viewMoreBtn} onClick={handleViewMoreClick}>
          View More
        </button>
      )}
    </div>
  );
};

export default MoodsPage;
