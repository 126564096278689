import React, { useState } from "react";
import randomDefaultImage from "../../../utils/RandomDefaultImage";
import { FaPlay, FaPause } from "react-icons/fa";
import styles from "./MiniTrack.module.css";
import { useGlobalPlayer } from "../../../utils/GlobalPlayerContext";
import { useDispatch } from "react-redux";

const MiniTrackCard = ({ trackInfo }) => {
  const [activeTrackCode, setActiveTrackCode] = useState(null); // Track currently playing
  const { isPlaying, setIsPlaying, showPlayer } = useGlobalPlayer();
  const dispatch = useDispatch(); // Hook to dispatch actions

  // Function to handle play button click
  const handlePlayClick = () => {
    // Log the clicked track info
    // console.log("Clicked Track Info:", trackInfo);

    // Use the trackInfo passed as a prop
    const trackData = {
      trackImg: trackInfo.cardImg,
      trackName: trackInfo.preText,
      trackArtistName: trackInfo.secText,
      trackDuration: "03:25",
      trackCode: trackInfo.code,
      trackUrl: trackInfo.trackUrl,
      trackJson: trackInfo.trackJson,
    };

    // Check if track is playing and then update the player
    if (isPlaying) {
      showPlayer(trackData);
      setIsPlaying(isPlaying);
    }
    showPlayer(trackData);

    setActiveTrackCode(trackInfo.code);
  };

  return (
    <div
      className={styles.trackCard}
      style={{
        backgroundImage: `url(${
          trackInfo.cardImg || randomDefaultImage("track")
        })`,
      }}
    >
      <div className={styles.trackPlayBtn} onClick={handlePlayClick}>
        {activeTrackCode === trackInfo.code && isPlaying ? (
          <FaPause color="#000" size={18} />
        ) : (
          <FaPlay color="#000" size={18} />
        )}
      </div>
    </div>
  );
};

export default MiniTrackCard;
