import React from "react";

const privacyPolicy = [
  {
    sectionTitle: "",
    content: [
      {
        type: "paragraph",
        text: "At GSharp Media Private Limited having its registered office at A-1203, Serenity Complex, Off Link Road, Oshiwara West Mumbai 400102 (referred to as “GSharp” “Company,” “us” or “we”), we recognize that your privacy is important. This Policy discloses the privacy practices for the Company’s family of applications and/or Websites (collectively, the “Site”), as well as related products and services we may offer to you (collectively referred to as the “Services”). This Policy also covers how personal and other information that we receive or collect about you is treated (“Privacy Policy”). Please read the information below to learn the following regarding your use of this Site.",
      },
      {
        type: "paragraph",
        text: "You acknowledge that this Privacy Policy is designed to be read in connection with the Site Terms of Use (https://hoopr.ai/terms-conditions) and Hoopr Smash Terms of Use (https://hooprsmash.com/terms), and that by accessing or using our Site, you agree to be bound by the Site’s General Terms and conditions, this Privacy Policy, as well as any other Terms made available on the Site.",
      },
      {
        type: "paragraph",
        text: "We reserve the right to change this Privacy Policy from time to time. Any significant changes we may make to our Privacy Policy in the future will be promptly notified to you by posting the relevant terms in a prominent position on the Site. The new terms may be displayed on the Site and you will be required to read and accept them to continue your use of the Services. Your continued use of the Site and or Services available through this Site after such modifications will constitute your: (a)acknowledgment of the modified Policy; and (b)your agreement to abide and be bound by that Policy.",
      },
      {
        type: "paragraph",
        text: "By using this Site and/or the offerings available on it, you give your consent that all personal data you submit may be processed by us in the manner and for the purposes described below. This includes, but is not limited to, data collection, storage, analysis, and sharing as necessary to provide and improve our services. Please refrain from using this Site if you do not accept these terms and conditions.",
      },
    ],
  },
  {
    sectionTitle: "Types of Information We Collect",
    content: [
      {
        type: "paragraph",
        text: "We collect two types of information about our you: Personally Identifiable Information (“PII”) and Aggregate Information.",
      },
      {
        type: "paragraph",
        text: "Personally Identifiable Information: This pertains to any information that identifies you personally. When you participate in certain activities on this Site—such as registering for an account, downloading or purchasing a product or service, submitting or posting content, or sending us feedback—we may request or gather specific details about you. This can include, but is not limited to, your first and last name, age, email address, billing address (including ZIP code), IP address, payment information, preferences, and other identifying data.",
      },
      {
        type: "paragraph",
        text: "Aggregate Information: This pertains to information that, on its own, does not identify a specific individual. We collect certain data based on your activity on our Site and the Sites that referred you to us. This information, gathered through various methods, is compiled and analyzed both individually and in aggregate. It may include the Uniform Resource Locator (“URL”) of the Site you visited before ours, the URL you visit after leaving our Site, the browser you are using, and your Internet Protocol (“IP”) address. ",
      },
    ],
  },
  {
    sectionTitle: "How We Collect Information",
    content: [
      {
        type: "paragraph",
        text: "We do not collect any PII about you unless you voluntarily provide it to us. However, certain PII shall be collected when you elect to use certain products or services available on the Site. These may include:",
      },
      {
        type: "list",
        items: [
          "collection of PII when you visit the Site or register for an account on our Site;",
          "collecting PII (whether you are registered or not registered) when you browse the Sites, open or respond to an email from us (promotional or informational);",
          "when you raise a query/question to us through phone or email;",
          "when you register with us by linking your social media platforms or third-party accounts. By doing this, you authorize such social media platforms to share with us, certain information from such third-party accounts, and authorizes us to collect, store, and use the PII in accordance with this Privacy Policy;",
          "from you using third party tools, browser cookies and web beacons in order to improve user experience.",
          "	Device Information: We may collect non-personal information about the computer, mobile device or other device you use to access the service, such as IP address, geo-location information, unique device identifiers, browser type, browser language and other information for the purpose of providing customized information on the browser.",
          "Location Information: Our Site may capture your PII such as current location if you choose to enable GPS feature in the app or browser.",
        ],
      },
    ],
  },
  {
    sectionTitle: "Third Party Services",
    content: [
      {
        type: "We may allow you to connect a YouTube channel to your account on the Site. In this case, we will access certain information from YouTube about your channel by using YouTube API Services. We may store your channel ID for reference when creating licenses, which will be treated in accordance with this Privacy Policy. We may share your channel id to our content ID licensing partner who facilitates our content ID licensing. If you decide to connect a YouTube channel to your account, you will also be subject to Google’s privacy policy available here: http://www.google.com/policies/privacy. When disconnecting a YouTube channel on our Site, We will delete the stored data associated with that YouTube channel. You can also revoke access to the data we collect via the YouTube API Services by changing your security settings on Google here: https://security.google.com/settings/security/permissions.",
      },
    ],
  },
  {
    sectionTitle: "How We Process Information",
    content: [
      {
        type: "paragraph",
        text: "We will primarily use your PII to provide you with products or services. When processing certain information, we encrypt the transaction, using Secure Socket Layer (SSL) encryption technology, in order to prevent your PII from being stolen or intercepted. These may include processing of PII:",
      },
      {
        type: "list",
        items: [
          "to enhance the functionality of our Site, improve our marketing and promotional efforts, analyze Site usage statistically, enhance our product and service offerings, and customize our Site’s content, layout, and services.",
          "to help facilitate in-app purchases & withdrawals and make subscribed and paid content available to you;",
          "to provide you with marketing communications, advertising, news, special offers, and general information about the products and services;",
          "to communicate with you for offers and information about the Services and our affiliates, , new features that you are entitled to access, and any other important information/ communication. You may opt out of receiving commercial email messages from us by following the instructions contained in those email messages;",
          "to validate, authorize and map a specific profile with an authorized user;",
          "for the performance of a contractual obligation;",
          "to resolve disputes, troubleshoot issues, and enforce our agreements with you, including our Site Terms of Use and this Privacy Policy",
          "to provide and process service requests initiated by you;",
          "to diagnose server or software problems, manage our Site, and gather demographic data.",
          "to facilitate your participation in various contests or programs conducted by the Company, affiliates, subsidiaries, or agents on the Platform.",
          "We may use your  PII in the event we believe it is necessary or appropriate to protect, enforce, or defend the legal rights, privacy, safety, or property of the Services, its employees or agents, or other users and to comply with applicable laws.",
        ],
      },
    ],
  },
  {
    sectionTitle: "Data Retention",
    content: [
      {
        type: "paragraph",
        text: "We may retain and use your information as necessary to comply with our legal obligations. We may also retain and use your information for business, statistical or other purposes as allowed under applicable laws in your respective country of residence. ",
      },
    ],
  },
  {
    sectionTitle: "Cookies",
    content: [
      {
        type: "paragraph",
        text: "Depending on how you use our Site, we may store cookies on your computer in order to collect certain aggregate data about our users and to customize certain aspects of your specific user experience (“Cookie(s)”). ",
      },
      {
        type: "paragraph",
        text: "A Cookie is a small data text file stored on your computer that uniquely identifies your browser. Cookies can also contain more personalized information, such as your IP address, browser type, the server your computer is connected to, and the area code and ZIP code associated with your server, as well as your first name for welcoming you back to our Site. We use Cookies to monitor website usage, including estimating audience size, consumer behavior, user navigation, and usage patterns, which helps us provide better service. Cookies also simplify your future visits to the website and allow us to personalize content by recording your preferences. We use both persistent Cookies (which expire on a set date) and session Cookies (which expire when you close your browser). Please note that third parties (such as providers of external services like web traffic analysis services like Google Analytics or advertising services) may also use Cookies, over which we have no control. These Cookies are usually analytical/performance Cookies or targeting Cookies.",
      },
      {
        type: "paragraph",
        text: "We may use Cookies to perform tasks such as: monitoring aggregate Site usage metrics, storing and remembering your passwords (if you allow us to do so), storing account and advertising preferences that you have set, and personalizing the Services we make available to you. Most browsers are initially set up to accept Cookies, but you can reset your browser to refuse all Cookies or to indicate when a cookie is being sent. However, some aspects of the Site may not function properly if you elect to disable Cookies.",
      },
      {
        type: "paragraph",
        text: "You also have choices with respect to Cookies, as described above. By modifying your browser preferences, you have the choice to accept all Cookies, to be notified when a cookie is set, or to reject all Cookies. If you choose to reject all Cookies some parts of our Site may not work properly in your case.",
      },
      {
        type: "paragraph",
        text: "We also use local storage (“localStorage”) to store relevant information, including login session details, which enables you to maintain your login status across sessions. Such data however is not accessible to third parties. By using the Site, you provide implicit consent to the use of localStorage for the aforementioned purposes.",
      },
    ],
  },
  {
    sectionTitle: "Release of Information",
    content: [
      {
        type: "paragraph",
        text: "We do provide some of our products and services through contractual arrangements made with affiliates, service providers, partners and other third parties (“Service Partners”). We and our Service Partners may need to use some PII in order to perform tasks between our respective Sites, or to deliver products or services to you. We will encourage our Service Partners to adopt and promote strong privacy policies but we do not take any guarantee or any liability for non-adherence of these policies by our Services Partners. However, the use of your PII by our Service Partners is governed by the respective privacy policies of those providers, and is not subject to our control. Except as otherwise discussed in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. Other Sites accessible through this Site, including our Advertising and Service Partners, have their own privacy policies and data collection, use and disclosure practices. We are not responsible for the policies or practices of third parties. . We shall enter into contractual obligations with such Service Partner to ensure that they do not disclose, share, sell or misuse any PII shared by you, however in the case that there is a misuse of your PII, you hereby agree and acknowledge to pursue legal action if you wish, against any such Service Partner and agree to hold harmless and keep indemnified Company from any claim, legal action or suit that may be instituted.",
      },
      {
        type: "paragraph",
        text: "Occasionally, we may be legally required to provide PII to government authorities. In such instances, we will disclose PII upon receiving a statutory regulation, court order, or subpoena, or to cooperate with a law enforcement investigation. We fully cooperate with law enforcement agencies to identify individuals using our services for illegal activities. We reserve the right to report any activities we believe in good faith to be unlawful to law enforcement agencies. We may also provide Aggregate Information about our customers’ sales, traffic patterns, and related Site information to third party advertisers, but these statistics do not include any PII.",
      },
      {
        type: "paragraph",
        text: "We may share the PII (and other information) with our affiliates and/or third-parties when necessary, to provide you with the service infrastructure and the related, to provide advertising and promotional services, to provide search results and links (including paid listings and links), to process financial transactions, to provide customer service, and any such relevant and necessary purposes which are essential for us making our Services available to you. We will never display, rent, or sell your personally identifiable information to others.",
      },
      {
        type: "list",
        items: [
          "As required by law, such as to comply with a judicial order, executive orders, requirement by a law enforcement authority, or by other legal processes;",
          "To protect and guard the rights and interests of the Company;",
          "When we are required to provide information to the companies that provide products or services on our behalf; (unless we notify you otherwise, these companies do not have the right to use your personally identifiable information);",
          "When we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, or, investigate fraud or crime;",
          "If we (or our affiliates) are involved in a merger, acquisition, or sale of all or substantially all of its assets or equity.",
          "To other third parties where you have provided your consent.",
        ],
      },
    ],
  },
  {
    sectionTitle: "Security of Your PII",
    content: [
      {
        type: "paragraph",
        text: "We work hard to ensure that the data we collect is reliable, accurate, complete and current. We encrypt all PII, in order to prevent unauthorized parties from viewing such information when it is transmitted to us. We also only keep collected information indefinitely and use it only for the purposes for which it was collected or to comply with any applicable legal or ethical reporting or document retention requirements. We limit access to PII only to specific employees, contractors and agents who have a reasonable need to come into contact with your information. For example, we may provide members of our technical support team with limited access to your account in order to allow them to troubleshoot problems you may be having with the Site. We also employ a number of physical, electronic, and procedural safeguards to protect PII. Our secure servers and our data centers are protected by industry-standard encryption, and our servers reside behind firewalls and employ high-level password protection.\nFinally, access by you to your PII is available through a password and unique customer ID selected by you. This password is encrypted. We recommend that you do not divulge your password to anyone.",
      },
      {
        type: "paragraph",
        text: "We shall notify you in case of any security breach that affects You in the form and manner as prescribed under the Digital Personal Data Protection Act, 2023 and its subsequent rules (as and when notified by the central government). We endeavour to comply with applicable norms and have a number of security measures to ensure there is no loss, misuse, or illegal alteration of information collected from its users. Information we collect is securely stored within our databases, and we use standard, industry-wide, commercially reasonable security practices for protecting your information. However, no security system is impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that information you supply won't be intercepted while being transmitted to us over the internet or wireless communication and any information you transmit to Us you do at your own risk. If, despite our best efforts, an unauthorised breach of our security measures occurs, resulting in the unauthorised access, duplication, removal or alteration of any Information, third party information, or other information, and We, our directors, officers, employees, promoters, or affiliates shall not be held responsible for any loss resulting from such breach.",
      },
      {
        type: "paragraph",
        text: "As a result, while we strive to protect your PII, you acknowledge that there are security and privacy limitations inherent to the Internet which are beyond our control; and the security, integrity and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.",
      },
    ],
  },
  {
    sectionTitle: "Data Transfer",
    content: [
      {
        type: "paragraph",
        text: "Your PII may be stored and processed in India or any other country, unless restricted by the Central Government under the Digital Personal Data Protection Act, 2023 or subsequent rules (as and when notified by the central government), in which We or our subsidiaries, affiliates, or products or service providers maintain facilities. We may transfer information that we collect about you, including PII, to affiliated entities, or to other third parties across borders and from your country or jurisdiction to other countries or jurisdictions around the world. These countries may have data protection laws that are different from the laws of your country and, in some cases, may not be as protective.",
      },
    ],
  },
  {
    sectionTitle: "Updating and Correcting Information",
    content: [
      {
        type: "paragraph",
        text: "We believe you should have the ability to access and edit the PII that you have provided to us. You may change any of your PII in your account online at any time by linking to your account in accordance with instructions posted elsewhere on this Site. You may also access and correct your personal information and privacy preferences by emailing us at support@hoopr.ai; or writing to us at:\nAttn: Privacy Compliance Officer\nsupport@hoopr.ai;",
      },
      {
        type: "paragraph",
        text: "Please include your name, address, and/or email address when you contact us.\nWe recommend that you promptly update your (PII) if there are any changes. Depending on where you are located, you may have extra rights regarding your information. For example, you may request deletion of your data, including having us remove personal information unless we are required to keep it, by contacting us at the address above. We may also delete your data if requested by our customers who have shared your information with us, except for data we are obligated to retain. You may also have the right to withdraw consent or object to processing, which may include asking us to stop processing your personal data in certain situations, by contacting us at the address above.",
      },
      {
        type: "list",
        items: [
          "The right to access the information we hold about you, and to obtain details of processing;",
          "The right to withdraw your consent to our continued processing of the information, at any point of time, subject only to any legitimate reason for continued processing in certain circumstances;",
          "The right to edit your account information and preferences available with us;",
          "The right to seek from us your personal data to be provided to another company;",
          "The right to erasure of any personal data that we process, in certain circumstances, to the extent permissible in applicable law, and feasible; and,",
          "The right to move your information to another service provider, in a compatible format. This will only cover such information which we have received from you.",
        ],
      },
    ],
  },
  {
    sectionTitle: "User Rights",
    content: [
      {
        type: "paragraph",
        text: "Your Right of Withdrawal: You shall have the right to withdraw your consent at any time after sending a request mail to our consent manager at ________ (“Consent Manager”) , who shall within a reasonable time from your request, take appropriate action required under the data privacy laws in your respective country.  However, it is clarified that, such withdrawal shall not affect the legality of processing of the PII based on consent given by you before the withdrawal. In case of withdrawal of consent, we will have to close and delete your account and access details to the Site and/or Services, in whole or in part, in order to delete your Information and you will not be able to access the Site and/or Services. We will retain the required PII from closed accounts to comply with law, avoid/check illegal and/or potentially prohibited activities and to enforce contractual obligations; Comply with any court judgment / decree / order / directive / legal & government authority /applicable law; Investigate potential violations or applicable national & international laws; Investigate deliberate damage to the website/services or its legitimate operation; Detect, prevent, or otherwise address security and/or technical issues; safeguard the rights, property or safety of Company and/or its directors, employees and the general public at large; Respond to Claims of third parties; and take such other actions as may be permitted by law.",
      },
      {
        type: "paragraph",
        text: "It is hereby informed to you, and you hereby agree that, no consent is required for the processing of any PII which is processed, however, we may give notice of use in such situations for individuals residing outside India (save and except European citizens or users whose data is received directly from Europe, wherein the notice shall be served in the manner decided by Company:",
      },
      {
        type: "list",
        items: [
          "for necessary purpose of research, archiving or statistical purposes if the PII is not to be used to take any decision specific to a data principal and such processing;",
          "for enforcing any legal right or claim; ",
          "in the interest of prevention, detection, investigation or prosecution of any offence or contravention of any law;",
          "PII of users not within the territory of India pursuant to any contract entered into with any person outside the territory of India;",
          "for scheme of compromise or arrangement or merger or amalgamation of two or more companies or a reconstruction by way of a demerger or otherwise or transfer of undertaking of one or more company to another company or involving division of one or more companies; and",
          "the processing is for the purpose of ascertaining the financial information and assets and liabilities of any person who has defaulted in payment due on account of a loan or advance taken from a financial institution, subject to such processing being in accordance with the provisions regarding disclosure of information or data in any other law for the time being in force.",
        ],
      },
      {
        type: "paragraph",
        text: "Your Right of Grievance Redressal: You shall have the right to have readily available means of grievance redressal by emailing your grievances to our Consent Manager at ______  in respect of any act or omission of us or the Consent Manager regarding the performance of its obligations in relation to your PII or in relation to the exercise of your rights under the Digital Data Protection Act, 2023.",
      },
      {
        type: "paragraph",
        text: "Your Right to Approach the Data Protection Board: You shall have the right to approach and make a complaint to the Data Protection Board   in the manner as specified under the Digital Data Protection Act, 2023 or subsequent rules (as and when notified by the central government). However, this right should be only exercised after exercising your right of grievance redressal with the Company.",
      },
    ],
  },
  {
    sectionTitle: "Miscellaneous",
    content: [
      {
        type: "paragraph",
        text: "You must be above the relevant legal age in your country as may be required for you to have our permission to use this Site. Our policy is that we do not knowingly collect, use or disclose PII about children under below such relevant legal age. You must have attained the age of majority to be able to use and access our Services. As a parent or legal guardian, please do not allow your minors under your care to submit Personal Information to us. In the event that such PII of minor is disclosed to us, you hereby consent to the processing of the minor’s PII and accept and agree to be bound by this Privacy Policy and take responsibility for his or her actions.",
      },
      {
        type: "paragraph",
        text: "You should also be aware that when PII is voluntarily disclosed (i.e., your name, email address, etc.) in the discussion forums or other public areas on this Site, that information, along with any information disclosed in your communication, can be collected and used by third parties and may result in unsolicited messages (including unwanted spam messages) from third parties. Such activities are beyond our control and this Policy does not apply to such information. Please consult our Site Terms of Use for our Site conduct policies.",
      },
      {
        type: "paragraph",
        text: "If we make changes to this Privacy Notice, we will notify you by posting a copy of the updated policy on our Services prior to any change becoming effective. We will post a copy of the updated policy on our Services prior to any change becoming effective. If your consent is required due to the changes, we will provide you additional prominent notice as appropriate under the circumstances and ask for your consent in accordance with applicable law.",
      },
      {
        type: "paragraph",
        text: "If you have any questions, concerns or inquiries about our Privacy Policy, or our use of your PII, or our privacy practices, please contact us at support@hoopr.ai.You can also reach our Privacy Compliance Officer by sending written correspondence to:\nAttn: Privacy Compliance Officer\nsupport@hoopr.ai;",
      },
    ],
  },
];

const PrivacyPolicy = () => {
  return (
    <div className="outer" style={styles.container}>
      <div className="inner">
        <h1 style={{ fontSize: "36px" }}>Privacy Policy</h1>
        {privacyPolicy.map((section, sectionIndex) => (
          <div key={sectionIndex} style={styles.section}>
            <h1 style={styles.sectionTitle}>{section.sectionTitle}</h1>
            {section.content.map((content, contentIndex) => {
              if (content.type === "paragraph") {
                return (
                  <p key={contentIndex} style={styles.paragraph}>
                    {content.text}
                  </p>
                );
              } else if (content.type === "list") {
                return (
                  <ul key={contentIndex} style={styles.list}>
                    {content.items.map((item, itemIndex) => (
                      <li key={itemIndex} style={styles.listItem}>
                        {item}
                      </li>
                    ))}
                  </ul>
                );
              }
              return null;
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Inter",
    padding: "20px",
    backgroundColor: "#fff",
    color: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "880px",
    marginTop: "70px",
  },
  section: {
    marginBottom: "40px",
  },
  sectionTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  paragraph: {
    fontSize: "16px",
    lineHeight: "1.5",
    marginBottom: "15px",
  },
  list: {
    paddingLeft: "20px",
    marginBottom: "20px",
  },
  listItem: {
    fontSize: "16px",
    lineHeight: "1.5",
    marginBottom: "5px",
  },
};

export default PrivacyPolicy;
