import React, { useState, useContext, useEffect } from "react";
import styles from "../styles/Checkout.module.css";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomInput from "../components/CustomInput/CustomInput";
import { IoIosClose } from "react-icons/io";
import Select from "react-select";
import { FiEdit } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { ToastContext } from "../utils/ToastContext";
// import { useNavigate } from "react-router-dom";
// import { clearCart } from "../utils/redux/cartSlice";
import PopUpModal from "../components/PopUpModal/PopUpModal";
import {
  createOrder,
  startTransaction,
  commitTransaction,
  getCart,
} from "../service/TransactionService";
import config from "../utils/config";
import {
  userAllAddress,
  createAddress,
  getUserProfile,
} from "../service/UserService";
import Login from "./Login";
import { addToCart } from "../utils/redux/cartSlice";
import EmptyState from "../components/EmptyStates/EmptyState";

const Checkout = () => {
  const [billingInfo, setBillingInfo] = useState([]); // user billing info data
  const [isChecked, setIsChecked] = useState(false); // state for checkbox for t&c
  const [defaultBillingChecked, setDefaultBillingChecked] = useState(false); // state for checkbox of default billing info
  const [showModal, setShowModal] = useState(false); // Modal visibility state for displaying the user billing info
  const [phoneNumber, setPhoneNumber] = useState(""); // phone number
  const [editIndex, setEditIndex] = useState(null); // Track index of the billing info being edited
  const [popupMsg, setPopupMsg] = useState(null); // state to manage the message to be displayed in popup modal
  const [isModalOpen, setIsModalOpen] = useState(false); // toggle thankyou(purchase success) popup modal
  const [userProfile, setUserProfile] = useState(null); // state to amange the user prfile info
  const [isIncompleteProfile, setIsIncompleteProfile] = useState(false); // state to manage the profile completion status
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // toggle login modal

  // Input states for user info billing
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [pincode, setPincode] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");

  const [selectedOption, setSelectedOption] = useState(""); // state to manage the selected user billing details

  const cartItems = useSelector((state) => state.cart.items); // Access cart items from Redux store
  // console.log(cartItems, "cart iten from redux");

  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  // const navigate = useNavigate(); // Hook to navigate programmatically
  const dispatch = useDispatch();

  // use effect to fetch the cart if in case the data is not in the redux, preventing if user refresh this page then data from redux
  // will be empty there fore when empty call the cart api and update in the redux.
  useEffect(() => {
    if (cartItems.length === 0) {
      // console.log("redux empty so called api");
      const fetchCart = async () => {
        const fetchCart = await getCart();
        // console.log(fetchCart, "fetching cart data");

        if (fetchCart?.error?.code === 0) {
          const cartInfo = fetchCart.data.items.map((item) => {
            // Extract data from API response to build cartInfo object
            return {
              cardType: "track", // assuming all items are track types
              preText: item.item.name, // track name
              secText: item.item.tracksArtistRole
                .map((artist) => artist.artist.name)
                .join(", "), // artist names
              code: item.item.trackCode, // track code
              sp: item.sku.sellingPrice.toLocaleString("en-IN"), // selling price
              cp: item.sku.costPrice.toLocaleString("en-IN"), // assuming selling price is the same as cost price
              cardImg: item.item.image || "", // use item ID or another field for the image
              trackJson: "",
              trackUrl: "",
              skuID: item.sku.id, // sku id
            };
          });
          // Dispatch each cart item separately to Redux
          // note if you see the number twice or doubled then comment the strict mode of react in local and check
          cartInfo.forEach((item) => {
            dispatch(addToCart(item)); // Dispatch each item to the Redux store
          });
          // Log the cartItems or use it as needed
          // console.log(cartInfo, "formatted cart items");
        } else {
          // showToast("Error", "Failed to get cart", "error"); // Show error toast if API fails
        }
      };
      fetchCart();
    } else {
      return;
    }
  }, []);

  // use effect to fetch the user address for the billing info
  useEffect(() => {
    const fetchUserAddress = async () => {
      const userAddress = await userAllAddress();
      // console.log(userAddress, "address");
      // Map the API response
      const billingData = userAddress.data.map((item) => ({
        companyName: item.first_name || "",
        phoneNumber: item.mobile,
        email: item.email,
        addressLine1: item.addressLine1,
        addressLine2: item.addressLine2,
        city: "",
        state: "",
        pincode: item.pincode,
        gstNumber: "",
        panNumber: "",
        defaultBillingChecked: item.isDefault,
        addressId: item.id,
      }));

      // console.log(billingData);
      // Add objects from billingData one by one to billingInfo
      billingData.forEach((data) => {
        setBillingInfo((prevInfo) => [...prevInfo, data]);
      });
    };
    fetchUserAddress();
  }, []);

  // get the user profile
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile();
        // console.log(response, "user profile");

        if (response.error.code === 0) {
          // Ensure the data exists and is structured as expected
          const profile = response.data.profile?.[0] || null;
          setUserProfile(profile);
          // Check if profile is incomplete
          if (
            !profile?.name ||
            !profile?.email ||
            !profile?.mobile ||
            !profile?.companyName ||
            !profile?.companyType
          ) {
            setIsIncompleteProfile(true);
          } else {
            setIsIncompleteProfile(false);
          }
        } else {
          setUserProfile(null);
          setIsIncompleteProfile(true); // No profile means it's incomplete
        }
      } catch (error) {
        // console.error("Error fetching user profile:", error);
        setUserProfile(null);
        setIsIncompleteProfile(true); // No profile means it's incomplete
      }
    };
    fetchUserProfile();
  }, []);

  // handle check box for t&c
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // handle check box for default billing info
  const handleBillCheckboxChange = () => {
    setDefaultBillingChecked(!defaultBillingChecked);
  };

  // to display the user bill info add modal
  const toggleModal = () => {
    if (!showModal) {
      // Clear form fields only when opening the modal for a new entry
      setCompanyName("");
      setPhoneNumber("");
      setEmail("");
      setAddressLine1("");
      setAddressLine2("");
      setCity(null);
      setState(null);
      setPincode("");
      setGstNumber("");
      setPanNumber("");
      setDefaultBillingChecked(false);
    }
    setShowModal(!showModal);
  };

  // handle phone number input
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and prevent any non-digit characters
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  // function to save user bill info details
  // const handleSave = async (e) => {
  //   e.preventDefault();

  //   // Regex to validate email
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailRegex.test(email)) {
  //     showToast("Email", "Please enter a valid email address.", "error");
  //     return;
  //   }

  //   const payload = {
  //     first_name: companyName,
  //     mobile: phoneNumber,
  //     email: email,
  //     addressLine1: addressLine1,
  //     addressLine2: addressLine2,
  //     city_id: "133504",
  //     state_id: "4008",
  //     pincode: pincode,
  //     // gstNumber,
  //     // panNumber,
  //     isDefault: defaultBillingChecked ? "Y" : "N",
  //   };
  //   // console.log(payload);

  //   // Check for required fields (other than city, state, panNumber, gstNumber)
  //   const requiredFields = {
  //     companyName,
  //     phoneNumber,
  //     email,
  //     addressLine1,
  //     addressLine2,
  //     pincode,
  //   };

  //   const emptyFields = Object.entries(requiredFields)
  //     .filter(([key, value]) => !value) // Find empty fields
  //     .map(([key]) => key); // Get the field names

  //   if (emptyFields.length > 0) {
  //     showToast("Billing Info", `Incomplete Billing Details`, "error");
  //     return;
  //   }

  //   // const addAddress = await createAddress(payload);
  //   // console.log(addAddress, "billing info added");

  //   // Add new billing info
  //   // setBillingInfo((prevInfo) => [...prevInfo, billingData]);

  //   // Reset and close modal
  //   // setShowModal(false);
  // };

  const handleSave = (e) => {
    e.preventDefault();

    // Regex to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const billingData = {
      companyName,
      phoneNumber,
      email,
      addressLine1,
      addressLine2,
      city: city?.value || "",
      state: state?.value || "",
      pincode,
      gstNumber,
      panNumber,
      defaultBillingChecked,
    };

    if (defaultBillingChecked) {
      // Set all others to false if the current one is default
      billingInfo.forEach((info) => (info.defaultBillingChecked = false));
    }

    if (editIndex !== null) {
      // Update existing billing info
      const updatedBillingInfo = [...billingInfo];
      updatedBillingInfo[editIndex] = billingData;
      setBillingInfo(updatedBillingInfo);
    } else {
      // Add new billing info
      setBillingInfo((prevInfo) => [...prevInfo, billingData]);
    }

    // Reset editing state and close modal
    setEditIndex(null);
    setShowModal(false);
  };

  // fucntion handle the edit user billing info
  const handleEditBillingInfo = (index) => {
    const info = billingInfo[index];
    console.log("Editing companyName:", info.companyName); // Log here

    // Populate the form fields with the selected billing info
    setEditIndex(index);
    setCompanyName(info.companyName ? info.companyName : "companyname");
    setPhoneNumber(info.phoneNumber || "");
    setEmail(info.email || "");
    setAddressLine1(info.addressLine1 || "");
    setAddressLine2(info.addressLine2 || "");
    setCity(info.city ? { value: info.city, label: info.city } : null);
    setState(info.state ? { value: info.state, label: info.state } : null);
    setPincode(info.pincode || "");
    setGstNumber(info.gstNumber || "");
    setPanNumber(info.panNumber || "");
    setDefaultBillingChecked(info.defaultBillingChecked || false);
    setShowModal(true);
  };

  // Handler for change event
  // const handleChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  const handlePayNow = async () => {
    // if (billingInfo.length === 0) {
    //   showToast("Oops Missed Something!", "Please add billing info", "error"); //display toast msg
    //   return;
    // }

    if (!isChecked) {
      showToast(
        "Oops Missed Something!",
        "Please accept our terms and conditions",
        "error"
      ); //display toast msg
      return;
    }

    if (isIncompleteProfile) {
      showToast("Profile", "Please complete the user profile", "error"); //display toast msg
      setIsLoginModalOpen(true);
      return;
    }

    const createOrderResp = await createOrder();
    // console.log(createOrderResp, "api resp for creating order");

    if (createOrderResp?.error?.code === 0) {
      // order is create now call the transaction api and get the razorpay link
      // create payload
      const payload = {
        order_id: createOrderResp?.data?.order?.id,
      };
      // console.log(payload, "transaction payload");
      const transactionResp = await startTransaction(payload);
      // console.log(transactionResp, "transaction resp");
      if (transactionResp.error.code === 0) {
        const paymentLink = transactionResp.data.razorpayOrder.id;
        // console.log(paymentLink);
        // Wait for the payment to complete on the razoopay
        const paymentResult = await initRzp(paymentLink);
        // console.log("Payment result", paymentResult);
        // wait for the payment to complete on the backend
        if (paymentResult) {
          // create payload
          const payload = paymentResult;
          const paymentStatus = await commitTransaction(payload);
          // console.log(paymentStatus);
          if (paymentStatus?.error?.code === 0) {
            showToast("Purchase", "Payment Successful", "success");
            const modalMsg = {
              title: "Your purchase was succesful!",
              subTitle:
                "Don't forget to add links to your content in 'my accounts' section",
              btntext: "License more tracks",
              btnredirect: "/",
              redirectText: "View Purchases",
              textRedirect: "/user/purchases",
            };
            localStorage.setItem("purchaseData", JSON.stringify(cartItems));
            setPopupMsg(modalMsg);
            setIsModalOpen(true);
            // dispatch(clearCart());
            // navigate("/user"); // Navigate after modal closes
          } else {
            showToast("Purchase", "Payment Fail", "error");
            return;
          }
        }
      } else {
        showToast("Payment Inititate", "Failed to initiate Payment", "error");
      }
    } else {
      showToast(
        "Purchase Order",
        "Something went wrong while creating order",
        "error"
      );
    }
  };

  const initRzp = (paymentLink) => {
    return new Promise((resolve, reject) => {
      if (!window.Razorpay) {
        // console.error("Razorpay script is not loaded.");
        reject(new Error("Razorpay script not loaded"));
        return;
      }

      const options = {
        key: config.razorPayKey, // Razorpay key
        amount: "", // no need pass as the amount is the prefill in the payment link
        currency: "INR",
        name: "Hoopr Smash",
        description: "Test Transaction",
        image: "https://cdn.hooprsmash.com/web/logos/smash.png",
        order_id: paymentLink, // razorpay Order ID from init api response
        prefill: {
          name: "",
          email: "",
          contact: "",
        },
        theme: {
          color: "#fff",
        },
        handler: function (response) {
          // console.log("Payment successful:", response);
          resolve(response); // Resolve the promise on successful payment
        },
      };

      const rzp = new window.Razorpay(options);

      rzp.on("payment.failed", function (response) {
        // console.log("Payment failed:", response);
        reject(response); // Reject the promise on payment failure
      });

      rzp.open();
    });
  };

  //  city options
  const cityOptions = [
    { value: "mumbai", label: "Mumbai" },
    { value: "patna", label: "Patna" },
    { value: "noida", label: "Noida" },
  ];

  // state options
  const stateOptions = [
    { value: "maharashtra", label: "Maharashtra" },
    { value: "delhi", label: "Delhi" },
    { value: "bihar", label: "Bihar" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "200px", // Custom width
    }),
  };

  return cartItems.length > 0 ? (
    <div className={`${styles.outerFull}`}>
      <div className={`${styles.headLogo}`}>
        <a href="/">
          <img
            src="https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/logos/smash.png"
            alt="logo"
            class="header-logo"
          />
        </a>
      </div>
      <div className={`${styles.checkoutContainer} ${styles.outer}`}>
        <div className={`${styles.inner}`}>
          {/* <div className={styles.checkoutContainer}> */}
          {/* header section */}
          {/* <div className={styles.checkoutHeader}>
        <p className={styles.headerText}>Checkout</p>
      </div> */}
          <div className={styles.billDContainer}>
            {/* billing details section */}
            <div className={styles.billDetails}>
              {/* details header section */}
              <div className={styles.billDHeader}>
                <p className={styles.billHeading}>Billing Details</p>
                <span className={styles.billSubHeading}>
                  Please ensure all details are accurate as they will be
                  reflected on your invoice. Complete your information for a
                  seamless billing process
                </span>
              </div>
              {/* detail profile information */}
              <div className={styles.profileInfo}>
                <p className={styles.profileHeading}>Profile Info</p>
                {userProfile ? (
                  <div className={styles.userInfo}>
                    <p className={styles.username}>{userProfile.name || ""}</p>
                    <div className={styles.emailPhone}>
                      <span>+91-{userProfile.mobile || ""}</span>
                      <p className={styles.bullet}>{userProfile.email || ""}</p>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => setIsLoginModalOpen(true)}
                    className={styles.billInfoadd}
                  >
                    Add Profile Info +
                  </div>
                )}
              </div>
              {/* billing info section */}
              <div className={styles.billInfoContainer}>
                <p className={styles.billInfoHeading}>Billing Info</p>
                {billingInfo.length > 0 ? (
                  <>
                    {billingInfo.map((info, index) => (
                      <div key={index} className={styles.billInfo}>
                        <div className={styles.billUserInfo}>
                          <input
                            type="radio"
                            value={index}
                            name="billingInfo"
                            checked={selectedOption === index.toString()}
                            onChange={() => setSelectedOption(index.toString())}
                          />
                          <div className={styles.bsiName}>
                            <p className={styles.username}>
                              {info.companyName}
                            </p>
                            <div className={styles.emailPhone}>
                              <span>{info.phoneNumber}</span>
                              <p className={styles.bullet}>{info.email}</p>
                            </div>
                          </div>
                          <div
                            onClick={() => handleEditBillingInfo(index)}
                            className={styles.edit}
                          >
                            <FiEdit
                              cursor="pointer"
                              color="#464646"
                              size={18}
                            />
                          </div>
                        </div>
                        <div className={styles.gstpanContainer}>
                          <div className={styles.gstpanBillInfo}>
                            <p className={styles.gstpanHeading}>GSTIN:</p>
                            <span className={styles.gstpanNumber}>
                              {info.gstNumber || "N/A"}
                            </span>
                          </div>
                          <div className={styles.gstpanBillInfo}>
                            <p className={styles.gstpanHeading}>PAN:</p>
                            <span className={styles.gstpanNumber}>
                              {info.panNumber || "N/A"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div
                      onClick={toggleModal}
                      className={styles.addNewBillingBtn}
                    >
                      Add New +
                    </div>
                  </>
                ) : (
                  <div onClick={toggleModal} className={styles.billInfoadd}>
                    Add Billing Info +
                  </div>
                )}
              </div>
              {/* order summary section */}
              <div className={styles.orderSumContainer}>
                <p className={styles.oscHeader}>Order Summary</p>
                {cartItems.map((item, index) => (
                  <div key={index} className={styles.oscContent}>
                    <div>
                      <div className={styles.trackInfo}>
                        <p className={styles.trackName}>{item.preText}</p>
                        <div className={styles.trackQutantity}>
                          {item.quantity}
                        </div>
                      </div>
                      <span className={styles.artistName}>{item.secText}</span>
                    </div>
                    <div>
                      <p className={styles.trackPrice}>
                        ₹
                        {(
                          parseInt(item.sp.replace(/,/g, ""), 10) *
                          item.quantity
                        ).toLocaleString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.billSummary}>
              <div className={styles.billSumInfo}>
                <p className={styles.bsiText}>Billing Summary</p>
                <div className={styles.bsiSection}>
                  <div className={styles.bsiContent}>
                    <p className={styles.bsiHeading}>Subtotal</p>
                    <span className={styles.bsiSubHeading}>
                      ₹
                      {Number(
                        (
                          cartItems.reduce(
                            (subtotal, item) =>
                              subtotal +
                              parseInt(item.sp.replace(",", ""), 10) *
                                (item.quantity || 1),
                            0
                          ) / 1.18
                        ).toFixed(2) // Ensure two decimal places for precision
                      ).toLocaleString("en-IN")}
                    </span>
                  </div>
                  <div className={styles.bsiContent}>
                    <p className={styles.bsiHeading}>TAX (18%GST)</p>
                    <span className={styles.bsiSubHeading}>
                      {" "}
                      ₹
                      {Number(
                        (
                          cartItems.reduce(
                            (total, item) =>
                              total +
                              parseInt(item.sp.replace(",", ""), 10) *
                                item.quantity,
                            0
                          ) -
                          cartItems.reduce(
                            (subtotal, item) =>
                              subtotal +
                              (parseInt(item.sp.replace(",", ""), 10) *
                                (item.quantity || 1)) /
                                1.18,
                            0
                          )
                        ).toFixed(2) // Ensure two decimal places for precision
                      ).toLocaleString("en-IN")}
                    </span>
                  </div>
                </div>
              </div>
              <hr className={styles.breakLine}></hr>
              <div className={styles.bsiTotalSection}>
                <div className={styles.totalAmt}>
                  <div className={styles.totaltext}>Total</div>
                  <div className={styles.totalPriceSection}>
                    <span className={styles.billTotal}>
                      ₹
                      {Number(
                        cartItems
                          .reduce(
                            (total, item) =>
                              total +
                              parseInt(item.sp.replace(",", "")) *
                                item.quantity,
                            0
                          )
                          .toFixed(2) // Ensure two decimal places for precision
                      ).toLocaleString("en-IN")}
                    </span>
                    <div className={styles.bsiSaving}>
                      You are saving ₹
                      {cartItems
                        .reduce(
                          (totalSavings, item) =>
                            totalSavings +
                            (parseInt(item.cp.replace(",", "")) -
                              parseInt(item.sp.replace(",", ""))) *
                              item.quantity,
                          0
                        )
                        .toLocaleString("en-IN")}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.tandc}>
                <label className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className={styles.checkbox}
                  />
                  <span className={styles.checkboxText}>
                    I have read and agree to the{" "}
                    <a href="/terms" target="_blank" className={styles.link}>
                      Terms
                    </a>{" "}
                    &{" "}
                    <a href="/privacy" target="_blank" className={styles.link}>
                      Privacy Policy
                    </a>
                  </span>
                </label>
              </div>
              <div className={styles.payBtn}>
                <CustomButton
                  text="Pay Now"
                  backgroundColor="var(--button-primary)"
                  borderRadius="50px"
                  width="348px"
                  height="50px"
                  textColor="#fff"
                  fontSize="18px"
                  fontFamily="Raleway"
                  fontWeight="550"
                  onClick={handlePayNow}
                />
              </div>
            </div>
          </div>
          {/* billing info popup modal */}
          {showModal && (
            <form onSubmit={handleSave} className={styles.modalBackdrop}>
              <div className={styles.modalBackdrop} onClick={toggleModal}>
                <div
                  className={styles.modalContent}
                  onClick={(e) => e.stopPropagation()} // Prevent backdrop click from closing modal
                >
                  <div className={styles.modalHeader}>
                    <p className={styles.headerText}>Add Billing Information</p>
                    <IoIosClose
                      onClick={() => setShowModal(false)}
                      color="#000"
                      cursor="pointer"
                      size={32}
                    />
                  </div>
                  <div className={styles.billingForm}>
                    <CustomInput
                      fontSize="16px"
                      marginTop="5px"
                      paddingLeft="22px"
                      labelOpcaity="30%"
                      fontSizeLabel="14px"
                      border="1px solid #98999C"
                      labelTop="15px"
                      fontLabelColor="#98999C"
                      height="50px"
                      placeholder="Company / Your Name"
                      borderRadius="8px"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                    <div className={styles.phoneInputProfile}>
                      <select className={styles.countryCode}>
                        <option value="+91">+91</option>
                      </select>
                      <input
                        type="text"
                        placeholder="Enter Phone Number"
                        className={styles.phoneNumber}
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        maxLength={10}
                      />
                    </div>
                    <CustomInput
                      fontSize="16px"
                      marginTop="5px"
                      paddingLeft="22px"
                      labelOpcaity="30%"
                      fontSizeLabel="14px"
                      border="1px solid #98999C"
                      labelTop="15px"
                      fontLabelColor="#98999C"
                      height="50px"
                      placeholder="Email"
                      borderRadius="8px"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <CustomInput
                      fontSize="16px"
                      marginTop="5px"
                      paddingLeft="22px"
                      labelOpcaity="30%"
                      fontSizeLabel="14px"
                      border="1px solid #98999C"
                      labelTop="15px"
                      fontLabelColor="#98999C"
                      height="50px"
                      placeholder="Address Line 1"
                      borderRadius="8px"
                      value={addressLine1}
                      onChange={(e) => setAddressLine1(e.target.value)}
                    />
                    <CustomInput
                      fontSize="16px"
                      marginTop="5px"
                      paddingLeft="22px"
                      labelOpcaity="30%"
                      fontSizeLabel="14px"
                      border="1px solid #98999C"
                      labelTop="15px"
                      fontLabelColor="#98999C"
                      height="50px"
                      placeholder="Address Line 2"
                      borderRadius="8px"
                      value={addressLine2}
                      onChange={(e) => setAddressLine2(e.target.value)}
                    />
                    <div className={styles.state}>
                      <Select
                        value={city}
                        onChange={setCity}
                        styles={customStyles}
                        options={cityOptions}
                      />
                      <Select
                        value={state}
                        onChange={setState}
                        styles={customStyles}
                        options={stateOptions}
                      />
                    </div>
                    <CustomInput
                      fontSize="16px"
                      marginTop="5px"
                      paddingLeft="22px"
                      labelOpcaity="30%"
                      fontSizeLabel="14px"
                      border="1px solid #98999C"
                      labelTop="15px"
                      fontLabelColor="#98999C"
                      height="50px"
                      placeholder="Pincode"
                      borderRadius="8px"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                    />
                    <div className={styles.gstpan}>
                      <CustomInput
                        fontSize="16px"
                        marginTop="5px"
                        paddingLeft="22px"
                        labelOpcaity="30%"
                        fontSizeLabel="14px"
                        border="1px solid #98999C"
                        labelTop="15px"
                        fontLabelColor="#98999C"
                        height="50px"
                        placeholder="GSTIN Number"
                        borderRadius="8px"
                        value={gstNumber}
                        onChange={(e) => setGstNumber(e.target.value)}
                      />
                      <CustomInput
                        fontSize="16px"
                        marginTop="5px"
                        paddingLeft="22px"
                        labelOpcaity="30%"
                        fontSizeLabel="14px"
                        border="1px solid #98999C"
                        labelTop="15px"
                        fontLabelColor="#98999C"
                        height="50px"
                        placeholder="PAN Number"
                        borderRadius="8px"
                        value={panNumber}
                        onChange={(e) => setPanNumber(e.target.value)}
                      />
                    </div>
                    <div className={styles.billingCheckbox}>
                      <label className={styles.checkboxContainer}>
                        <input
                          type="checkbox"
                          checked={defaultBillingChecked}
                          onChange={handleBillCheckboxChange}
                          className={styles.checkbox}
                        />
                        <span className={styles.checkboxText}>
                          Set as default billing information
                        </span>
                      </label>
                    </div>
                    <div className={styles.payBtn}>
                      <CustomButton
                        text="Save"
                        backgroundColor="var(--button-primary)"
                        borderRadius="50px"
                        width="399px"
                        height="50px"
                        textColor="#fff"
                        fontSize="18px"
                        fontFamily="Raleway"
                        fontWeight="550"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      {/* success popup message modal */}
      <PopUpModal
        modalData={popupMsg}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <Login
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        profileCompete={true}
      />
    </div>
  ) : (
    <EmptyState type="checkout" />
  );
};

export default Checkout;
