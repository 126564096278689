const moods = {
    "f11b4beb-c2de-41a1-81d3-1ca0dd870110": {
        "name": "Abstract",
        "description": "Ethereal and experimental soundscapes, perfect for creative and avant-garde projects.",
    },
    "9adc4829-f074-40cc-a9d6-12263b78bcb0": {
        "name": "Action",
        "description": "High-energy, adrenaline-pumping tracks for action-packed scenes and trailers.",
    },
    "89f693a7-6b5c-40a9-94e9-719caf72a963": {
        "name": "Adventurous",
        "description": "Upbeat and inspiring music for travel, exploration, and outdoor activities.",
    },
    "e29621c0-09f0-44d1-af32-aa8c7ba52d0e": {
        "name": "Aggressive",
        "description": "",
    },
    "de78fff8-b2cd-4758-92c6-7c7b182ff2f1": {
        "name": "Angry",
        "description": "Intense and aggressive music to convey anger, frustration, and conflict.",
    },
    "469a2a08-704b-4339-8b77-cc6314f2d8ac": {
        "name": "Anthemic",
        "description": "Powerful and inspiring music, perfect for patriotic content, sports events, and motivational videos.",
    },
    "afabbc54-6e4a-4e77-ab10-ca63a543b4c8": {
        "name": "Badass",
        "description": "Powerful and edgy music for rebellious and edgy content.",
        "img": ""
    },
    "3f674d09-a211-4f0d-8647-a8909e077d8b": {
        "name": "Busy",
        "description": "Energetic and fast-paced music to match the hustle and bustle of modern life.",
    },
    "fc6d31ac-ee6e-489c-a7dc-79da156c3247": {
        "name": "Calm",
        "description": "Soothing and relaxing music to promote tranquility and peace.",
    },
    "3fd90f5f-f872-466e-8f97-075ea0635ee4": {
        "name": "Celebratory",
        "description": "Joyful and celebratory music for festive occasions and parties.",
    },
    "b9adc674-d482-4900-bb9c-65c57690ba3e": {
        "name": "Chill",
        "description": "Laid-back and mellow music for relaxation and chill-out sessions.",
    },
    "51ad375b-d2c4-437e-af1d-c171f6054119": {
        "name": "Dark",
        "description": "Mysterious and suspenseful music to create a dark and moody atmosphere.",
    },
    "b617ce60-ced1-4f75-853c-76545f8cb163": {
        "name": "Depressed",
        "description": "Sad and melancholic music, perfect for dramatic scenes, heartbreaks, and emotional content.",
    },
    "92a2b8ff-5b82-4f0b-98ad-80ab2c012a9f": {
        "name": "Dramatic",
        "description": "Emotional and impactful music to enhance dramatic scenes and moments.",
    },
    "6a011e8c-5048-4448-bce2-5458dadf1421": {
        "name": "Dreamy",
        "description": "Ethereal and atmospheric music to evoke a sense of wonder and imagination.",
    },
    "9e6482af-cca0-42c8-a9a7-7cc4a634d5a3": {
        "name": "Elegant",
        "description": "Sophisticated and refined music for upscale and luxurious brands.",
    },
    "bed6f8ce-d5b3-4d09-acd3-a4629ed69592": {
        "name": "Emotional",
        "description": "Evocative and poignant music, perfect for dramas, emotional scenes, and sentimental ads.",
    },
    "f39282cf-64be-455d-b17e-6efa6dc0fa01": {
        "name": "Energetic",
        "description": "High-energy and fast-paced music, perfect for action scenes, sports, and fitness videos.",
    },
    "6a026083-a5ae-4a1b-8fff-1075cf069373": {
        "name": "Epic",
        "description": "Grand and sweeping music for epic scenes and trailers.",
    },
    "7e98f210-b995-46f0-973c-0da8e6713c17": {
        "name": "Ethnic",
        "description": "Authentic and traditional music from various cultures around the world.",
    },
    "4ca08b7e-0c98-4922-a776-576e8a36e331": {
        "name": "Exciting",
        "description": "Upbeat and energetic music to excite and energize your audience. Suitable for revelation, unboxing and curtain raisers.",
    },
    "3c75595e-fb29-4efa-b281-73b64e44abb2": {
        "name": "Fantasy",
        "description": "Magical and mystical music to transport your audience to otherworldly realms.",
    },
    "50d100e3-5353-491c-ad52-7f2758bb3dda": {
        "name": "Feel Good",
        "description": "Uplifting and positive music to evoke feelings of happiness and joy.",
    },
    "623df712-99f1-4fa9-8636-3cf3a15e335f": {
        "name": "Festive",
        "description": "Cheerful and celebratory music for festivals and holidays.",
    },
    "fdb3543d-af3b-4712-8fb8-a38a38f257ae": {
        "name": "Flirty",
        "description": "Playful and romantic music, perfect for romantic comedies, love songs, and dating apps.",
    },
    "43d114d0-ae93-48a2-a079-bab015a9be89": {
        "name": "Fun",
        "description": "Playful and lighthearted music to create a fun and engaging atmosphere.",
    },
    "5888d8b9-503a-4034-a8a2-0a7c3072ac55": {
        "name": "Funny",
        "description": "Humorous and quirky music, perfect for comedy sketches, sitcoms, and funny commercials.",
    },
    "8da09f93-eaa1-423b-adb7-93749d161e59": {
        "name": "Glamorous",
        "description": "Glamorous and sophisticated music to enhance your luxury and fashion content.",
    },
    "e15b4625-c45b-4692-ba86-311bb2aa2c16": {
        "name": "Grand",
        "description": "Majestic and powerful music for grand and epic occasions.",
    },
    "f1b0d86f-ffaf-4387-b7e5-914eeada7383": {
        "name": "Groovy",
        "description": "Groovy and funky music to get your audience moving.",
    },
    "0ca27d5b-d78a-4ac5-875c-1008756ef158": {
        "name": "Happy",
        "description": "Joyful and upbeat music to lift your spirits.",
    },
    "375c4386-a819-4aa4-bdd1-3c66b0c3ed5c": {
        "name": "Heartwarming",
        "description": "Emotional and sentimental music to tug at the heartstrings.",
    },
    "5747c295-e345-4265-8921-7833577e391f": {
        "name": "Hopeful",
        "description": "Optimistic and uplifting music, perfect for inspirational content, motivational videos, and feel-good ads.",
    },
    "70fc6a0c-a6e6-4da8-95a8-9782bdea9900": {
        "name": "Horror",
        "description": "Dark and eerie music to create suspense and fear.",
    },
    "d0d2b2dc-6791-47a1-902e-1b14788757f1": {
        "name": "Inspiring",
        "description": "Motivational and uplifting music to inspire and empower.",
    },
    "9200766a-1c9e-4c61-9d84-1402310790c6": {
        "name": "Intense",
        "description": "Intense and dramatic music to build tension and excitement.",
  },
    "ed7d43f9-918f-4e20-80dc-e3113c556803": {
        "name": "Intimate",
        "description": "",
    },
    "1e0e85bc-19a2-422a-8553-0939f08f78aa": {
        "name": "Joyful",
        "description": "Upbeat and energetic music, perfect for party scenes, comedies, and upbeat commercials.",
    },
    "115a3e41-d2ee-47ae-9fa1-c96c21c20178": {
        "name": "Lazy",
        "description": "Laid-back and mellow music for relaxation and downtime.",
    },
    "66585de3-83a6-44d4-a651-4f8c0dcffec8": {
        "name": "Melancholic",
        "description": "Sad and reflective music, perfect for dramas, heartbreaks, and emotional scenes.",
    },
    "7e9caf70-1cd5-4e82-8ee0-8f4590f2f8bb": {
        "name": "Motivational",
        "description": "Uplifting and inspiring music to motivate and energize.",
    },
    "3bbdfc0a-78a8-4efc-8f51-7b30162c755b": {
        "name": "Mysterious",
        "description": "",
    },
    "ad8e3c56-3a40-409d-b1c5-01e9a35a6e23": {
        "name": "Nostalgic",
        "description": "Reminiscent and nostalgic music, ideal for historical dramas, vintage-themed ads, and sentimental content.",
    },
    "ad055f48-c801-46a3-9836-616bbc43095e": {
        "name": "Peppy",
        "description": "Energetic and upbeat music to get you moving.",
    },
    "18bcd8ec-4d62-48fb-a95e-66123d3d2f39": {
        "name": "Rebellious",
        "description": "Edgy and rebellious music, perfect for action movies, sports commercials, and rebellious brands.",
    },
    "550d8185-9e23-45a9-90bb-e8993b3c9759": {
        "name": "Relaxing",
        "description": "Soothing and calming music to reduce stress and anxiety.",
    },
    "ed820c8e-03f5-43d1-bbd8-c96c51b31f88": {
        "name": "Romantic",
        "description": "Romantic and dreamy music to evoke feelings of love and passion.",
    },
    "91365ba2-a58c-4152-a5b3-780ea9f62216": {
        "name": "Sad",
        "description": "Melancholic and emotional music to evoke feelings of sadness and longing.",
    },
    "d40a1f69-6d17-4f38-bc90-fcab1d780296": {
        "name": "Sensual",
        "description": "Sultry and seductive music. Ideal for sensual scenes in movies, music videos, and adult content.",
    },
    "22a59a52-5591-4d8b-9fc0-a5282e2a642e": {
        "name": "Serious",
        "description": "Serious and solemn music for formal and professional occasions.",
    },
    "eaf38e4e-3afc-40bf-9670-3a3e95c2ebd7": {
        "name": "Sexy",
        "description": "Sultry and seductive music to create a sensual and alluring atmosphere.",
    },
    "398ee4b5-ba5a-4904-b76b-34e0a912030d": {
        "name": "Soft",
        "description": "Gentle and soothing music for delicate and sensitive moments.",
    },
    "dec28c89-f48a-4c39-9582-3eb3e822a683": {
        "name": "Soulful",
        "description": "Emotional and expressive music that touches the soul.",
    },
    "7d58aafd-8d01-4625-b513-4b291ff4495a": {
        "name": "Spiritual",
        "description": "Sacred and devotional music to connect with the divine.",
    },
    "03ce178f-e648-4c58-a840-af199ab13b13": {
        "name": "Suspense",
        "description": "Tense and mysterious music to build suspense and anticipation.",
    },
    "6c29befb-abce-4b79-8132-d23345a7bee3": {
        "name": "Sweet",
        "description": "Soft, romantic, and sentimental music. Perfect for love songs, romantic comedies, and heartwarming ads.",
    },
    "eaf56e56-4e35-4db9-8bf1-a34be195bbf1": {
        "name": "Thoughtful",
        "description": "Reflective and contemplative music for introspective moments.",
    },
    "dc0ba229-e598-495f-bf6e-9fb209ebe097": {
        "name": "Thriller",
        "description": "Intense and suspenseful music for thrillers and horror films.",
    },
    "e5f21bae-1943-4a6e-b36f-e15a6e2df5d4": {
        "name": "Upbeat",
        "description": "Energetic and positive music to uplift and inspire.",
    }
};

export default moods;