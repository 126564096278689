import React, { useState, useEffect } from "react";
import styles from "../styles/RequestLicense.module.css";
import { IoIosClose } from "react-icons/io";
import CustomDropdown from "../components/CustomDropdown/CustomDropdown";
import CustomInput from "../components/CustomInput/CustomInput";
import CustomButton from "../components/CustomButton/CustomButton";
import randomDefaultImage from "../utils/RandomDefaultImage";
import PopUpModal from "../components/PopUpModal/PopUpModal";

const RequestLicense = ({ isOpen, onClose, licenseData }) => {
  const [isRequestOpen, setIsRequestOpen] = useState(isOpen); // Sync with parent initially
  const [campaignSelected, setIsCampaignSelected] = useState(null); // state to manage the selected value for campaign dropdown
  const [companyName, setIsCompanyName] = useState("");
  const [licenseDuration, setIsLicenseDuration] = useState(null); // state to manage license duration value
  const [territory, setIsTerritory] = useState(null); // state to manage the territory dropdown value
  const [isModalOpen, setIsModalOpen] = useState(false); // toggle thankyou(request success) popup modal
  const [popupMsg, setPopupMsg] = useState(null); // state to manage the message to be displayed in popup modal

  // Sync `isCartOpen` with `isOpen` prop whenever it changes
  useEffect(() => {
    setIsRequestOpen(isOpen);
  }, [isOpen]);

  // Function to close the modal
  const closeModal = () => {
    setIsRequestOpen(false); // Close modal locally
    onClose(); // Notify the parent to close the modal
  };

  // const handleOverlayClick = (e) => {
  //   if (e.target === e.currentTarget) {
  //     closeModal(); // Close both modals by setting parent modal state
  //   }
  // };

  //  city options
  const campaignOptions = [
    { value: "agency", label: "Agency" },
    { value: "brand", label: "Brand" },
    { value: "content-studio", label: "Content Studio" },
    { value: "independent-filmmaker", label: "Independent Filmmaker" },
    {
      value: "individual-creator-influencer",
      label: "Individual Creator / Influencer",
    },
    { value: "ott-platform", label: "OTT Platform" },
    { value: "production-house", label: "Production House" },
    { value: "radio-station", label: "Radio Station" },
    { value: "tv-channel", label: "TV Channel" },
    { value: "Other", label: "Other" },
  ];

  const licenseDurationOptions = [
    { value: "three", label: "3 Months" },
    { value: "six", label: "6 Months" },
  ];

  const territoryOptions = [
    { value: "maharashtra", label: "Maharashtra" },
    { value: "delhi", label: "Delhi" },
  ];

  if (!isRequestOpen) return null; // Don't render if the modal is closed

  //   console.log(licenseData, "licendata received");

  const handleSubmit = () => {
    const modalMsg = {
      title: "Thank You for Your Request",
      subTitle: "Our Sales Team Will Contact You Soon",
      btntext: "License more tracks",
      btnredirect: "/",
      // redirecttext: "View Purchases",
    };
    setPopupMsg(modalMsg);
    // closeModal();
    // setIsRequestOpen(false); // Close modal locally
    setIsModalOpen(true);
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        {/* request header section */}
        <div className={styles.header}>
          <div className={styles.heading}>
            <p className={styles.headingtext}>Request License</p>
          </div>
          <IoIosClose
            onClick={closeModal}
            color="#000"
            cursor="pointer"
            size={34}
          />
        </div>
        <div className={styles.content}>
          {/* card section */}
          <div className={styles.cardContainer}>
            <div
              className={styles.cardImg}
              style={{
                backgroundImage: `url(${
                  licenseData.img || randomDefaultImage("track")
                })`,
                // backgroundImage: `url(${
                //   config.s3imgUrl + 'web/tracks/' + licenseData.trackCode + '.webp' || randomDefaultImage("track")
                // })`,
              }}
            ></div>
            <div className={styles.cardInfo}>
              <p className={styles.cardTitle}>
                {licenseData
                  ? licenseData.title.length > 40
                    ? licenseData.title.substring(0, 40) + "..."
                    : licenseData.title
                  : "Name"}
              </p>
              <span className={styles.cardSubTitle}>
                {licenseData
                  ? licenseData.subTitle.length > 40
                    ? licenseData.subTitle.substring(0, 40) + "..."
                    : licenseData.subTitle
                  : ""}
              </span>
            </div>
          </div>
          {/* campaign dropdown */}
          <div className={styles.container}>
            <p className={styles.title}>
              You are looking to license the tracks for
            </p>
            <CustomDropdown
              options={campaignOptions}
              placeholder="Select Campaign"
              onChange={setIsCampaignSelected}
              value={campaignSelected}
            />
          </div>
          {/* Date Selection */}
          <div className={styles.container}>
            <p className={styles.title}>Select Date</p>
            <input
              type="date"
              className={styles.dateInput}
              placeholder="Select a date"
              onChange={(e) => console.log(e.target.value)} // handle the date change
            />
          </div>
          {/* companyName input box */}
          <div className={styles.container}>
            <p className={styles.title}>Company Name</p>
            <CustomInput
              fontSize="16px"
              marginTop="5px"
              paddingLeft="22px"
              labelOpcaity="30%"
              fontSizeLabel="14px"
              border="1px solid #BFBFBF"
              labelTop="15px"
              fontLabelColor="#BFBFBF"
              height="50px"
              placeholder="Name of company you want a license for"
              borderRadius="8px"
              value={companyName}
              onChange={(e) => setIsCompanyName(e.target.value)}
            />
          </div>
          {/* license duration dropdown */}
          <div className={styles.container}>
            <p className={styles.title}>Duration of License</p>
            <CustomDropdown
              options={licenseDurationOptions}
              placeholder="Select a Duration"
              onChange={setIsLicenseDuration}
              value={licenseDuration}
            />
          </div>
          {/* territory dropdown */}
          <div className={styles.container}>
            <p className={styles.title}>
              Select the territory you wish to get the clearance for?
            </p>
            <CustomDropdown
              options={territoryOptions}
              placeholder="Select Territory"
              onChange={setIsTerritory}
              value={territory}
            />
          </div>
          {/* Project Brief Textarea */}
          <div className={styles.container}>
            <p className={styles.title}>Project Brief</p>
            <textarea
              className={styles.textArea}
              placeholder="Please mention the brief that you have for the song you are looking for - our supervisors can help you find the right fit."
            ></textarea>
          </div>
          {/* submit request button */}
          <div className={styles.container}>
            <CustomButton
              text="Submit Request"
              backgroundColor="var(--button-primary)"
              borderRadius="50px"
              width="408px"
              height="50px"
              textColor="#fff"
              fontSize="18px"
              fontFamily="Raleway"
              fontWeight="550"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
      <PopUpModal
        modalData={popupMsg}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onOutsideClick={closeModal} // Trigger parent modal close when child modal outside is clicked
      />
    </div>
  );
};

export default RequestLicense;
