import React, { useState, useEffect, useCallback } from "react";
import "./AllPlaylistCard.css";
import CustomDropdown from "../../CustomDropdown/CustomDropdown";
import PlaylistCard from "./PlaylistCard";
import config from "../../../utils/config";
import { getAllPack, getPackFilters } from "../../../service/PlaylistService";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoFilterSharp } from "react-icons/io5";

const AllPlaylistCard = () => {
  const [page, setPage] = useState(1); // For pagination
  const [packCards, setPackCards] = useState([]); // State for pack cards
  const [moodsFilters, setMoodsFilters] = useState([]); // setting moods filters
  const [genreFilters, setGenreFilters] = useState([]); // setting genres filters
  const [dropDownDisabled, setDropDownDisabled] = useState(true); // state to manage dropdown visibility

  useEffect(() => {
    fetchPacks(page); // Initial fetch when component mounts
  }, [page]);

  // Fetch packs with pagination
  const fetchPacks = useCallback(async (page) => {
    try {
      const response = await getAllPack(page);
      // if (response?.data?.packs) {
      //   const mappedCards = response.data.packs.map((pack) => ({
      if (response?.data) {
        const mappedCards = response.data.map((pack) => ({
          image: `${config.s3imgUrl}web/playlists/${pack.playlistCode}.webp` || "",
          title: pack.name,
          buttonText: "Explore",
          buttonLink: `#${pack.name_slug}`, // Link to the pack
          totalTracks: pack.trackCount,
          description: pack.description,
          packCode: pack.playlistCode,
          packNameSlug: pack.name_slug,
          type: pack.markerType,
        }));
        setPackCards(mappedCards);
      }
    } catch (error) {
      console.error("Error fetching track list:", error);
    }
  }, []);

  useEffect(() => {
    fetchPackFilters();
  }, []); // calling filters api one time

  // Function to call the pack filters API
  const fetchPackFilters = async () => {
    try {
      const response = await getPackFilters();

      // Mapping the genre filters
      const genres = response.data.filters.genre || [];
      const genresOptions = genres.map((genre) => ({
        value: genre.id, // Mapping the id
        label: genre.name, // Mapping the name
      }));

      // Mapping the moods filters
      const moods = response.data.filters.mood || [];
      const moodsOptions = moods.map((mood) => ({
        value: mood.id, // Mapping the id
        label: mood.name, // Mapping the name
      }));

      setMoodsFilters(moodsOptions);
      setGenreFilters(genresOptions);
      setDropDownDisabled(false);
    } catch (error) {
      console.error("Error fetching track filters", error);
    }
  };

  const GenreFilters = () => {
    return (
      <CustomDropdown
        options={genreFilters}
        placeholder="Select Genre"
        isMulti
        isDisabled={dropDownDisabled}
      />
    );
  };

  const MoodsFilters = () => {
    return (
      <CustomDropdown
        options={moodsFilters}
        placeholder="Select Moods"
        isMulti
        isDisabled={dropDownDisabled}
      />
    );
  };

  return (
    <div className="all-packs-container inner">
      <div className="all-packs-filter">
        <div className="packs-filter-contents">
          {/* <div className="inner allfilter-title">All Filters</div> */}
          <div className="packs-filter-options">
            <div className="filter-pack-title">
              <p>Filters:</p>
              <IoFilterSharp size={16} />
            </div>
          </div>
          <div className="packs-filter-artist">
            <p>Moods:</p>
            {MoodsFilters()}
            {/* {[...Array(5)].map((_, index) => (
              <div key={index} className="packs-filter-artist-options">
                <input
                  type="checkbox"
                  id={`vehicle${index + 1}`}
                  name={`vehicle${index + 1}`}
                  value={`Option ${index + 1}`}
                />
                <label htmlFor={`vehicle${index + 1}`}>
                  Sub Category ({index + 1})
                </label>
              </div>
            ))} */}
          </div>
          <div className="packs-filter-artist">
            <p>Genres:</p>
            {GenreFilters()}
            {/* {[...Array(5)].map((_, index) => (
              <div key={index} className="packs-filter-artist-options">
                <input
                  type="checkbox"
                  id={`vehicle${index + 1}`}
                  name={`vehicle${index + 1}`}
                  value={`Option ${index + 1}`}
                />
                <label htmlFor={`vehicle${index + 1}`}>
                  Sub Category ({index + 1})
                </label>
              </div>
            ))} */}
          </div>
          {/* <div className="packs-filter-artist">
            <p>Language:</p>
            {[...Array(5)].map((_, index) => (
              <div key={index} className="packs-filter-artist-options">
                <input
                  type="checkbox"
                  id={`vehicle${index + 1}`}
                  name={`vehicle${index + 1}`}
                  value={`Option ${index + 1}`}
                />
                <label htmlFor={`vehicle${index + 1}`}>
                  Sub Category ({index + 1})
                </label>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <div className="inner">
        <div className="allpack-title">
          <p className="allpack-title-text-heading">All Playlists</p>
          {/* <Link to={"/packs"}>
            <div className="allpack-viewall">
              <p className="allpackview-heading">View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div>
          </Link> */}
        </div>
        <div className="all-packs">
          <PlaylistCard packCards={packCards} packType="allplaylists" />
        </div>
      </div>
    </div>
  );
};

export default AllPlaylistCard;
