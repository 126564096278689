/*
  CLEANS THE QUERY STRING
  FOR NOW REMOVES SPECIAL CHARACTERS AS WELL AS SPACES FROM THE QUERY KEYWORD
    BEFORE PASSING IT TO THE SEARCH API
  REMOVING SPACES IS NEEDED TO REDUCE RELEVANCE OF SECOND (THIRD, FOURTH....)
    FROM THE CURRENT SEARCH RESULTS
  WE CAN IGNORE THE SPACING RULE AT A LATER DATE WHEN NEEDED

  keyword => SEARCH KEYWORD
*/
const CleanSearchKey = (keyword) => {
  //  REGEX TO REMOVE ALL SPECIAL CHARACTERS INCLUDING SPACES
  //  THIS RETURNS A PURE ALPHANUM STRING
  keyword = keyword
    .trim()
    .toLowerCase()
    .replace(/&/g, "and")
    .replace(/[^a-z0-9 ,\'\.]/g, "")
    .replace(/ +(?= )/g,'')
    .replace(/[^a-z0-9,\'\.]/g, "-");

  return keyword;
};

export default CleanSearchKey;
