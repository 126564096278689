import React from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import "./App.css";
import Header from "./screens/Header";
import Footer from "./screens/Footer";
import GlobalPlayer from "./screens/GlobalPlayer";
import { GlobalPlayerProvider } from "./utils/GlobalPlayerContext";
import { ToastProvider } from "./utils/ToastContext";
import ToastComponent from "./components/ToastComponent/ToastComponent";
import ProtectedRoutes from "./screens/ProtectedRoutes";

//  FOR MS CLARITY
import Clarity from "@microsoft/clarity";

//  INITIALISE CLARITY
const projectId = "pqnlvyi68x";
Clarity.init(projectId);

Clarity.consent(false);

/*
  THIS HAS BEEN CREATED WITH THE INTENT OF HIDING THE HEADER ON THE CHECKOUT PAGE
*/
const HeaderWrapper = () => {
  const location = useLocation();

  // Hide header on checkout page
  if (location.pathname === "/checkout") {
    return null;
  }

  return <Header />;
};

/*
  THIS HAS BEEN CREATED WITH THE INTENT OF HIDING THE FOOTER ON THE CHECKOUT PAGE
*/
const FooterWrapper = () => {
  const location = useLocation();

  // Hide header on checkout page
  if (location.pathname === "/checkout") {
    return null;
  }

  return <Footer />;
};

/*
  THIS HAS BEEN CREATED WITH THE INTENT OF HIDING THE GLOBAL PLAYER ON THE CHECKOUT PAGE
*/
const GPWrapper = () => {
  const location = useLocation();

  // Hide header on checkout page
  if (location.pathname === "/checkout") {
    return null;
  }

  return <GlobalPlayer />;
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <ToastProvider>
        <ToastComponent />
        <GlobalPlayerProvider>
          <div className="parentDiv">
            {/* <div class="snowflakes" aria-hidden="true"> */}
            {/* <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake"><img src="https://images.emojiterra.com/google/noto-emoji/unicode-16.0/color/512px/1f1ee-1f1f3.png" height="20px" width="20px" /></div>
              <div class="snowflake sf-green">❄</div>
              <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake"><img src="https://images.emojiterra.com/google/noto-emoji/unicode-16.0/color/512px/1f1ee-1f1f3.png" height="20px" width="20px" /></div>
              <div class="snowflake sf-green">❅</div>
              <div class="snowflake sf-orange">❆</div>
              <div class="snowflake sf-green">❄</div> */}

            {/* <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake">❆</div>
              <div class="snowflake sf-green">❄</div>
              <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake">❄</div>
              <div class="snowflake sf-green">❅</div>
              <div class="snowflake sf-orange">❆</div>
              <div class="snowflake sf-green">❄</div> */}

            {/* Always visible component */}
            <div
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                left: 0,
                zIndex: 1,
              }}
            >
              {/* <Header /> */}
              <HeaderWrapper />
            </div>

            {/* Route-based pages */}
            <ProtectedRoutes />

            {/* Always visible component */}
            <FooterWrapper />
            <GPWrapper />
          </div>
          {/* </div> */}
        </GlobalPlayerProvider>
      </ToastProvider>
    </Router>
  );
};

export default App;
