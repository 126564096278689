import React from "react";
import "../styles/Playlist.css";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import PlaylistCard from "../components/CardsComponent/PlaylistCard/PlaylistCard";
import AllPlaylistCard from "../components/CardsComponent/PlaylistCard/AllPlaylistCard";
import { Link } from "react-router-dom";

import RandomDefaultImage from "../utils/RandomDefaultImage";
import CustomImg from "../components/CustomImg/CustomImg";
import config from "../utils/config";


const Playlist = () => {
  const offerCards = [
    {
      image: `${config.s3imgUrl}web/promotional/packs/banner1.webp`,
      title: "Trendsetters",
      description: "Fresh tracks, trending vibes! Explore the latest hits from Bollywood's finest. License premium tracks for your next project.",
      buttonText: "Explore",
      packCode: 12,
      packNameSlug: "trendsetters",
      titleImg: `${config.s3imgUrl}web/promotional/packs/trends.png`
    },
    {
      image: `${config.s3imgUrl}web/promotional/packs/banner2.webp`,
      title: "Where’s the Party?",
      description: "Make your year-end celebrations unforgettable. License the perfect track for every occasion.",
      buttonText: "Explore",
      packCode: 15,
      packNameSlug: "wheres-the-party",
      titleImg: `${config.s3imgUrl}web/promotional/packs/party.png`
    },
  ];

  const packCards = [
    {
      image: `${config.s3imgUrl}web/playlists/15.webp`,
      title: "Big Bang Chartbusters",
      buttonText: "Explore",
      totalTracks: 5,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 15,
      packNameSlug: "big-bang-chartbusters",
    },
    {
      image: `${config.s3imgUrl}web/playlists/16.webp`,
      title: "Desi Devotion",
      buttonText: "Explore",
      totalTracks: 5,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 16,
      packNameSlug: "desi-devotion",
    },
    {
      image: `${config.s3imgUrl}web/playlists/13.webp`,
      title: "Happily Ever After",
      buttonText: "Explore",
      totalTracks: 5,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 13,
      packNameSlug: "happily-ever-after",
    },
    {
      image: `${config.s3imgUrl}web/playlists/10.webp`,
      title: "I Love My India",
      buttonText: "Explore",
      totalTracks: 4,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 10,
      packNameSlug: "i-love-my-india",
    },
    {
      image: `${config.s3imgUrl}web/playlists/17.webp`,
      title: "Pulse Racers",
      buttonText: "Explore",
      totalTracks: 5,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 17,
      packNameSlug: "pulse-racers",
    },
    {
      image: `${config.s3imgUrl}web/playlists/11.webp`,
      title: "Love Is In The Air",
      buttonText: "Explore",
      totalTracks: 5,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 11,
      packNameSlug: "love-is-in-the-air",
    },
  ];

  return (
    <div className="pack-listing-container">
      {/* packs page description */}
      <div className="pack-listing-description outer">
        <h1>Explore Playlists</h1>
        <h2>
          Discover a collection of the perfect tracks for your project.
          Browse our extensive library of music playlists, categorized by occasion and use cases.
          {/* Whether you're creating a commercial, a social media post, or a full-length film, seamlessly license the music you need for your content. */}
        </h2>
      </div>
      {/* top packs card section */}
      <div className="toppack-container outer">
        {offerCards.map((offer, index) => (
          <div
            key={index}
            style={
              {
                backgroundImage: `url(${offer.image})`,
              }
            }
            className="toppack-card-img"
          >
            <div className="toppack-card-title">
              {/* <h1> */}
              {/* <CustomImg
                  type="pack"
                  id="party_title"
                  alt="Where's the Party?"
                  imgClass="pack-banner-title-image"
                  src={ offer.titleImg }
                /> */}
              {/* </h1> */}
            </div>
            {/* {/* <div className="toppack-card-description">
              <h2>{offer.description}</h2>
            </div> */}
            <Link
                  to={`/playlists/${encodeURIComponent(
                    offer.packNameSlug
                  )}/${offer.packCode}`}
                >
            <div>
              <CustomButton
                text={offer.buttonText}
                backgroundColor="#fff"
                borderRadius="30px"
                borderColor="none"
                width="150px"
                height="37px"
                textColor="var(--button-primary)"
                iconColor="var(--button-primary)"
                fontSize="16px"
                fontFamily="Raleway"
                fontWeight="bold"
                icon={<IoIosArrowForward />}
                justifyContent="space-between"
              />
              </div>
              </Link>
          </div>
        ))}
      </div>
      {/* popular packs */}
      <div className="packs-container outer-full">
        <div className="inner inner-pl">
          <h1 className="packs-container-heading">Explore Popular Playlists</h1>
          <div className="packs-description-view">
            <h2>Collection of tracks for different occasions, moods and use cases!</h2>
            {/* <div className="packs-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div> */}
          </div>
          <div className="inner">
            <PlaylistCard packCards={packCards} />
          </div>
        </div>
      </div>
      {/* all packs section */}
      <div style={{ marginTop: "10px" }}>
        <AllPlaylistCard />
      </div>
    </div>
  );
};

export default Playlist;
