import React, { useEffect } from "react";
import "../styles/TrackListing.css";
import config from "../utils/config";
import { FaPlay } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import { RiShoppingCart2Line } from "react-icons/ri";
import AllTrackCards from "../components/CardsComponent/TrackCard/AllTrackCards";
import { Link } from "react-router-dom";
import RandomDefaultImage from "../utils/RandomDefaultImage";
import setMetaTags from "../utils/SeoService";

const TrackListing = () => {
  useEffect(() => {
    const seoData = {
      title: "Track Based Music Licensing | Hoopr Smash",
      description:
        "Find the perfect track for your project in our extensive library of premium Bollywood music, categorized by mood, genre, and budget to suit any creative need.",
      ogImage: `https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/tracks/listing1.webp`,
      canonical: window.location.href,
      // lang: "en",
    };
    setMetaTags(seoData);
  }, []);

  const trackCards = [
    {
      image: `https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/tracks/listing1.webp`,
      title: "Besharam Rang",
      // description: "Fresh tracks, fresh vibes! License the latest hits from Bollywood's finest.",
      description: "",
      buttonText: "Explore Track",
      width: "640px",
      code: 74,
      slug: "besharam-rang",
    },
    {
      image: `https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/tracks/listing3.webp`,
      title: "Ankhein Khuli",
      // description: " Smash your year-end celebrations with the best music. License the perfect track for every occasion.",
      description: "",
      buttonText: "Explore Track",
      width: "332px",
      code: 94,
      slug: "ankhein-khuli",
    },
    {
      image: `https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/tracks/listing2.webp`,
      title: "Judaaiyan",
      // description: "Discover independent talent and license their unique music for your projects.",
      description: "",
      buttonText: "Explore Track",
      width: "332px",
      code: 533,
      slug: "judaaiyan",
    },
  ];

  const popularTracks = [
    {
      image: `https://cdn.staging.select.hoopr.ai/musics/8ccdcbdc-a65b-4c00-b020-740749fa408f/8ccdcbdc-a65b-4c00-b020-740749fa408f-image.webp`,
      title: "Ghungroo",
      description: "Vishal & Shekhar, Kumar",
      buttonText: "Explore",
      discountPercent: "20%",
      costPrice: 79999,
      sellingPrice: 59999,
      slug: "ghungroo",
      code: 79,
    },
    {
      image: `https://cdn.staging.select.hoopr.ai/musics/403fa896-df64-406b-ad92-a6e21dc5113f/403fa896-df64-406b-ad92-a6e21dc5113f-image.webp`,
      title: "Gaadi Kaali",
      description: "Neha Kakkar, Rohanpreet Singh",
      buttonText: "Explore",
      discountPercent: "20%",
      costPrice: 79999,
      sellingPrice: 59999,
      slug: "gaadi-kaali",
      code: 164,
    },
    {
      image: `https://cdn.staging.select.hoopr.ai/musics/4da6bb4e-f5cd-4198-9369-13629d383c88/4da6bb4e-f5cd-4198-9369-13629d383c88-image.webp`,
      title: "Crazy Kiya Re",
      description: "Sunidhi Chauhan",
      buttonText: "Explore",
      discountPercent: "20%",
      costPrice: 69999,
      sellingPrice: 49999,
      slug: "crazy-kiya-re",
      code: 115,
    },
    {
      image: `https://cdn.staging.select.hoopr.ai/musics/0f9b706f-f3b9-4708-b0be-40e770c3d67e/0f9b706f-f3b9-4708-b0be-40e770c3d67e-image.webp`,
      title: "Kamaal",
      description: "Uchana Amit, Badshah",
      buttonText: "Explore",
      discountPercent: "20%",
      costPrice: 69999,
      sellingPrice: 49999,
      slug: "kamaal",
      code: 252,
    },
    {
      image: `https://cdn.staging.select.hoopr.ai/musics/a328425b-a2ab-4f84-9a78-8995c711ad93/a328425b-a2ab-4f84-9a78-8995c711ad93-image.jpg`,
      title: "Saawan",
      description: "Abhay Jodhpurkar",
      buttonText: "Explore",
      discountPercent: "20%",
      costPrice: 69999,
      sellingPrice: 49999,
      slug: "saawan",
      code: 599,
    },
  ];

  return (
    <div className="listing-container">
      <div className="track-listing-description outer">
        <h1>Shop by Track</h1>
        <h2>
          Discover and license the perfect bollywood, independent or trending
          track for your project.
        </h2>
      </div>
      {/* track card container */}
      {/* Map over trackCards to render each track card */}
      <div className="track-cards-container inner">
        {trackCards.map((card, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${card.image})`,
              minWidth: card.width,
            }}
            className="track-listingcard"
          >
            <div className="text-overlay">
              <div className="card-play-button">
                <FaPlay size={24} color="var(--button-primary)" />
              </div>
              <div className="card-description">
                <h1>{card.title}</h1>
                <h2>{card.description}</h2>
                <Link
                  to={`/tracks/${encodeURIComponent(card.slug)}/${card.code}`}
                >
                  <CustomButton
                    text={card.buttonText}
                    backgroundColor="#fff"
                    borderRadius="50px"
                    borderColor="none"
                    width="182px"
                    textColor="var(--button-primary)"
                    fontSize="16px"
                    fontFamily="Raleway"
                    fontWeight="bold"
                    iconColor="var(--button-primary)"
                    icon={<IoIosArrowForward />}
                    justifyContent="space-between"
                  />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* popular track cards container */}
      <div className="popular-track-cards-container outer-full">
        <div className="inner">
          <h1>Popular Tracks</h1>
          <div className="popular-track-cards">
            {popularTracks.map((track, index) => (
              <div key={index} className="popular-track-card">
                <div
                  className="popular-track-card-image"
                  style={{
                    backgroundImage: `url(${track.image})`,
                  }}
                ></div>
                <div className="popular-track-card-text">
                  <div className="popular-track-title">
                    <Link
                      to={`/tracks/${encodeURIComponent(track.slug)}/${
                        track.code
                      }`}
                    >
                      <h1>{track.title}</h1>
                    </Link>
                    <h2>{track.description}</h2>
                  </div>
                  <div className="popular-track-price-container">
                    <div className="popular-track-discount-price">
                      <div className="popular-track-discount-amount">
                        <span>₹</span>
                        <p>{track.sellingPrice.toLocaleString()}</p>
                      </div>
                      <div className="popular-track-discount-percentage">
                        {Math.round(
                          ((parseFloat(track.costPrice) -
                            parseFloat(track.sellingPrice)) *
                            100) /
                            parseFloat(track.costPrice)
                        )}
                        % Off
                      </div>
                    </div>
                    <div className="popular-track-cost-price">
                      <span>₹{track.costPrice.toLocaleString()}</span>
                    </div>
                  </div>
                  <div className="popular-explore-track-button">
                    <div>
                      <Link
                        to={`/tracks/${encodeURIComponent(track.slug)}/${
                          track.code
                        }`}
                      >
                        <CustomButton
                          text={track.buttonText}
                          backgroundColor="#fff"
                          borderRadius="50px"
                          borderColor="1px solid #CCCCCC"
                          width="186px"
                          height="37px"
                          textColor="#212328"
                          iconColor="#212328"
                          fontSize="16px"
                          fontFamily="Inter"
                          fontWeight="550"
                          icon={<IoIosArrowForward />}
                          justifyContent="space-between"
                        />
                      </Link>
                    </div>
                    <div>
                      <CustomButton
                        icon={<RiShoppingCart2Line />}
                        isIconOnly={true}
                        backgroundColor="#272727"
                        iconColor="#fff"
                        borderRadius="50px"
                        height="37px"
                        width="37px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* all songs container */}
      <div style={{ marginTop: "2px" }}>
        <AllTrackCards />
      </div>
    </div>
  );
};

export default TrackListing;
