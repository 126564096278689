import React, { useEffect, useState } from "react";
import styles from "./PopUpModal.module.css";
import { IoMdCheckmark } from "react-icons/io";
import CustomButton from "../CustomButton/CustomButton";
import { Link } from "react-router-dom";

const PopUpModal = ({ isOpen, onClose, modalData, onOutsideClick }) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(isOpen); // Sync with parent initially
  const [countdown, setCountdown] = useState(5); // Countdown timer state

  // Sync `isPopUpOpen` with `isOpen` prop whenever it changes
  useEffect(() => {
    setIsPopUpOpen(isOpen);
  }, [isOpen]);

  // Countdown timer logic
  useEffect(() => {
    if (isPopUpOpen && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer); // Cleanup on unmount or when countdown changes
    }

    // Redirect when countdown reaches 0
    if (countdown === 0) {
      const redirectUrl = modalData.textRedirect || modalData.btnredirect;
      window.location.href = redirectUrl; // Navigate to the redirect URL
    }
  }, [isPopUpOpen, countdown, modalData]);

  // Function to close the modal
  const closeModal = () => {
    setIsPopUpOpen(false); // Close modal locally
    onClose(); // Notify the parent to close the modal
  };

  // Close the modal if the click happens outside the modalContainer
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      // closeModal();
      onOutsideClick && onOutsideClick(); // Notify the parent about the outside click
    }
  };

  if (!isPopUpOpen) return null; // Don't render if the modal is closed
  //   console.log(modalData, "modalData");

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContainer}>
        <div className={styles.iconContainer}>
          <IoMdCheckmark color="#fff" size={40} />
        </div>
        <div className={styles.container}>
          <p className={styles.modalHeading}>{modalData.title}</p>
          <span className={styles.modalSubHeading}>{modalData.subTitle}</span>
        </div>
        <div className={styles.btnContainer}>
          <Link to={modalData.btnredirect}>
            <CustomButton
              text={modalData.btntext}
              backgroundColor="#0D0D0D"
              borderRadius="50px"
              width="333px"
              height="50px"
              textColor="#fff"
              fontSize="18px"
              fontFamily="Raleway"
              fontWeight="550"
            />
          </Link>
          {modalData.redirectText && ( // Check if `redirectText` exists
            <Link to={modalData.textRedirect}>
              <p className={styles.redirectText}>{modalData.redirectText}</p>
            </Link>
          )}
        </div>
        {/* Countdown Timer */}
        <p className={styles.countdownText}>
          Redirecting in {countdown} second{countdown > 1 ? "s" : ""}...
        </p>
      </div>
    </div>
  );
};

export default PopUpModal;
