import React, { useState } from "react";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomInput from "../components/CustomInput/CustomInput";
import styles from "../styles/ContactUs.module.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    message: "",
    phoneNumber: "",
  });

  const [emailError, setEmailError] = useState(""); // State to store email error message

  const handleInputChange = (field, value) => {
    if (field === "phoneNumber" && !/^\d*$/.test(value)) {
      return; // Allow only digits for phoneNumber
    }

    if (field === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
      if (!emailRegex.test(value) && value !== "") {
        setEmailError("Invalid email format"); // Set error message
      } else {
        setEmailError(""); // Clear error message
      }
    }

    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (emailError) {
      alert("Please fix the errors before submitting.");
      return;
    }
    console.log("Form Data:", formData);
    alert("Form submitted successfully!");
  };

  return (
    <div className={`${styles.parentContainer} ${styles.outer}`}>
      <div className={styles.inner}>
        <h2>Contact Us</h2>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <CustomInput
            placeholder="Name"
            height="50px"
            borderRadius="4px"
            marginTop="5px"
            onChange={(e) => handleInputChange("name", e.target.value)}
          />

          <div className={styles.phoneInput}>
            <select className={styles.countryCode}>
              <option value="+91">+91</option>
            </select>
            <input
              type="text"
              placeholder="Enter Phone Number"
              className={styles.phoneNumber}
              value={formData.phoneNumber}
              onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
              maxLength={10}
            />
          </div>

          <CustomInput
            placeholder="Email"
            height="50px"
            borderRadius="4px"
            marginTop="5px"
            type="email"
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
          {emailError && <p className={styles.errorMsg}>{emailError}</p>}

          <CustomInput
            placeholder="Company Name"
            height="50px"
            borderRadius="4px"
            marginTop="5px"
            onChange={(e) => handleInputChange("companyName", e.target.value)}
          />

          <div className={styles.textareaContainer}>
            <textarea
              placeholder="Message"
              className={styles.textarea}
              onChange={(e) => handleInputChange("message", e.target.value)}
            />
          </div>

          <CustomButton
            text="Submit"
            backgroundColor="#000"
            textColor="#fff"
            width="100%"
            type="submit"
          />
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
