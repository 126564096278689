import React from "react";
import "./PlaylistCard.css";
import { FaRegHeart } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
// import { RiShoppingCart2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import randomDefaultImage from "../../../utils/RandomDefaultImage";
import { FaHeart } from "react-icons/fa";

const CardComponent = ({
  packCards,
  packType,
  showPrice = true,
  showLikeIcon = false,
  likedStatus = false,
  handlePlaylistRemoveLike,
}) => {
  // console.log(packCards);

  // const handlePlaylistRemoveLike = () => {};

  return (
    <div className="packs-cards-container" data-type={packType}>
      {packCards.map((pack, index) => (
        <div key={index} className="pack-card">
          <div className="pack-title">
            <Link
              to={`/playlists/${encodeURIComponent(pack.packNameSlug)}/${
                pack.packCode
              }`}
            >
              <h1 className="pack-heading">
                {pack.title.length > 35
                  ? pack.title.substring(0, 32) + "..."
                  : pack.title}
              </h1>
            </Link>
            {/* <span>Pack of {pack.totalTracks} tracks</span> */}
            <span>{pack.totalTracks} tracks</span>
          </div>

          {/* <Link
            to={`/playlists/${encodeURIComponent(pack.packNameSlug)}/${
              pack.packCode
            }`}
          > */}
          <div
            className="pack-card-img"
            style={{
              // backgroundImage: `url(${pack.image})`,
              backgroundImage:
                pack.image != ""
                  ? `url(${pack.image})`
                  : `url(${randomDefaultImage("pack")})`,
            }}
          >
            {showLikeIcon && (
              <div className="card-like-info">
                <div
                  style={{ opacity: likedStatus ? 100 : 50 }}
                  className="like-icon"
                >
                  {likedStatus ? (
                    <FaHeart
                      onClick={() => handlePlaylistRemoveLike(pack.packCode)}
                      color="#E90B2F"
                      size={22}
                    />
                  ) : (
                    <FaRegHeart size={24} color="#fff" />
                  )}
                </div>
                {/* <div className="card-track-total">
                  <p>{pack.totalTracks}</p>
                  <span>TRACKS</span>
                  </div> */}
              </div>
            )}
          </div>
          {/* </Link> */}

          <div className="pack-price-button">
            {/* <div className="pack-price-container">
              <div className="pack-discount-price">
                <div className="pack-discount-amount">
                  <span>₹</span>
                  <p>99,999</p>
                </div>
                {showPrice && (
                  <div className="pack-discount-percentage">23% Off</div>
                )}
              </div>
              {showPrice && (
                <div className="pack-cost-price">
                  <p>Cost:</p>
                  <span>₹1,29,999</span>
                </div>
              )}
            </div> */}
            {showPrice && (
              <div className="explore-track-button">
                <Link
                  to={`/playlists/${encodeURIComponent(pack.packNameSlug)}/${
                    pack.packCode
                  }`}
                  className="explore-pack-btn"
                >
                  {pack.buttonText}
                  <IoIosArrowForward color="var(--button-primary)" size={20} />
                </Link>

                {/* <button className="explore-pack-cart-btn">
                  {<RiShoppingCart2Line color="#fff" size={20} />}
                </button> */}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardComponent;
