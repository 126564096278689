const categories = {
  "eafd60a8-c0ab-4504-a3af-96ed441d267a": {
      "name": "Advertising",
      "description": "High-energy tracks and jingles to elevate your brand's visibility and create memorable campaigns. Use cases: TV commercials, radio ads, digital ads.",
  },
  "5b53b4ff-a802-4b5d-bcef-6ec3fcd727c8": {
      "name": "Automobile",
      "description": "High-energy tracks for car commercials, road trips, and thrilling drive visuals.",
  },
  "c63cbc85-930c-40bd-8e8c-84f252c4f7f6": {
      "name": "Background Scores",
      "description": "Subtle and evocative background music to enhance the mood and atmosphere of your videos and presentations. Use cases: Documentaries, corporate videos, explainer videos.",
  },
  // "5a1e36b9-614b-4540-a81c-6ad79db245b0": {
  //     "name": "Beauty & Skincare",
  //     "description": "Elegant and sophisticated music for beauty and skincare content. Use Cases: Beauty vlogs, makeup tutorials, skincare reviews, hair tutorials, product reviews",
  // },
  "ff81cbd0-c571-4ffd-9349-136fd04f64c0": {
      "name": "Beauty & Wellness",
      "description": "Elegant and sophisticated music for beauty and skincare content. Use Cases: Beauty vlogs, makeup tutorials, skincare reviews, hair tutorials, product reviews",
  },
  "ed70513b-a743-42ec-95a5-248c60e13800": {
      "name": "Bumpers ",
      "description": "Short, attention-grabbing sound effects and music stings to transition between segments in your videos. Use cases: YouTube videos, podcasts, live streams.",
  },
  "64538398-f88b-431b-a63c-b79971fbed6a": {
      "name": "Comedy ",
      "description": "Funny and quirky music to add humor and levity to your comedic content. Use cases: Comedy sketches, stand-up comedy, satirical videos.",
  },
  "0e01f907-a8cd-4e71-9d73-81097fe21789": {
      "name": "Corporate ",
      "description": "Professional and sophisticated music to enhance your corporate communications and presentations. Use cases: Corporate videos, webinars, presentations.",
  },
  "3c48a7fb-291e-4db9-bee0-526d36472585": {
      "name": "Devotional ",
      "description": "Soothing and spiritual music for religious and devotional content. Use cases: Religious videos, meditation apps, yoga videos.",
  },
  "07f3896a-efc7-4d24-8a78-8b6a72d775ed": {
      "name": "E-Commerce",
      "description": "Upbeat tunes to enhance product demos, ads, and shopping experiences.",
  },
  "4d12fd79-69b5-4482-9a7d-61cfe521439c": {
      "name": "Fashion",
      "description": "Stylish and trendy music to complement your fashion and beauty content. Use cases: Fashion vlogs, beauty tutorials, product reviews.",
  },
  "8c1b166d-087c-40ac-bd92-6bce898d3488": {
      "name": "Festive Content",
      "description": "Festive and celebratory music for special occasions and holidays. Use cases: Holiday videos, event promotions, social media posts.",
  },
  "9f01440b-5e9a-4252-ba8e-b2b63f6fc185": {
      "name": "Film & OTT Content",
      "description": "Dramatic, cinematic, and emotive music for films and OTT content. Use Cases: Movie trailers, web series, documentaries, short films, TV commercials",
  },
  "e1b380a3-8fc2-4c18-82c4-ca9afe281fdf": {
      "name": "Financial Services",
      "description": "",
  },
  "d096a310-adf6-4974-8ff6-3ca8e5d0e55c": {
      "name": "Food",
      "description": "Upbeat and appetizing music to accompany your culinary content. Use cases: Food blogs, cooking shows, recipe videos.",
  },
  "0ea970e0-a57e-40c9-b46e-2d04a74c20ab": {
      "name": "Gaming ",
      "description": "Energetic and immersive music to enhance your gaming experiences. Use cases: Gaming videos, live streams, game trailers.",
  },
  "26f05bb3-c7a1-4409-a28e-6f9003c976d5": {
      "name": "Global Occasions",
      "description": "International music tracks to celebrate global events and cultures. Use cases: Travel vlogs, cultural documentaries, language learning videos.",
  },
  "4f4624b6-9f8a-458d-82d1-77a844691856": {
      "name": "Health & Fitness",
      "description": "Motivational and energetic music to inspire your fitness and wellness content. Use cases: Workout videos, fitness apps, yoga tutorials.",
  },
  "b8e9fb8f-69fc-40c8-b1d0-09f6f81e92fa": {
      "name": "Indian Festivals",
      "description": "Traditional and contemporary Indian music for celebrating Indian festivals. Use cases: Festival videos, cultural documentaries, devotional content.",
  },
  "11334cec-2010-4885-bc35-f0f995d42701": {
      "name": "Intros & Outros",
      "description": "Catchy intro and outro music to grab your audience's attention and leave a lasting impression. Use cases: YouTube videos, podcasts, online courses.",
  },
  "1d785d09-f059-4952-98eb-9c21d3864890": {
      "name": "Jingles",
      "description": "Short, catchy jingles to promote your brand or product. Use cases: TV commercials, radio ads, social media ads.",
  },
  "f872e350-5430-46a1-aca0-af292a9c13b9": {
      "name": "Kids",
      "description": "Fun and playful music for children's content. Use cases: Children's videos, educational content, toys and games.",
  },
  "3446ca31-c19c-4eab-8c97-a0b6bcf8369b": {
      "name": "Learning & Ed-Tech",
      "description": "Focused and engaging music to enhance learning and education. Use Cases: Educational videos, online courses, tutorials, explainer videos, webinars",
  },
  "f909fd19-7d11-4bb8-9ef0-33a1852b2278": {
      "name": "Meditation",
      "description": "Calming and relaxing music to promote mindfulness and relaxation. Use cases: Meditation apps, yoga videos, sleep aids.",
  },
  "953971e2-fd76-416d-b570-c0ab566302c1": {
      "name": "Movies",
      "description": "Dramatic and cinematic music to elevate your film projects. Use cases: Short films, feature films, movie trailers.",
  },
  "3f0ff233-984f-4ebb-a2f2-982e4b7472f1": {
    "name": "Nonprofits & NGO",
    "description": "",
  },
  "1e615e83-0a74-40b0-b65f-b4af467705e3": {
      "name": "OTT / Webshows",
      "description": "Cinematic sounds to elevate trailers, intros, and episodic storytelling.",
  },
  "ba2205b8-c398-4e70-b65c-0f5df5a75adc": {
      "name": "Patriotic ",
      "description": "Inspiring and patriotic music to commemorate national events and holidays. Use cases: Patriotic videos, documentaries, historical content.",
  },
  "84a88317-f88c-4432-85df-5ab81e8a1588": {
      "name": "Podcasts & Audiobooks",
      "description": "Engaging and immersive background music to enhance your audio content. Use cases: Podcasts, audiobooks, storytelling.",
  },
  "c3461e1c-aa33-4c75-949a-bf84a80744db": {
      "name": "Product Launches",
      "description": "Dynamic tracks to amplify unveilings, promos, and brand showcases.",
  },
  "264c7ea7-fd2b-4485-9957-58a57f09e54d": {
      "name": "Promos & Trailers",
      "description": "Dynamic and attention-grabbing music to promote your products and services. Use cases: Product launches, event promotions, movie trailers.",
  },
  "c5cbe26e-074b-447e-bf9f-256055854732": {
      "name": "Shorts & Reels",
      "description": "Short and snappy music tracks to create engaging short-form content. Use cases: TikTok videos, Instagram Reels, YouTube Shorts.",
  },
  "538f6a59-7a05-46f9-8abf-d78a915549a1": {
      "name": "Shows",
      "description": "Versatile music tracks to enhance the mood and atmosphere of your TV shows and web series. Use cases: TV shows, web series, reality shows.",
  },
  "21b41ad8-b66c-4a79-bd24-4485db9c7dbc": {
      "name": "Sports ",
      "description": "Energetic and exciting music to accompany sports highlights and events. Use cases: Sports broadcasts, sports documentaries, highlight reels.",
  },
  "5799c0ea-7399-46ad-a047-aeeaa80e9d57": {
      "name": "Technology ",
      "description": "",
  },
  "33b2bf23-f5b5-4398-aa51-7046a2922691": {
      "name": "Travel ",
      "description": "Adventurous and inspiring music to complement your travel content. Use cases: Travel vlogs, travel documentaries, destination marketing.",
  },
  "4f95ba81-8a7d-453d-8a5d-325ca9b57feb": {
      "name": "Unboxing & Reviews",
      "description": "Upbeat and engaging music to enhance your product reviews and unboxing videos. Use cases: Product reviews, tech reviews, unboxing videos.",
  },
  "7f0b6aac-1d3e-4262-8063-52eb57e3430c": {
      "name": "Wedding & Reception",
      "description": "Romantic and celebratory music for weddings and receptions. Use cases: Wedding videos, wedding invitations, reception music.",
  }
};

export default categories;
