import axios from "axios";
import config from "../utils/config";
import { urls } from "../utils/AppManager";
import { getAuthHeaders } from "../utils/headers";

export const packLike = async (payload, token) => {
  const url = `${config.baseUrl}${urls.playlistLike}`;
  try {
    const res = await axios.post(url, payload, {
      headers: {
        Authorization: token, // Add the token here with brearer key word already present
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const trackLike = async (payload, token) => {
  const url = `${config.baseUrl}${urls.trackLike}`;
  try {
    const res = await axios.post(url, payload, {
      headers: {
        Authorization: token, // Add the token here with brearer key word already present
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};
// track like and pack like need to be deprecreated

export const createUserLike = async (payload) => {
  const url = `${config.baseUrl}${urls.user}/like`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const getLikes = async () => {
  const url = `${config.baseUrl}${urls.user}/likes`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const createPlaylist = async (payload, token) => {
  const url = `${config.baseUrl}${urls.userCreatePlayList}`;
  try {
    const res = await axios.post(url, payload, {
      headers: {
        Authorization: token, // Add the token here with brearer key word already present
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const addTrackToPlaylist = async (payload, token, playlistId) => {
  // 2f9e21d1-9b8a-4cbd-a0ab-fe8b4fccb43f
  const url = `${config.baseUrl}${urls.addTrackToPlaylist}/${playlistId}`;
  try {
    const res = await axios.post(url, payload, {
      headers: {
        Authorization: token, // Add the token here with brearer key word already present
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const getUserPlaylist = async (token) => {
  const url = `${config.baseUrl}${urls.getUserPlaylist}`;
  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: token, // Add the token here with brearer key word already present
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
}; // need to deprecreated

export const getUserCollections = async () => {
  const url = `${config.baseUrl}${urls.user}/collections`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const updateProfile = async (payload) => {
  const url = `${config.baseUrl}${urls.user}`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.put(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};

export const getUserProfile = async () => {
  const url = `${config.baseUrl}${urls.user}`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};

export const userAllAddress = async () => {
  const url = `${config.baseUrl}${urls.user}/addresses`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};

export const createAddress = async (payload) => {
  const url = `${config.baseUrl}${urls.user}/addresses`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};

export const getUserPurchases = async () => {
  const url = `${config.baseUrl}${urls.user}/purchases`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};
