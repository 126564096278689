import React, { useState, useEffect, useCallback } from "react";
import "./AllTrackCard.css";
import { IoIosArrowForward } from "react-icons/io";
import { getAllTrack } from "../../../service/TrackService";
import { Link } from "react-router-dom";
import randomDefaultImage from "../../../utils/RandomDefaultImage";
import getDirectLink from "../../../utils/AudioUrlFormated";
import TrackCard from "./TrackCard";
import TrackFilter from "./TrackFilter";
import config from "../../../utils/config";

const AllTrackCards = () => {
  const [allTrackList, setAllTrackList] = useState([]);
  const [loading, setLoading] = useState(false); // To control the loading state
  const [page, setPage] = useState(1); // For pagination
  const [totalAllTracks, setTotalAllTracks] = useState(0); // To store total number of items
  const [isFilterApplied, setFilterApplied] = useState(false); // Track if filters are applied

  // Fetch tracks with pagination
  const fetchTracks = useCallback(
    async (page) => {
      if (isFilterApplied) return; // Don't fetch if filters are applied

      setLoading(true);
      try {
        const response = await getAllTrack(page);
        //   console.log(response);
        const data = response.data.tracks || [];
        setTotalAllTracks(response.data.totalTracks);
        setAllTrackList((prevTrackList) => [...prevTrackList, ...data]); // Append new data to existing list

        setLoading(false);
      } catch (error) {
        console.error("Error fetching track list:", error);
        setLoading(false);
      }
    },
    [isFilterApplied]
  );

  useEffect(() => {
    fetchTracks(page); // Initial fetch when component mounts
  }, [page, fetchTracks]);

  // Handle scrolling to detect when we reach the bottom of the container
  // const handleScroll = (e) => {
  //   // const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
  //   const bottom =  window.innerHeight + document.documentElement.scrollTop
  //   === document.documentElement.offsetHeight;
  //   console.log(totalAllTracks, allTrackList.length);
  //   if (bottom && !loading && allTrackList.length <= totalAllTracks) {
  //     setPage((prevPage) => prevPage + 1); // Load the next page
  //   }
  // };

  const handleScroll = (e) => {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight =
      window.innerHeight || document.documentElement.clientHeight;

    const scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight - 200; // 200px threshold

    if (scrolledToBottom && !loading && allTrackList.length < totalAllTracks) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    // const container = document.querySelector(".all-songs");
    // if (container) {
    //   container.addEventListener("scroll", handleScroll);
    //   return () => {
    //     container.removeEventListener("scroll", handleScroll);
    //   };
    // }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // }, [loading]); // Ensure to remove the listener when loading state changes
  }, [handleScroll]); // Ensure to remove the listener when loading state changes

  // fucntion to format the data for track cards
  const allTracks = allTrackList.map((track) => {
    const { costPrice, sellingPrice } = track.SKU[0]; // Extract costPrice and sellingPrice
    const discountPercent = (
      ((costPrice - sellingPrice) / costPrice) *
      100
    ).toFixed(0); // Calculate discount percentage

    const artistNames = track.primaryArtists?.length
      ? track.primaryArtists.map((artist) => artist.artistName).join(", ")
      : "";

    return {
      image: track.imageUrl || randomDefaultImage("track"),
      title:
        track.name.length > 18
          ? track.name.substring(0, 17) + "..."
          : track.name,
      description:
        artistNames.length > 30
          ? artistNames.substring(0, 29) + "..."
          : artistNames,
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: discountPercent || "50% OFF",
      costPrice: costPrice.toLocaleString() || "19,999",
      sellingPrice: sellingPrice.toLocaleString() || "79,000",
      name: track.name_slug,
      trackCode: track.trackCode,
      //   trackUrl: getDirectLink(track.link),
      trackUrl: track.mp3Link || getDirectLink(track.link),
      waveJson: track.waveformLink,
    };
  });

  // Callback to update the track list when filters are applied
  const handleFiltersApplied = (filteredTracks) => {
    // console.log(filteredTracks, "filteredTracks");
    setAllTrackList(filteredTracks);
    setFilterApplied(true);
  };

  const handleClearFilters = () => {
    setAllTrackList([]); // Clear the current list
    setFilterApplied(false);
    setPage(1); // Reset pagination
    fetchTracks(page); // Fetch the initial track list
  };

  const marketingCards = [
    {
      position: 12,
      // image: `${config.s3imgUrl}web/promotional/tracks/card1.webp`,
      image: `${config.s3imgUrl}web/promotional/tracks/scrolling.gif`,
    },
    {
      position: 21,
      // image: `${config.s3imgUrl}web/promotional/tracks/card2.webp`,
      image: `${config.s3imgUrl}web/promotional/tracks/spotlight.gif`,
    },
    {
      position: 36,
      // image: `${config.s3imgUrl}web/promotional/tracks/card3.webp`,
      image: `${config.s3imgUrl}web/promotional/tracks/boost.gif`,
    },
    {
      position: 45,
      // image: `${config.s3imgUrl}web/promotional/tracks/card3.webp`,
      image: `${config.s3imgUrl}web/promotional/tracks/budget.gif`,
    },
    {
      position: 60,
      // image: `${config.s3imgUrl}web/promotional/tracks/card3.webp`,
      image: `${config.s3imgUrl}web/promotional/tracks/looking.gif`,
    },
  ];

  return (
    <div className="all-songs-container inner">
      <div className="all-songs-filter">
        <TrackFilter
          onFiltersApplied={handleFiltersApplied}
          isFilterApplied={isFilterApplied}
          onClearFilters={handleClearFilters} // Pass callback for clearing
        />
      </div>
      <div className="inner">
        <div className="alltrack-title">
          <p className="alltrack-title-text-heading">All Tracks</p>
          <Link to={"/tracks"}>
            <div className="alltrack-viewall">
              <p className="alltrackview-heading">View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div>
          </Link>
        </div>
        <div className="all-songs">
          {/* packType="allpacks" */}
          <TrackCard marketingCards={marketingCards} trackCard={allTracks} />
          {loading && <p>Loading more...</p>}
          {/* Show loading indicator */}
        </div>
      </div>
    </div>
  );
};

export default AllTrackCards;
