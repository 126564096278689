// src/redux/cartSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [], // Array to store cart items
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Action to add an item to the cart
    addToCart: (state, action) => {
      const itemIndex = state.items.findIndex(
        (item) => item.code === action.payload.code
      );

      if (itemIndex !== -1) {
        // If item already exists in the cart, update quantity
        state.items[itemIndex].quantity += 1;
      } else {
        // If item is new, add it to the cart with quantity 1
        state.items.push({ ...action.payload, quantity: 1 });
      }
    },

    // Action to increment the quantity of an item
    incrementQuantity: (state, action) => {
      const item = state.items.find((item) => item.code === action.payload);
      if (item) {
        item.quantity += 1;
      }
    },

    // Action to decrement the quantity of an item
    decrementQuantity: (state, action) => {
      const item = state.items.find((item) => item.code === action.payload);
      if (item && item.quantity > 1) {
        item.quantity -= 1;
      }
    },

    // Action to remove an item from the cart
    removeFromCart: (state, action) => {
      state.items = state.items.filter((item) => item.code !== action.payload);
    },

    // Action to clear the cart
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const {
  addToCart,
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
  clearCart,
} = cartSlice.actions;

// Selector to get total item count from the cart
export const selectItemCount = (state) =>
  state.cart.items.reduce((total, item) => total + (item.quantity || 1), 0);

export default cartSlice.reducer;
