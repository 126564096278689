export const urls = {
  getTrack: "tracks",
  getMultiTrack: "multi",
  getTrackFilters: "filters/track",
  getPack: "playlists",
  getPackFilters: "filters/playlist/packs",
  getSearchResult: "search/global",
  getSearchResultfaceted: "search/faceted",
  playlistLike: "user/likePlaylist",
  getLikes: "user/likes",
  trackLike: "user/likeTrack",
  userCreatePlayList: "user/createPlaylist",
  addTrackToPlaylist: "user/tracks/addTrack",
  getUserPlaylist: "user/userPlaylists",
  sendOtp: "otp/send-otp",
  verifyOtp: "otp/verify-otp",
  userLogin: "auth/consumer/login",
  cart: "cart",
  createPruchaseOrder: "order",
};
