import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    globalPlayerActive: false, // Initial state
  },
  reducers: {
    setGlobalPlayerActive: (state, action) => {
      state.globalPlayerActive = action.payload; // Toggle the state
    },
  },
});

export const { setGlobalPlayerActive } = uiSlice.actions;
export default uiSlice.reducer;
