// remoteConfig.js
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";
import { firebaseApp } from "./firebaseConfig";

// Initialize Remote Config
const remoteConfig = getRemoteConfig(firebaseApp);
// console.log(remoteConfig, "remote");

// Set up Remote Config settings
remoteConfig.settings = {
  minimumFetchIntervalMillis: 0, // Fetch interval of 1 hour
};

// Default values
remoteConfig.defaultConfig = {
  welcome_message: "Welcome to our app!",
};

export { remoteConfig, fetchAndActivate, getValue };
