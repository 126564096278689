// firebaseConfig.js
import { initializeApp } from "firebase/app";

// Your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyCzpVO7IrVuaRYtGuVgg6ZM_-R2m_9deWk",
  authDomain: "hoopr-select-staging.firebaseapp.com",
  projectId: "hoopr-select-staging",
  storageBucket: "hoopr-select-staging.firebasestorage.app",
  messagingSenderId: "120242217783",
  appId: "1:120242217783:web:10b62fdfe3a2bc763b70b2",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export { firebaseApp };
