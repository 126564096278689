import React, { useState, useContext } from "react";
import "./PackCard.css";
import { FaRegHeart } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { RiShoppingCart2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import randomDefaultImage from "../../../utils/RandomDefaultImage";
import { addToCart } from "../../../utils/redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContext } from "../../../utils/ToastContext";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "../../../utils/firebase/remoteConfig";
import { putCart } from "../../../service/TransactionService";
import { getCookie } from "../../../utils/AuthContext";

const CardComponent = ({
  packCards,
  packType,
  showPrice = true,
  showLikeIcon = false,
}) => {
  const dispatch = useDispatch(); // Hook to dispatch actions
  const userPhoneNumber = getCookie("userPhoneNumber"); // Get the user's phone number from cookies to identify the user
  const items = useSelector((state) => state.cart.items); // Redux selector

  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  // console.log(packCards);

  // Utility to fetch item quantity from Redux state
  const getItemQuantity = (trackCode) =>
    items.find((item) => item.code === trackCode)?.quantity || 0;

  const handleCartClick = async (pack) => {
    // console.log(pack, "pack info for cart");
    const cartInfo = {
      cardType: "pack", // card type to display
      preText: pack.title, // pack name
      secText: "", // pack description
      code: pack.packCode, // pack code
      sp: pack.sellingPrice, // selling price
      cp: pack.costPrice, // cost price
      cardImg: pack.image, // card img based on pack
      skuID: pack.skuId || "",
    };

    // Get the current quantity for the track
    const currentQuantity = getItemQuantity(pack.packCode);

    const updatedQuantity = currentQuantity + 1; // Increment quantity
    // console.log("Updated Quantity:", updatedQuantity);

    // preparign the payload for api call
    const payload = {
      items: [{ skuId: pack.skuId || "", qty: updatedQuantity, cartType: "C" }],
    };

    await fetchAndActivate(remoteConfig); // Fetch and activate Remote Config
    const jsonString = getValue(remoteConfig, "auth").asString(); // Fetch the auth config
    const parsedConfig = JSON.parse(jsonString);
    const matchingEntry = Object.entries(parsedConfig).find(
      ([phoneNumber, userData]) => phoneNumber === userPhoneNumber
    );

    if (matchingEntry) {
      const [phoneNumber, userData] = matchingEntry;
      // console.log(
      //   `Matching entry found for phone number: ${phoneNumber}`,
      //   userData.token
      // );
      const addCart = await putCart(userData.token, payload);
      // console.log(addCart, "cart add");
      if (addCart?.error?.code === 0) {
        // Dispatch action to add the trak in the redux
        dispatch(addToCart(cartInfo));
        showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg
      } else {
        showToast("Added to cart", "Failed to update the cart", "error"); //display toast msg
      }
    }

    // Dispatch action to add the track to the cart
    // dispatch(addToCart(cartInfo));
    // showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg

    // console.log(cartInfo, "cartInfo");
  };

  return (
    <div className="packs-cards-container" data-type={packType}>
      {packCards.map((pack, index) => (
        <div key={index} className="pack-card">
          <div className="pack-title">
            <Link
              to={`/playlists/pack-${encodeURIComponent(pack.packNameSlug)}/${
                pack.packCode
              }`}
            >
              <h1 className="pack-heading">
                {pack.title.length > 35
                  ? pack.title.substring(0, 32) + "..."
                  : pack.title}
              </h1>
            </Link>
            {/* <span>Pack of {pack.totalTracks} tracks</span> */}
            <span>{pack.totalTracks} tracks</span>
          </div>

          <Link
            to={`/playlists/pack-${encodeURIComponent(pack.packNameSlug)}/${
              pack.packCode
            }`}
          >
            <div
              className="pack-card-img"
              style={{
                // backgroundImage: `url(${pack.image})`,
                backgroundImage:
                  pack.image != ""
                    ? `url(${pack.image})`
                    : `url(${randomDefaultImage("pack")})`,
              }}
            >
              {showLikeIcon && (
                <div className="card-like-info">
                  <div className="like-icon">
                    <FaRegHeart size={24} color="#fff" />
                  </div>
                  {/* <div className="card-track-total">
                  <p>{pack.totalTracks}</p>
                  <span>TRACKS</span>
                  </div> */}
                </div>
              )}
            </div>
          </Link>

          <div className="pack-price-button">
            <div className="pack-price-container">
              <div className="pack-discount-price">
                <div className="pack-discount-amount">
                  <span>₹</span>
                  <p>{Number(pack.sellingPrice)?.toLocaleString("en-IN")}</p>
                </div>
                {showPrice && pack.costPrice > 0 && (
                  <div className="pack-discount-percentage">
                    {pack.discountPercent}
                  </div>
                )}
              </div>
              {showPrice && (
                <div className="pack-cost-price">
                  {/* <p>Cost:</p> */}
                  <span>
                    ₹{Number(pack.costPrice)?.toLocaleString("en-IN")}
                  </span>
                </div>
              )}
            </div>
            {showPrice && (
              <div className="explore-track-button">
                <Link
                  to={`/playlists/pack-${encodeURIComponent(
                    pack.packNameSlug
                  )}/${pack.packCode}`}
                  className="explore-pack-btn"
                >
                  {pack.buttonText}
                  <IoIosArrowForward color="var(--button-primary)" size={20} />
                </Link>

                <button
                  onClick={() => {
                    handleCartClick(pack);
                  }}
                  className="explore-pack-cart-btn"
                >
                  {<RiShoppingCart2Line color="#fff" size={20} />}
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardComponent;
