import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import "./App.css";
import Header from "./screens/Header";
import Home from "./screens/Home";
import TrackPage from "./screens/TrackPage";
import TrackListing from "./screens/TrackListing";
import PackListing from "./screens/PackListing";
import Playlist from "./screens/Playlist";
import PackPage from "./screens/PackPage";
import Footer from "./screens/Footer";
import GlobalPlayer from "./screens/GlobalPlayer";
import { GlobalPlayerProvider } from "./utils/GlobalPlayerContext";
import TermsAndConditions from "./screens/TermsAndConditions";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Faq from "./screens/Faq";
import CategoryPage from "./screens/CategoryPage";
import Moodspage from "./screens/MoodsPage";
import GenrePage from "./screens/GenrePage";
import SearchPage from "./screens/SearchPage";
import MoodsListing from "./screens/MoodsListing";
import CategoryListing from "./screens/CategoryListing";
import GenreListing from "./screens/GenreListing";
import { AuthProvider, useAuth } from "./utils/AuthContext";
import Checkout from "./screens/Checkout";
import { ToastProvider } from "./utils/ToastContext";
import ToastComponent from "./components/ToastComponent/ToastComponent";
import MyAccount from "./screens/MyAccount/MyAccount";
import Foryoupage from "./screens/ForyouPage";
import ContactUs from "./screens/ContactUs";

//  FOR MS CLARITY
import Clarity from "@microsoft/clarity";

//  INITIALISE CLARITY
const projectId = "pqnlvyi68x";
Clarity.init(projectId);

Clarity.consent(false);

/*
  THIS HAS BEEN CREATED WITH THE INTENT OF HIDING THE HEADER ON THE CHECKOUT PAGE
*/
const HeaderWrapper = () => {
  const location = useLocation();

  // Hide header on checkout page
  if (location.pathname === "/checkout") {
    return null;
  }

  return <Header />;
};

/*
  THIS HAS BEEN CREATED WITH THE INTENT OF HIDING THE FOOTER ON THE CHECKOUT PAGE
*/
const FooterWrapper = () => {
  const location = useLocation();

  // Hide header on checkout page
  if (location.pathname === "/checkout") {
    return null;
  }

  return <Footer />;
};

/*
  THIS HAS BEEN CREATED WITH THE INTENT OF HIDING THE GLOBAL PLAYER ON THE CHECKOUT PAGE
*/
const GPWrapper = () => {
  const location = useLocation();

  // Hide header on checkout page
  if (location.pathname === "/checkout") {
    return null;
  }

  return <GlobalPlayer />;
};

const ProtectedRoutes = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/tracks/:name/:trackCode" element={<TrackPage />} />
      <Route path="/tracks" element={<TrackListing />} />
      <Route path="/packs" element={<PackListing />} />
      <Route path="/playlists" element={<Playlist />} />
      <Route path="/playlists/:packNameSlug/:packCode" element={<PackPage />} />
      <Route
        path="/categories/:name/:categoryCode"
        element={<CategoryPage />}
      />
      <Route path="/moods/:name/:moodsCode" element={<Moodspage />} />
      <Route path="/genres/:name/:genreCode" element={<GenrePage />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/faqs" element={<Faq />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/moods" element={<MoodsListing />} />
      <Route path="/categories" element={<CategoryListing />} />
      <Route path="/genres" element={<GenreListing />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/user" element={<MyAccount />} />
      <Route path="/foryou" element={<Foryoupage />} />
      <Route path="/contact" element={<ContactUs />} />
    </Routes>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <ToastProvider>
          <ToastComponent />
          <GlobalPlayerProvider>
            <div className="parentDiv">
              {/* <div class="snowflakes" aria-hidden="true"> */}
              {/* <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake"><img src="https://images.emojiterra.com/google/noto-emoji/unicode-16.0/color/512px/1f1ee-1f1f3.png" height="20px" width="20px" /></div>
              <div class="snowflake sf-green">❄</div>
              <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake"><img src="https://images.emojiterra.com/google/noto-emoji/unicode-16.0/color/512px/1f1ee-1f1f3.png" height="20px" width="20px" /></div>
              <div class="snowflake sf-green">❅</div>
              <div class="snowflake sf-orange">❆</div>
              <div class="snowflake sf-green">❄</div> */}

              {/* <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake">❆</div>
              <div class="snowflake sf-green">❄</div>
              <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake">❄</div>
              <div class="snowflake sf-green">❅</div>
              <div class="snowflake sf-orange">❆</div>
              <div class="snowflake sf-green">❄</div> */}

              {/* Always visible component */}
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  left: 0,
                  zIndex: 1,
                }}
              >
                {/* <Header /> */}
                <HeaderWrapper />
              </div>

              {/* Route-based pages */}
              <ProtectedRoutes />
              {/* <Routes>
              <Route path="/" element={<Home />} />
            <Route path="/tracks/:name/:trackCode" element={<TrackPage />} />
            <Route path="/tracks" element={<TrackListing />} />
            <Route path="/packs" element={<PackListing />} />
            <Route
              path="/playlists/:packNameSlug/:packCode"
              element={<PackPage />}
            />
            <Route
              path="/categories/:name/:categoryCode"
              element={<CategoryPage />}
            />
            <Route path="/moods/:name/:moodsCode" element={<Moodspage />} />
            <Route path="/genres/:name/:genreCode" element={<GenrePage />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/faqs" element={<Faq />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/moods" element={<MoodsListing />} />
            <Route path="/categories" element={<CategoryListing />} />
            <Route path="/genres" element={<GenreListing />} />
            </Routes> */}

              {/* Always visible component */}
              <FooterWrapper />
              <GPWrapper />
            </div>
            {/* </div> */}
          </GlobalPlayerProvider>
        </ToastProvider>
      </Router>
    </AuthProvider>
  );
};

export default App;
