import React, { useState, useEffect, useContext } from "react";
import styles from "../styles/PackPage.module.css";
import { FaRegHeart } from "react-icons/fa";
import CustomButton from "../components/CustomButton/CustomButton";
import { RiShoppingCart2Line } from "react-icons/ri";
import { FiShare2 } from "react-icons/fi";
import { getPack } from "../service/PackService";
import { useParams, Link } from "react-router-dom";
import config from "../utils/config";
import { addToCart } from "../utils/redux/cartSlice";
import { useDispatch } from "react-redux";
import { ToastContext } from "../utils/ToastContext";
import { useHandleShareClick } from "../utils/Share";
import getDirectLink from "../utils/AudioUrlFormated";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import CleanSearchKey from "../utils/CleanSearchKey";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { ImCheckmark, ImCross } from "react-icons/im";
import RequestLicense from "./RequestLicense";
import { getCookie } from "../utils/AuthContext";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "../utils/firebase/remoteConfig";
import { packLike } from "../service/UserService";
import randomDefaultImage from "../utils/RandomDefaultImage";

const PackPage = () => {
  const [packData, setPackData] = useState(null);
  // const { packCode } = useParams(); // getting the data from the routes
  const dispatch = useDispatch(); // Hook to dispatch actions
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const { handleShareClick } = useHandleShareClick(); // Use the custom hook
  const [openCards, setOpenCards] = useState([true, false, false]); // state to manage the cards opening state
  const [licenseData, setIsLicenseData] = useState(null); // state to manage the request quote data for request license modal
  const [isRequestOpen, setIsRequestOpen] = useState(false); // toggle cart modal
  const userPhoneNumber = getCookie("userPhoneNumber"); // Get the user's phone number from cookies to identify the user

  const packCode = 31;

  // Fetch pack data on component mount
  useEffect(() => {
    const fetchPackData = async () => {
      try {
        const data = await getPack(packCode); // Fetch the pack data using the packCode
        // console.log(data);

        setPackData(data); // Store the fetched data in state
      } catch (error) {
        console.error("Error fetching pack data:", error);
      }
    };

    fetchPackData(); // Call the fetch function
  }, [packCode]); // Run the effect whenever packCode changes

  // Function to calculate discount percentage
  const calculateDiscountPercentage = (sellingPrice, costPrice) => {
    if (!sellingPrice || !costPrice) return 0; // Avoid division by zero
    const discount = ((costPrice - sellingPrice) / costPrice) * 100;
    return Math.round(discount); // Round the result to the nearest integer
  };

  if (!packData) return <div>Loading...</div>; // Display loading state if data is not available
  // console.log(packData);

  const { name, description, trackCount, tracks } = packData.data; // setting the data from the packData object

  // function to add the pack in the cart
  const handleCartClick = (pack) => {
    // console.log(pack, "pack info for cart");
    const cartInfo = {
      cardType: "pack", // card type to display
      preText: pack.name, // pack name
      secText: pack.description.substring(0, 30), // pack description
      code: JSON.parse(pack.playlistCode), // pack code
      sp:
        JSON.stringify(pack.SKU[0].sellingPrice) ||
        pack.SKU[0].sellingPrice ||
        "99999",
      cp: JSON.stringify(pack.SKU[0].costPrice) || "129999",
      cardImg: pack.coverImage, // card img based on pack
    };
    // // Dispatch action to add the track to the cart
    dispatch(addToCart(cartInfo));
    showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg

    // console.log(cartInfo, "cartInfo");
  };

  // formatting tracks from the pack info to pass data to tracks cards
  const formattedTracks = packData?.data.tracks.map((track) => {
    // console.log(track, "pack track");
    const { costPrice, sellingPrice } = track?.SKU?.[0] || {};
    const discountPercent =
      costPrice && sellingPrice
        ? (((costPrice - sellingPrice) / costPrice) * 100).toFixed(0)
        : null;

    return {
      image: track.imageUrl,
      title: track.name,
      description: track.primaryArtists
        .map((artist) => artist.artistName)
        .join(", "),
      trackCode: track.trackCode,
      name: track.name_slug,
      waveJson: track.waveformLink,
      trackUrl:
        track.mp3Link ||
        getDirectLink(track.link) ||
        track.link ||
        track.waveformLink,
      discountPercent: discountPercent || "50",
      costPrice: costPrice ? costPrice.toLocaleString("en-IN") : "19,999",
      sellingPrice: sellingPrice
        ? sellingPrice.toLocaleString("en-IN")
        : "79,999",
      buttonText: "Request Quote",
    };
  });

  // about pack info
  const aboutPack = [
    {
      title: "About",
      description: packData.data.description || "",
    },
    {
      title: "Moods",
      pills: packData.data.filterDetails?.mood?.map((mood) => mood.name) || [], // Map mood from filters
    },
    // {
    //   title: "Genres",
    //   pills:
    //     packData.data.filterDetails?.genre?.map((genre) => genre.name) || [], // Map genre from filters
    // },
  ];

  // console.log(aboutPack, "about pack");

  // pros and cons info of the pack
  const shortPros = {
    pros: [
      "Curated multi-track packs at great savings",
      "Quick clearance on submission of usage links",
      "Clearance only for IG Reels and YT Shorts",
      "Publish content within license validity duration",
    ],
    cons: [
      "No clearance for long form content, OTT and broadcast",
      "No clearance for DVC and paid ads",
      "No clearance for influencer campaigns",
    ],
    separate: [
      "Any of the above usage will require you to get in touch with our music team.",
    ],
  };

  // pack benefits info
  const packsBenefits = [
    {
      image: `${config.s3imgUrl}icons/immediatedownload.png`,
      title: "Immediate Downlaod",
    },
    {
      image: `${config.s3imgUrl}icons/copyrightshield.png`,
      title: "100% Copyright Safe",
    },
    {
      image: `${config.s3imgUrl}icons/copyright.png`,
      title: "Zero-claims Guarantee",
    },
    {
      image: `${config.s3imgUrl}icons/globalnetwork.png`,
      title: "Worldwide Clearance",
    },
    {
      image: `${config.s3imgUrl}icons/chat.png`,
      title: "Dedicated Support Team",
    },
  ];

  // function for toggling the cards
  const toggleContainer = (index) => {
    setOpenCards((prevOpenCards) => {
      // Create a new array with the updated open states
      const newOpenCards = prevOpenCards.map((isOpen, i) =>
        i === index ? !isOpen : false
      );
      return newOpenCards;
    });
  };

  // fucntion to open request quote screen
  const handleRequestQuote = () => {
    const licenseData = {
      title: packData?.data.name,
      subTitle:
        packData?.data.primaryArtists &&
        packData?.data.primaryArtists.length > 0
          ? packData?.data.primaryArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : "",
      // img: packData?.data.image,
      img:
        config.s3imgUrl +
        "web/playlists/" +
        packData?.data.playlistCode +
        ".webp",
    };
    setIsLicenseData(licenseData);
    // console.log(licenseData, "license data");
    setIsRequestOpen(true);
  };

  const handleTrackRequestQuote = (track) => {
    // console.log("Request Quote clicked for track:", track);
    const licenseData = {
      title: track.title,
      subTitle: track.description,
      img: track.image,
    };
    setIsLicenseData(licenseData);
    // console.log(licenseData, "license data");
    setIsRequestOpen(true);
  };

  const handlePackLike = async () => {
    const payload = {
      playlistCode: packData.data.playlistCode,
    };
    await fetchAndActivate(remoteConfig); // Fetch and activate Remote Config
    const jsonString = getValue(remoteConfig, "auth").asString(); // Fetch the auth config
    const parsedConfig = JSON.parse(jsonString);
    // console.log(parsedConfig, "parsedConfig");
    // Find the object where the phone number matches
    const matchingEntry = Object.entries(parsedConfig).find(
      ([phoneNumber, userData]) => phoneNumber === userPhoneNumber
    );

    if (matchingEntry) {
      const [phoneNumber, userData] = matchingEntry;
      // console.log(
      //   `Matching entry found for phone number: ${phoneNumber}`,
      //   userData.token
      // );
      const packLikeResponse = await packLike(payload, userData.token);
      // console.log(packLikeResponse, "packLikeResponse");
      if (packLikeResponse.data.status === true) {
        showToast("Like Added", "Added to liked Packs", "success"); //display toast msg
      } else if (packLikeResponse.data.status === false) {
        showToast("Like Removed", "Removed from liked Packs", "success");
      } else {
        showToast("Error", "Something went wrong", "error");
      }
      // showToast("Like Added", "Added to liked Packs", "success"); //display toast msg
      // Do something with userData, such as using the token or userId
    } else {
      console.log(
        `No matching entry found for phone number: ${userPhoneNumber}`
      );
    }
  };

  return (
    <div className={styles.pdContainer}>
      {/* pack description and details section */}
      <div className={`${styles.pdContent} ${styles.outerFullPp}`}>
        <div className={styles.innerPp}>
          <div
            className={styles.pdCardImg}
            style={{
              backgroundImage: `url(https://cdn.staging.select.hoopr.ai/playlist/2c91aa47-20d7-4afe-b3cb-53c220b08eb8/cover.webp)`,
            }}
          >
            <div className={styles.pdLikeInfo}>
              <div className={styles.pdLikeIcon}>
                <FaRegHeart
                  cursor={"pointer"}
                  onClick={handlePackLike}
                  size={24}
                  color="#fff"
                />
              </div>
              <div className={styles.pdCardTrackTotal}>
                <p>{trackCount}</p>
                <span>TRACKS</span>
              </div>
            </div>
          </div>
          <div className={styles.pdInfo}>
            <h1 className={styles.pdInfoTitle}>{name}</h1>
            <h2 className={styles.pdInfoDescription}>
              {description !== null && description.length > 160
                ? description.substring(0, 150) + "..."
                : description}
            </h2>
            {packData.data.markerType === "Packs" ? (
              <div className={styles.pdInfoPrice}>
                <div className={styles.pdInfoPriceAmount}>
                  <div className={styles.pdDiscountAmount}>
                    <p>₹</p>
                    <span>
                      {packData.data.SKU[0].sellingPrice.toLocaleString(
                        "en-IN"
                      )}
                    </span>
                  </div>
                  <div className={styles.pdDiscountPercentage}>
                    {calculateDiscountPercentage(
                      packData?.data.SKU[0]?.sellingPrice,
                      packData?.data.SKU[0]?.costPrice
                    )}
                    % off
                  </div>
                </div>
                <span className={styles.pdInfoPriceCost}>
                  ₹{packData.data.SKU[0].costPrice.toLocaleString("en-IN")}
                </span>
              </div>
            ) : null}

            {packData.data.markerType === "Packs" ? (
              <div className={styles.pdInfoButton}>
                <div>
                  <CustomButton
                    justifyContent="space-between"
                    buyArrow={true}
                    fontSize="14px"
                    text="Buy Now"
                    backgroundColor="var(--button-primary)"
                    textColor="#ffffff"
                    fontWeight="550"
                    width="188px"
                    height="39px"
                    borderRadius="50px"
                  />
                </div>
                <div>
                  <CustomButton
                    backgroundColor="#FFFFFF"
                    width="39px"
                    height="39px"
                    borderRadius="50px"
                    iconColor="#000"
                    isIconOnly="true"
                    onClick={handleCartClick.bind(this, packData.data)}
                    icon={<RiShoppingCart2Line />}
                  />
                </div>
                <div>
                  <CustomButton
                    backgroundColor="#FFFFFF"
                    width="39px"
                    height="39px"
                    borderRadius="50px"
                    iconColor="#000"
                    isIconOnly="true"
                    onClick={handleShareClick}
                    icon={<FiShare2 />}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* track list with associated pack */}
      {tracks.length > 0 ? (
        <div
          className={`${styles.bgBlack}`}
          // className="catg-now-container outer-full bg-black outer-moodsg"
        >
          <div className="inner">
            <div className={styles.ppTrackHeading}>
              <h2>Tracks included in {name}</h2>
            </div>
            <div className={styles.ppTrackCards}>
              <TrackCard
                // showPrice={true}
                trackCard={formattedTracks}
                trackType="foryou"
                btnWidth="184px"
                onRequestClick={handleTrackRequestQuote}
              />
            </div>
          </div>
        </div>
      ) : null}

      {/* request quote screen */}
      <RequestLicense
        isOpen={isRequestOpen}
        onClose={() => setIsRequestOpen(false)}
        licenseData={licenseData}
      />
    </div>
  );
};

export default PackPage;
