// src/ProtectedRoutes.js
import { useSelector } from "react-redux";
import { Navigate, Routes, Route } from "react-router-dom";
import Home from "./Home";
import TrackPage from "./TrackPage";
import TrackListing from "./TrackListing";
import PackListing from "./PackListing";
import Playlist from "./Playlist";
import PackPage from "./PackPage";
import CategoryPage from "./CategoryPage";
import Moodspage from "./MoodsPage";
import GenrePage from "./GenrePage";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Faq from "./Faq";
import SearchPage from "./SearchPage";
import MoodsListing from "./MoodsListing";
import CategoryListing from "./CategoryListing";
import GenreListing from "./GenreListing";
import Checkout from "./Checkout";
import Foryoupage from "./ForyouPage";
import ContactUs from "./ContactUs";
import Pay from "./Pay";
import Purchases from "./MyAccount/Purchases";
import MyProfile from "./MyAccount/MyProfile";
import MyLikes from "./MyAccount/MyLikes";
import MyCollection from "./MyAccount/MyCollection";
import MyAccount from "./MyAccount/MyAccount";
import EmptyState from "../components/EmptyStates/EmptyState";

const ProtectedRoutes = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<EmptyState type="404" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/tracks/:name/:trackCode" element={<TrackPage />} />
      <Route path="/tracks" element={<TrackListing />} />
      <Route path="/packs" element={<PackListing />} />
      <Route path="/playlists" element={<Playlist />} />
      <Route path="/playlists/:packNameSlug/:packCode" element={<PackPage />} />
      <Route
        path="/categories/:name/:categoryCode"
        element={<CategoryPage />}
      />
      <Route path="/moods/:name/:moodsCode" element={<Moodspage />} />
      <Route path="/genres/:name/:genreCode" element={<GenrePage />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/faqs" element={<Faq />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/moods" element={<MoodsListing />} />
      <Route path="/categories" element={<CategoryListing />} />
      <Route path="/genres" element={<GenreListing />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/foryou" element={<Foryoupage />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/pay" element={<Pay />} />
      {/* Nested Routes for My Account */}
      <Route path="/user" element={<MyAccount />}>
        <Route index element={<Navigate to="profile" />} />{" "}
        {/* Default to profile */}
        <Route path="profile" element={<MyProfile />} />
        <Route path="purchases" element={<Purchases />} />
        <Route path="likes" element={<MyLikes />} />
        <Route path="collection" element={<MyCollection />} />
      </Route>
      <Route path="*" element={<EmptyState type="404" />} />
    </Routes>
  );
};

export default ProtectedRoutes;
