import React, { useState, useEffect } from "react";
import "./TrackFilter.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchTrackFilters } from "../../../utils/redux/trackFiltersSlice";
import { getSearchResult } from "../../../service/SearchService";
import CustomDropdown from "../../CustomDropdown/CustomDropdown";
import randomDefaultImage from "../../../utils/RandomDefaultImage";
import getDirectLink from "../../../utils/AudioUrlFormated";
import CustomInput from "../../CustomInput/CustomInput";

const TrackFilter = ({
  onFiltersApplied,
  isFilterApplied,
  onClearFilters,
  width,
  hideApply = false,
  onFiltersSelected, // This is the function to send filtersSelected to parent
  onPriceFilterChange,
  showPriceFilter = false,
}) => {
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const [selectedUseCases, setSelectedUseCases] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedMoods, setSelectedMoods] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState({});

  // const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [filtersSelected, setFiltersSelected] = useState({
    languages: [],
    instruments: [],
    useCases: [],
    genres: [],
    moods: [],
  });

  const dispatch = useDispatch();

  const { genres, useCases, moods, instruments, languages, status } =
    useSelector((state) => state.trackFilters);

  // Fetch filters when component mounts
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTrackFilters());
    }
  }, [status, dispatch]);

  // Initialize state from localStorage when component mounts
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem("trackFilters")) || {};
    const priceFilters = JSON.parse(localStorage.getItem("priceFilters")) || {};
    setFiltersSelected(savedFilters);
    setSelectedPrice(priceFilters);
  }, []);

  // Map Redux filters to dropdown-compatible format
  const mapFilters = (filters) =>
    filters.map((filter) => ({
      value: filter.value,
      label: filter.label,
    }));

  const languageFilters = mapFilters(languages);
  const instrumentFilters = mapFilters(instruments);
  const useCasesFilters = mapFilters(useCases);
  const genreFilters = mapFilters(genres);
  const moodsFilters = mapFilters(moods);

  // Handle applying filters
  const handleFilterApply = async () => {
    try {
      const filters = {
        languages: selectedLanguages.map((item) => item.label),
        instruments: selectedInstruments.map((item) => item.label),
        useCases: selectedUseCases.map((item) => item.label),
        genres: selectedGenres.map((item) => item.label),
        moods: selectedMoods.map((item) => item.label),
      };

      const query = Object.values(filters)
        .filter((values) => values.length > 0) // Remove empty filters
        .map((values) => values.join(","))
        .join(",");

      const response = await getSearchResult(query);
      const filteredResults = response.filter(
        (item) => item["asset-type"] === "music"
      );

      //   console.log(filteredResults, "filteredResults");

      const formattedResults = filteredResults.flatMap((result) => {
        // Use flatMap to flatten the array

        // Make sure the result contains the 'data' array before mapping
        return result.data.map((track) => {
          //   console.log(track, "track");

          // Check if 'tracks_artist_role' exists and parse it correctly
          const artistNames = track.tracks_artist_role
            ? JSON.parse(track.tracks_artist_role)
                .map((role) => role.artist.name)
                .join(", ")
            : "No artists available";

          return {
            imageUrl:
              track.image_url != "" && track.image_url != null
                ? track.image_url
                : randomDefaultImage("track"),
            name: track.name,
            name_slug: track.name_slug,
            artistNames: artistNames,
            trackCode: track.track_code,
            artistNames: artistNames,
            trackUrl:
              track.mp3Link || getDirectLink(track.link) || track.waveformLink,
            SKU: [
              {
                costPrice: 50000,
                sellingPrice: 40000,
                gstPercent: 18,
                maxUsage: 3,
              },
            ],
          };
        });
      });

      //   console.log("Formatted Results:", formattedResults);

      // Pass filtered results back to parent
      onFiltersApplied(formattedResults);
      // setIsFilterApplied(true); // Set filter applied to true
    } catch (error) {
      console.error("Error applying filters:", error);
    }
  };

  const handleClearFilters = () => {
    // Reset all selected filters
    setSelectedLanguages([]);
    setSelectedInstruments([]);
    setSelectedUseCases([]);
    setSelectedGenres([]);
    setSelectedMoods([]);
    setSelectedPrice({});
  };

  // Handle changes in filter selection and update filtersSelected
  const handleFilterChange = (filterType, selectedValues) => {
    setFiltersSelected((prevState) => {
      const updatedFilters = { ...prevState, [filterType]: selectedValues };

      //store in local storage
      localStorage.setItem("trackFilters", JSON.stringify(updatedFilters)); // note the value in local to be clear from parent when unmounting or user action trigger

      // Send updated filters to the parent
      onFiltersSelected(updatedFilters);

      return updatedFilters;
    });
  };

  const priceFilter = [
    { value: "0-4999", label: "Under ₹4,999", min: 0, max: 4999 },
    {
      value: "5000-24999",
      label: "Between ₹5,000 - ₹24,999",
      min: 5000,
      max: 24999,
    },
    {
      value: "25000-44999",
      label: "Between ₹25,000 - ₹44,999",
      min: 25000,
      max: 44999,
    },
    {
      value: "45000-59999",
      label: "Between ₹45,000 - ₹59,999",
      min: 45000,
      max: 59999,
    },
    { value: "60000", label: "Above ₹60,000", min: 60000, max: "" },
    { value: "custom", label: "Custom", min: null, max: null },
  ];

  const handlePriceChange = (selected) => {
    const { value, min, max } = selected || {};

    // Set selectedPrice based on the selected option
    if (value === "custom") {
      setSelectedPrice({ value, min: "", max: "" });
    } else {
      setSelectedPrice({ value, min, max });
      // Notify parent immediately for predefined ranges
      onPriceFilterChange({ min, max });
    }

    // Store the selected price filter in localStorage under 'priceFilters'
    const priceFilterData = { value, min, max };
    localStorage.setItem("priceFilters", JSON.stringify(priceFilterData));
  };

  const handleCustomPriceInput = (field, value) => {
    setSelectedPrice((prev) => {
      const updatedPrice = {
        ...prev,
        [field]: value ? parseInt(value, 10) : null,
      };

      // Debounce logic
      clearTimeout(updatedPrice.debounceTimeout);
      const debounceTimeout = setTimeout(() => {
        // Notify parent only when max value is provided
        if (updatedPrice.max !== null && updatedPrice.min !== null) {
          onPriceFilterChange({ min: updatedPrice.min, max: updatedPrice.max });
        }

        // Store the custom price filter in localStorage under 'priceFilters'
        // const priceFilterData = updatedPrice;
        localStorage.setItem("priceFilters", JSON.stringify(updatedPrice));
      }, 1000);

      return { ...updatedPrice, debounceTimeout };
    });
  };

  return (
    <div style={{ width: width ? width : "218px" }} className="filter-contents">
      {hideApply === false ? (
        <div className="filter-options">
          <div className="filter-track-title">
            <p>Filters</p>
            {isFilterApplied ? (
              <span
                onClick={() => {
                  handleClearFilters(); // Reset local state
                  onClearFilters(); // Notify parent to clear filters
                }}
              >
                Clear
              </span>
            ) : (
              <span onClick={handleFilterApply}>Apply</span>
            )}
          </div>
        </div>
      ) : null}
      {showPriceFilter && (
        <div className="filter-artist">
          <p>Price</p>
          <CustomDropdown
            options={priceFilter}
            placeholder="Price Range"
            value={priceFilter.find(
              (option) =>
                option.min === selectedPrice.min &&
                option.max === selectedPrice.max
            )}
            onChange={handlePriceChange}
          />
          {selectedPrice.value === "custom" && (
            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
              <CustomInput
                fontSize="16px"
                marginTop="0px"
                paddingLeft="22px"
                labelOpcaity="30%"
                fontSizeLabel="14px"
                border="1px solid #98999C"
                labelTop="15px"
                fontLabelColor="#98999C"
                height="40px"
                placeholder="Min"
                borderRadius="4px"
                value={selectedPrice.min || ""}
                onChange={(e) => handleCustomPriceInput("min", e.target.value)}
              />
              <CustomInput
                fontSize="16px"
                marginTop="0px"
                paddingLeft="22px"
                labelOpcaity="30%"
                fontSizeLabel="14px"
                border="1px solid #98999C"
                labelTop="15px"
                fontLabelColor="#98999C"
                height="40px"
                placeholder="Max"
                borderRadius="4px"
                value={selectedPrice.max || ""}
                onChange={(e) => handleCustomPriceInput("max", e.target.value)}
              />
            </div>
          )}
        </div>
      )}
      <div className="filter-artist">
        <p>Languages</p>
        <CustomDropdown
          options={languageFilters}
          placeholder="Languages"
          isMulti
          // onChange={setSelectedLanguages}
          // value={selectedLanguages}
          onChange={
            hideApply
              ? (selected) => handleFilterChange("languages", selected)
              : setSelectedLanguages
          }
          value={hideApply ? filtersSelected.languages : selectedLanguages}
        />
      </div>
      <div className="filter-artist">
        <p>Instruments</p>
        <CustomDropdown
          options={instrumentFilters}
          placeholder="Instruments"
          isMulti
          // onChange={setSelectedInstruments}
          // value={selectedInstruments}
          onChange={
            hideApply
              ? (selected) => handleFilterChange("instruments", selected)
              : setSelectedInstruments
          }
          value={hideApply ? filtersSelected.instruments : selectedInstruments}
        />
      </div>
      <div className="filter-artist">
        <p>Use Cases</p>
        <CustomDropdown
          options={useCasesFilters}
          placeholder="Use Cases"
          isMulti
          // onChange={setSelectedUseCases}
          // value={selectedUseCases}
          onChange={
            hideApply
              ? (selected) => handleFilterChange("useCases", selected)
              : setSelectedUseCases
          }
          value={hideApply ? filtersSelected.useCases : selectedUseCases}
        />
      </div>
      <div className="filter-artist">
        <p>Genres</p>
        <CustomDropdown
          options={genreFilters}
          placeholder="Genres"
          isMulti
          // onChange={setSelectedGenres}
          // value={selectedGenres}
          onChange={
            hideApply
              ? (selected) => handleFilterChange("genres", selected)
              : setSelectedGenres
          }
          value={hideApply ? filtersSelected.genres : selectedGenres}
        />
      </div>
      <div className="filter-artist">
        <p>Moods</p>
        <CustomDropdown
          options={moodsFilters}
          placeholder="Moods"
          isMulti
          // onChange={setSelectedMoods}
          // value={selectedMoods}
          onChange={
            hideApply
              ? (selected) => handleFilterChange("moods", selected)
              : setSelectedMoods
          }
          value={hideApply ? filtersSelected.moods : selectedMoods}
        />
      </div>
    </div>
  );
};

export default TrackFilter;
