import React, { useState, useEffect, useContext } from "react";
import styles from "../styles/PackPage.module.css";
import { FaRegHeart } from "react-icons/fa";
import CustomButton from "../components/CustomButton/CustomButton";
import { RiShoppingCart2Line } from "react-icons/ri";
import { FiShare2 } from "react-icons/fi";
import { getPack } from "../service/PackService";
import { useParams, Link } from "react-router-dom";
import config from "../utils/config";
import { addToCart } from "../utils/redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContext } from "../utils/ToastContext";
import { useHandleShareClick } from "../utils/Share";
import getDirectLink from "../utils/AudioUrlFormated";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import CleanSearchKey from "../utils/CleanSearchKey";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { ImCheckmark, ImCross } from "react-icons/im";
import RequestLicense from "./RequestLicense";
import { getCookie } from "../utils/AuthContext";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "../utils/firebase/remoteConfig";
import { packLike } from "../service/UserService";
import setMetaTags from "../utils/SeoService";
import { putCart } from "../service/TransactionService";

const PackPage = () => {
  const [packData, setPackData] = useState(null);
  const { packCode } = useParams(); // getting the data from the routes
  const dispatch = useDispatch(); // Hook to dispatch actions
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const { handleShareClick } = useHandleShareClick(); // Use the custom hook
  const [openCards, setOpenCards] = useState([true, false, false]); // state to manage the cards opening state
  const [licenseData, setIsLicenseData] = useState(null); // state to manage the request quote data for request license modal
  const [isRequestOpen, setIsRequestOpen] = useState(false); // toggle cart modal
  const userPhoneNumber = getCookie("userPhoneNumber"); // Get the user's phone number from cookies to identify the user
  const items = useSelector((state) => state.cart.items); // Redux selector

  // Utility to fetch item quantity from Redux state
  const getItemQuantity = (trackCode) =>
    items.find((item) => item.code === trackCode)?.quantity || 0;

  // Fetch pack data on component mount
  useEffect(() => {
    const fetchPackData = async () => {
      try {
        const data = await getPack(packCode); // Fetch the pack data using the packCode
        // console.log(data);

        setPackData(data); // Store the fetched data in state
        const seoData = {
          title: `${data.data.name} | Music License | Hoopr Smash`,
          description: `License ${data.data.name} songs on Hoopr Smash. Access royalty-free ${data.data.name} music perfect for enhancing your projects, with easy licensing for any creative need.`,
          ogImage: `${config.s3imgUrl}web/playlists/${data.data.playlistCode}.webp`,
          canonical: window.location.href,
          // lang: "en",
        };
        setMetaTags(seoData);
      } catch (error) {
        console.error("Error fetching pack data:", error);
      }
    };

    fetchPackData(); // Call the fetch function
  }, [packCode]); // Run the effect whenever packCode changes

  // Function to calculate discount percentage
  const calculateDiscountPercentage = (sellingPrice, costPrice) => {
    if (!sellingPrice || !costPrice) return 0; // Avoid division by zero
    const discount = ((costPrice - sellingPrice) / costPrice) * 100;
    return Math.round(discount); // Round the result to the nearest integer
  };

  if (!packData) return <div>Loading...</div>; // Display loading state if data is not available
  // console.log(packData);

  const { name, description, trackCount, tracks } = packData.data; // setting the data from the packData object

  // function to add the pack in the cart
  const handleCartClick = async (pack) => {
    // console.log(pack, "pack info for cart");
    const cartInfo = {
      cardType: "pack", // card type to display
      preText: pack.name, // pack name
      secText: pack.description.substring(0, 30), // pack description
      code: JSON.parse(pack.playlistCode), // pack code
      sp:
        JSON.stringify(pack.SKU[0].sellingPrice) ||
        pack.SKU[0].sellingPrice ||
        "99999",
      cp: JSON.stringify(pack.SKU[0].costPrice) || "129999",
      cardImg: pack.coverImage, // card img based on pack
      skuID: pack.skuId || "",
    };

    // Get the current quantity for the track
    const currentQuantity = getItemQuantity(cartInfo.code);

    const updatedQuantity = currentQuantity + 1; // Increment quantity
    // console.log("Updated Quantity:", updatedQuantity);

    // preparign the payload for api call
    const payload = {
      items: [
        { skuId: cartInfo.skuID || "", qty: updatedQuantity, cartType: "C" },
      ],
    };

    const addCart = await putCart(payload);
    // console.log(addCart, "cart add");
    if (addCart?.error?.code === 0) {
      // Dispatch action to add the trak in the redux
      dispatch(addToCart(cartInfo));
      showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg
    } else {
      showToast("Added to cart", "Failed to update the cart", "error"); //display toast msg
    }
  };

  // formatting tracks from the pack info to pass data to tracks cards
  const formattedTracks = packData?.data.tracks.map((track) => ({
    image: track.imageUrl,
    title: track.name,
    description: track.primaryArtists
      .map((artist) => artist.artistName)
      .join(", "),
    trackCode: track.trackCode,
    name: track.name_slug,
    waveJson: track.waveformLink,
    trackUrl:
      track.mp3Link ||
      getDirectLink(track.link) ||
      track.link ||
      track.waveformLink,
  }));

  // about pack info
  const aboutPack = [
    {
      title: "About",
      description: packData.data.description || "",
    },
    {
      title: "Moods",
      pills: packData.data.filterDetails?.mood?.map((mood) => mood.name) || [], // Map mood from filters
    },
    // {
    //   title: "Genres",
    //   pills:
    //     packData.data.filterDetails?.genre?.map((genre) => genre.name) || [], // Map genre from filters
    // },
  ];

  // console.log(aboutPack, "about pack");

  // pros and cons info of the pack
  const shortPros = {
    pros: [
      "Curated multi-track packs at great savings",
      "Quick clearance on submission of usage links",
      "Clearance only for IG Reels and YT Shorts",
      "Publish content within license validity duration",
    ],
    cons: [
      "No clearance for long form content, OTT and broadcast",
      "No clearance for DVC and paid ads",
      "No clearance for influencer campaigns",
    ],
    separate: [
      "Any of the above usage will require you to get in touch with our music team.",
    ],
  };

  // pack benefits info
  const packsBenefits = [
    {
      image: `${config.s3imgUrl}icons/immediatedownload.png`,
      title: "Immediate Download",
    },
    {
      image: `${config.s3imgUrl}icons/copyrightshield.png`,
      title: "100% Copyright Safe",
    },
    {
      image: `${config.s3imgUrl}icons/copyright.png`,
      title: "Zero-claims Guarantee",
    },
    {
      image: `${config.s3imgUrl}icons/globalnetwork.png`,
      title: "Worldwide Clearance",
    },
    {
      image: `${config.s3imgUrl}icons/chat.png`,
      title: "Dedicated Support Team",
    },
  ];

  // function for toggling the cards
  const toggleContainer = (index) => {
    setOpenCards((prevOpenCards) => {
      // Create a new array with the updated open states
      const newOpenCards = prevOpenCards.map((isOpen, i) =>
        i === index ? !isOpen : false
      );
      return newOpenCards;
    });
  };

  // fucntion to open request quote screen
  const handleRequestQuote = () => {
    const licenseData = {
      title: packData?.data.name,
      subTitle:
        packData?.data.primaryArtists &&
        packData?.data.primaryArtists.length > 0
          ? packData?.data.primaryArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : "",
      // img: packData?.data.image,
      img:
        config.s3imgUrl +
        "web/playlists/" +
        packData?.data.playlistCode +
        ".webp",
    };
    setIsLicenseData(licenseData);
    // console.log(licenseData, "license data");
    setIsRequestOpen(true);
  };

  const handlePackLike = async () => {
    const payload = {
      playlistCode: packData.data.playlistCode,
    };
    await fetchAndActivate(remoteConfig); // Fetch and activate Remote Config
    const jsonString = getValue(remoteConfig, "auth").asString(); // Fetch the auth config
    const parsedConfig = JSON.parse(jsonString);
    // console.log(parsedConfig, "parsedConfig");
    // Find the object where the phone number matches
    const matchingEntry = Object.entries(parsedConfig).find(
      ([phoneNumber, userData]) => phoneNumber === userPhoneNumber
    );

    if (matchingEntry) {
      const [phoneNumber, userData] = matchingEntry;
      // console.log(
      //   `Matching entry found for phone number: ${phoneNumber}`,
      //   userData.token
      // );
      const packLikeResponse = await packLike(payload, userData.token);
      // console.log(packLikeResponse, "packLikeResponse");
      if (packLikeResponse.data.status === true) {
        showToast("Like Added", "Added to liked Packs", "success"); //display toast msg
      } else if (packLikeResponse.data.status === false) {
        showToast("Like Removed", "Removed from liked Packs", "success");
      } else {
        showToast("Error", "Something went wrong", "error");
      }
      // showToast("Like Added", "Added to liked Packs", "success"); //display toast msg
      // Do something with userData, such as using the token or userId
    } else {
      console.log(
        `No matching entry found for phone number: ${userPhoneNumber}`
      );
    }
  };

  return (
    <div className={styles.pdContainer}>
      {/* pack description and details section */}
      <div className={`${styles.pdContent} ${styles.outerFullPp}`}>
        <div className={styles.innerPp}>
          <div
            className={styles.pdCardImg}
            style={{
              backgroundImage: `url(${config.s3imgUrl}web/playlists/${packData.data.playlistCode}.webp)`,
            }}
          >
            <div className={styles.pdLikeInfo}>
              <div className={styles.pdLikeIcon}>
                <FaRegHeart
                  cursor={"pointer"}
                  onClick={handlePackLike}
                  size={24}
                  color="#fff"
                />
              </div>
              <div className={styles.pdCardTrackTotal}>
                <p>{trackCount}</p>
                <span>TRACKS</span>
              </div>
            </div>
          </div>
          <div className={styles.pdInfo}>
            <h1 className={styles.pdInfoTitle}>{name}</h1>
            <h2 className={styles.pdInfoDescription}>
              {description !== null && description.length > 160
                ? description.substring(0, 150) + "..."
                : description}
            </h2>
            {packData.data.markerType === "Packs" ? (
              <div className={styles.pdInfoPrice}>
                <div className={styles.pdInfoPriceAmount}>
                  <div className={styles.pdDiscountAmount}>
                    <p>₹</p>
                    <span>
                      {packData.data.SKU[0].sellingPrice.toLocaleString(
                        "en-IN"
                      )}
                    </span>
                  </div>
                  <div className={styles.pdDiscountPercentage}>
                    {calculateDiscountPercentage(
                      packData?.data.SKU[0]?.sellingPrice,
                      packData?.data.SKU[0]?.costPrice
                    )}
                    % off
                  </div>
                </div>
                <span className={styles.pdInfoPriceCost}>
                  ₹{packData.data.SKU[0].costPrice.toLocaleString("en-IN")}
                </span>
              </div>
            ) : null}

            {packData.data.markerType === "Packs" ? (
              <div className={styles.pdInfoButton}>
                <div>
                  <CustomButton
                    justifyContent="space-between"
                    buyArrow={true}
                    fontSize="14px"
                    text="Buy Now"
                    backgroundColor="var(--button-primary)"
                    textColor="#ffffff"
                    fontWeight="550"
                    width="188px"
                    height="39px"
                    borderRadius="50px"
                  />
                </div>
                <div>
                  <CustomButton
                    backgroundColor="#FFFFFF"
                    width="39px"
                    height="39px"
                    borderRadius="50px"
                    iconColor="#000"
                    isIconOnly="true"
                    onClick={handleCartClick.bind(this, packData.data)}
                    icon={<RiShoppingCart2Line />}
                  />
                </div>
                <div>
                  <CustomButton
                    backgroundColor="#FFFFFF"
                    width="39px"
                    height="39px"
                    borderRadius="50px"
                    iconColor="#000"
                    isIconOnly="true"
                    onClick={handleShareClick}
                    icon={<FiShare2 />}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* track list with associated pack */}
      {tracks.length > 0 ? (
        <div
          className={`${styles.bgBlack}`}
          // className="catg-now-container outer-full bg-black outer-moodsg"
        >
          <div className="inner">
            <div className={styles.ppTrackHeading}>
              <h2>Tracks included in {name}</h2>
            </div>
            <div className={styles.ppTrackCards}>
              <TrackCard
                showPrice={true}
                trackCard={formattedTracks}
                trackType="packs"
              />
            </div>
          </div>
        </div>
      ) : null}

      <div className={`${styles.aboutPack} ${styles.innerPp}`}>
        {/* track about section */}
        <div className={`${styles.about} ${styles.fl}`}>
          {/* about song container */}
          <div className={styles.aboutSongContainer}>
            <h1>About The Pack</h1>
            {aboutPack
              .filter(
                (item) =>
                  item.description?.trim() || // Non-empty description
                  (item.pills && item.pills.length > 0 && item.pills[0].trim()) // Non-empty pills
              )
              .map((item, index) => (
                <div key={index} className={styles.aboutSongItem}>
                  <h2>{item.title}</h2>
                  {item.description ? (
                    <h3>{item.description}</h3>
                  ) : (
                    <div className={styles.aboutSongPillsContainer}>
                      {item.pills.map((pill, pillIndex) => {
                        // Check the title and define routing logic
                        // if (item.title === "Genres") {
                        //   // Navigate to genres with name and genreCode
                        //   const pillData =
                        //     packData.data.filterDetails?.genre?.[pillIndex];
                        //   return (
                        //     <Link
                        //       to={`/genres/${encodeURIComponent(
                        //         CleanSearchKey(pill)
                        //       )}/${pillData?.id || ""}`}
                        //     >
                        //       <div key={pillIndex} className="about-song-pills">
                        //         {pill}
                        //       </div>
                        //     </Link>
                        //   );
                        // }
                        // else
                        if (item.title === "Moods") {
                          // Navigate to moods with name and moodCode
                          const pillData =
                            packData.data.filterDetails?.mood?.[pillIndex];
                          return (
                            <Link
                              to={`/moods/${encodeURIComponent(
                                CleanSearchKey(pill)
                              )}/${pillData?.id || ""}`}
                            >
                              <div
                                key={pillIndex}
                                className={styles.aboutSongPills}
                              >
                                {pill}
                              </div>
                            </Link>
                          );
                        }
                        return null;
                      })}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        {/* track pricing section */}
        <div className={`${styles.pricing} ${styles.fl}`}>
          {/* short form videos cards */}
          <div className={styles.pricingContainer}>
            <div
              className={`${styles.pricingHeaderContainer} ${
                openCards[0] ? "open" : "closed"
              }`}
              onClick={() => toggleContainer(0)}
            >
              <div className={styles.pricingHeader}>
                <div>
                  <h1>Short Form Videos</h1>
                  <h2>For use on Instagram Reels & YouTube Shorts</h2>
                </div>
                <div className={styles.pricingHeaderPrice}>
                  <span>₹</span>
                  <p>
                    {packData?.data.SKU?.[0]?.sellingPrice.toLocaleString(
                      "en-IN"
                    )}
                  </p>
                  {/* <h2>(Inclusive of all taxes)</h2> */}
                </div>
              </div>
              <div>
                <div className={styles.arrowContainer}>
                  <IoIosArrowDown size={16} color="#01508F" />
                </div>
              </div>
            </div>
            {openCards[0] && (
              <div
                className={`${styles.pricingDetails} ${styles.shortsSpacing}`}
              >
                <div className={styles.shortProsConsContainer}>
                  <div className={styles.shortPros}>
                    {shortPros.pros.map((item, index) => (
                      <div key={index} className={styles.shortProsItem}>
                        <span>
                          <ImCheckmark size={14} color="#169516" />
                        </span>
                        <p>{item}</p>
                      </div>
                    ))}
                  </div>
                  <div className={styles.shortCons}>
                    {shortPros.cons.map((item, index) => (
                      <div key={index} className={styles.shortConsItem}>
                        <span>
                          <ImCross size={10} color="#D45555" />
                        </span>
                        <p>{item}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <a
                  style={{
                    color: "#000",
                    opacity: "0.7",
                    fontFamily: "Raleway",
                    fontSize: "14px",
                  }}
                  href="#"
                >
                  Click here for License Terms
                </a>
                <div className={styles.shortsProsButton}>
                  <Link to={`/checkout`}>
                    <CustomButton
                      justifyContent="space-between"
                      buyArrow={true}
                      fontSize="14px"
                      text="Buy Now"
                      backgroundColor="var(--button-primary)"
                      textColor="#ffffff"
                      fontWeight="550"
                      width="337px"
                      height="39px"
                      borderRadius="50px"
                      onClick={handleCartClick.bind(this, packData.data)}
                      // onClick={handleCartClick}
                    />
                  </Link>
                  <CustomButton
                    justifyContent="space-between"
                    text="Add to Cart"
                    backgroundColor="#E6E8EA"
                    textColor="#0D0D0D"
                    fontWeight="600"
                    width="337px"
                    height="39px"
                    borderRadius="50px"
                    fontSize="14px"
                    iconColor="#0D0D0D"
                    icon={<RiShoppingCart2Line />}
                    onClick={handleCartClick.bind(this, packData.data)}
                    // onClick={handleCartClick}
                  />
                </div>
              </div>
              // </div>
            )}
          </div>
          {/* long form videos cards */}
          <div className={styles.pricingContainer}>
            <div
              className={`${styles.pricingHeaderContainer} ${
                openCards[2] ? "open" : "closed"
              }`}
              onClick={() => toggleContainer(2)}
            >
              <div className={styles.pricingHeader}>
                <div>
                  <h1>Long Form Videos</h1>
                  <h2>
                    For use on DVCs, TVCs, Webshows etc. on YouTube, OTT, and
                    Broadcast
                  </h2>
                </div>
                {/* <div className="pricing-header-price">
                    <span>₹</span>
                    <p>19,999</p>
                  </div> */}
              </div>
              <div>
                <div className={styles.arrowContainer}>
                  <IoIosArrowDown size={16} color="#01508F" />
                </div>
              </div>
            </div>
            {openCards[2] && (
              <div className={`${styles.pricingDetails} `}>
                <h1 className={styles.pricingLongFormDescription}>
                  Ready to elevate your brand with the power of music? Share
                  your usage details with us and our team will get back to you
                  with a customized quote. Thank you.
                </h1>
                <div>
                  <CustomButton
                    text="Request a Quote"
                    backgroundColor="var(--button-primary)"
                    borderRadius="50px"
                    borderColor="none"
                    width="685px"
                    height="44px"
                    textColor="#fff"
                    iconColor="#fff"
                    fontSize="15px"
                    fontFamily="Inter"
                    fontWeight="bold"
                    icon={<IoIosArrowForward />}
                    justifyContent="space-between"
                    onClick={handleRequestQuote}
                  />
                </div>
              </div>
            )}
          </div>
          {/* benefits section */}
          <div className={styles.pricingTracksBenifits}>
            {packsBenefits.map((benefit, index) => (
              <div key={index} className={styles.pricingbenefitContainer}>
                <div className={styles.benefitIconContainer}>
                  <img src={benefit.image} alt={benefit.title} />
                </div>
                <h1>{benefit.title}</h1>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* request quote screen */}
      <RequestLicense
        isOpen={isRequestOpen}
        onClose={() => setIsRequestOpen(false)}
        licenseData={licenseData}
      />
    </div>
  );
};

export default PackPage;
