import React, { useState, useEffect } from "react";
import styles from "../../styles/MyAccountCss/Likes.module.css";
import randomDefaultImage from "../../utils/RandomDefaultImage";
import { FaPlay, FaHeart } from "react-icons/fa";
import config from "../../utils/config";
import PackCard from "../../components/CardsComponent/PackCard/PackCard";
import PlaylistCard from "../../components/CardsComponent/PlaylistCard/PlaylistCard";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "../../utils/firebase/remoteConfig";
import { getCookie } from "../../utils/AuthContext";
import { getLikes } from "../../service/UserService";
import getDirectLink from "../../utils/AudioUrlFormated";
import MiniTrackCard from "../../components/CardsComponent/TrackCard/MiniTrackCard";

const MyLikes = () => {
  const [selectedTab, setSelectedTab] = useState("Tracks"); // state to manage the pill/button selected
  const userPhoneNumber = getCookie("userPhoneNumber"); // Get the user's phone number from cookies to identify the user
  const [packCards, setPackCards] = useState([]); // packs cards data
  const [playlistCards, setPlaylistCards] = useState([]); // playlist cards data
  const [tracks, setTracks] = useState([]); // track cards data

  useEffect(() => {
    const fetchLikes = async () => {
      await fetchAndActivate(remoteConfig); // Fetch and activate Remote Config
      const jsonString = getValue(remoteConfig, "auth").asString(); // Fetch the auth config
      const parsedConfig = JSON.parse(jsonString);
      const matchingEntry = Object.entries(parsedConfig).find(
        ([phoneNumber, userData]) => phoneNumber === userPhoneNumber
      );

      if (matchingEntry) {
        const [phoneNumber, userData] = matchingEntry;
        // console.log(
        //   `Matching entry found for phone number: ${phoneNumber}`,
        //   userData.token
        // );
        const likeResponse = await getLikes(userData.token);

        // console.log(likeResponse, "likeResponse");

        // Separate tracks
        // const { likedTracks } = likeResponse;
        const { likedTracks = [] } = likeResponse;
        const mappedTracks = likedTracks.map((track) => ({
          trackImg: track.imageUrl,
          trackName: track.name,
          trackCode: track.trackCode,
          trackArtist: "Track Artist",
          trackPrice: track.SKU[0].sellingPrice,
          trackLikeStatus: "Active",
          waveJson: track.waveformLink,
          trackUrl:
            track.mp3Link || getDirectLink(track.link) || track.waveformLink,
        }));

        setTracks(mappedTracks);

        // Separate packs and playlists
        // const { likedPlaylists } = likeResponse;
        const { likedPlaylists = [] } = likeResponse;

        const mappedPacks = likedPlaylists
          .filter((item) => item.playlistCode === "pack") // Adjust this condition based on your API response structure
          .map((pack) => ({
            image: pack.coverImage,
            title: pack.name,
            buttonText: "Explore",
            buttonLink: `/playlists/${pack.name_slug}/${pack.playlistCode}`,
            totalTracks: pack.totalItems,
            packCode: pack.playlistCode,
            packNameSlug: pack.name_slug,
            sellingPrice: "99999",
            costPrice: "129999",
            discountPercent: "23% Off",
          }));
        // console.log(mappedPacks, "mappedPacks");

        const mappedPlaylists = likedPlaylists
          .filter((item) => item.playlistCode !== "pack")
          .map((playlist) => ({
            image: playlist.coverImage || randomDefaultImage("pack"),
            title: playlist.name,
            buttonText: "Explore",
            totalTracks: playlist.totalItems,
            packCode: playlist.playlistCode,
            packNameSlug: playlist.name_slug,
            costPrice: 479999,
            sellingPrice: 299999,
          }));

        // console.log(mappedPlaylists, "mappedPlaylists");
        setPackCards(mappedPacks);
        setPlaylistCards(mappedPlaylists);
      } else {
        console.log(
          `No matching entry found for phone number: ${userPhoneNumber}`
        );
      }
    };
    fetchLikes();
  }, []);

  // function to handle tab swtich
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const renderTrackCard = (track, index) => {
    // console.log(track, "109");

    const trackInfo = {
      preText: track.trackName,
      secText: track.trackArtist,
      code: track.trackCode,
      cardImg: track.trackImg,
      trackJson: track.waveJson,
      trackUrl: track.trackUrl,
    };
    return (
      <div key={index} className={styles.tcardContainer}>
        <MiniTrackCard trackInfo={trackInfo} />
        <div className={styles.trackDetails}>
          <div className={styles.trackInfo}>
            <div>
              <p className={styles.trackHeading}>{track.trackName}</p>
              <span className={`${styles.trackSubHeading} ${styles.trackName}`}>
                {track.trackArtist}
              </span>
            </div>
            <div className={styles.trackHeading}>₹{track.trackPrice}</div>
          </div>
          <div className={styles.likeIcon}>
            <FaHeart color="#E90B2F" size={22} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.likesContainer}>
      <div className={styles.likesHeaderSection}>
        <h1 className={styles.containerHeading}>My Likes</h1>
        <div className={styles.containerPills}>
          {/* {["Tracks", "Playlists", "Packs"].map((tab) => ( */}
          {["Tracks", "Playlists"].map((tab) => (
            <div
              key={tab}
              className={`${styles.pills} ${
                selectedTab === tab ? styles.selectedPill : ""
              }`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      {/* Content Section Based on Selected Tab */}
      {/* <div className={styles.likesDataSection}>
        {selectedTab === "Tracks" &&
          tracks.map((track, index) => renderTrackCard(track, index))}{" "}
        {selectedTab === "Playlists" && (
          <div>
            <PlaylistCard packCards={playlistCards} packType="likesplaylist" />
          </div>
        )}
        {selectedTab === "Packs" && (
          <div>
            <PackCard
              showLikeIcon={true}
              packCards={packCards}
              packType="likespacks"
            />
          </div>
        )}
      </div> */}
      <div className={styles.likesDataSection}>
        {selectedTab === "Tracks" &&
          (tracks.length > 0 ? (
            tracks.map((track, index) => renderTrackCard(track, index))
          ) : (
            <p className={styles.noDataMessage}>No tracks found.</p>
          ))}
        {selectedTab === "Playlists" &&
          (playlistCards.length > 0 ? (
            <div>
              <PlaylistCard
                packCards={playlistCards}
                packType="likesplaylist"
              />
            </div>
          ) : (
            <p className={styles.noDataMessage}>No playlists found.</p>
          ))}
        {selectedTab === "Packs" &&
          (packCards.length > 0 ? (
            <div>
              <PackCard
                showLikeIcon={true}
                packCards={packCards}
                packType="likespacks"
              />
            </div>
          ) : (
            <p className={styles.noDataMessage}>No packs found.</p>
          ))}
      </div>
    </div>
  );
};

export default MyLikes;
