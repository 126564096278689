import React, { useEffect } from "react";
import "../styles/PackListing.css";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import config from "../utils/config";
import PackCard from "../components/CardsComponent/PackCard/PackCard";
import AllPackCard from "../components/CardsComponent/PackCard/AllPackCard";
import RandomDefaultImage from "../utils/RandomDefaultImage";
import { Link } from "react-router-dom";
import CustomImg from "../components/CustomImg/CustomImg";
import setMetaTags from "../utils/SeoService";

const PackListing = () => {
  useEffect(() => {
    const seoData = {
      title: "Pack Based Music Licensing | Hoopr Smash",
      description:
        "Browse Hoopr Smash's collection of premium music packs, carefully curated and categorized by mood, genre, and budget, making it easy to find the right sound for your creative work.",
      ogImage: `${config.s3imgUrl}web/promotional/packs/banner1.webp`,
      canonical: window.location.href,
      // lang: "en",
    };
    setMetaTags(seoData);
  }, []);

  const offerCards = [
    {
      image: `${config.s3imgUrl}web/promotional/packs/banner1.webp`,
      title: "Trendsetters",
      description:
        "Fresh tracks, trending vibes! Explore the latest hits from Bollywood's finest. License premium tracks for your next project.",
      buttonText: "Explore Pack",
      packCode: 12,
      packNameSlug: "trendsetters",
      titleImg: `${config.s3imgUrl}web/promotional/packs/trends.png`,
    },
    {
      image: `${config.s3imgUrl}web/promotional/packs/banner2.webp`,
      title: "Where’s the Party?",
      description:
        "Make your year-end celebrations unforgettable. License the perfect track for every occasion.",
      buttonText: "Explore Pack",
      packCode: 7,
      packNameSlug: "wheres-the-party",
      titleImg: `${config.s3imgUrl}web/promotional/packs/party.png`,
    },
  ];

  const packCards = [
    {
      image: `${config.s3imgUrl}web/playlists/15.webp`,
      title: "Big Bang Chartbusters",
      buttonText: "Explore",
      totalTracks: 5,
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
      packCode: 15,
      packNameSlug: "big-bang-chartbusters",
    },
    {
      image: `${config.s3imgUrl}web/playlists/16.webp`,
      title: "Desi Devotion",
      buttonText: "Explore",
      totalTracks: 5,
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
      packCode: 16,
      packNameSlug: "desi-devotion",
    },
    {
      image: `${config.s3imgUrl}web/playlists/13.webp`,
      title: "Happily Ever After",
      buttonText: "Explore",
      totalTracks: 5,
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
      packCode: 13,
      packNameSlug: "happily-ever-after",
    },
    {
      image: `${config.s3imgUrl}web/playlists/10.webp`,
      title: "I Love My India",
      buttonText: "Explore",
      totalTracks: 4,
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
      packCode: 10,
      packNameSlug: "i-love-my-india",
    },
    {
      image: `${config.s3imgUrl}web/playlists/17.webp`,
      title: "Pulse Racers",
      buttonText: "Explore",
      totalTracks: 5,
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
      packCode: 17,
      packNameSlug: "pulse-racers",
    },
    {
      image: `${config.s3imgUrl}web/playlists/11.webp`,
      title: "Love Is In The Air",
      buttonText: "Explore",
      totalTracks: 5,
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
      packCode: 11,
      packNameSlug: "love-is-in-the-air",
    },
  ];

  return (
    <div className="pack-listing-container">
      {/* packs page description */}
      <div className="pack-listing-description outer">
        <h1>Shop by Packs</h1>
        <h2>
          Discover a collection of the perfect tracks for your project. Browse
          our extensive library of music packs, categorized by occasion and use
          cases. Whether you're creating a commercial, a social media post, or a
          full-length film, seamlessly license the music you need for your
          content.
        </h2>
      </div>
      {/* top packs card section */}
      <div className="toppack-container outer">
        {offerCards.map((offer, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${offer.image})`,
            }}
            className="toppack-card-img"
          >
            <div className="toppack-card-title">
              <h1>
                <CustomImg
                  type="playlist"
                  id="party_title"
                  alt="Where's the Party?"
                  imgClass=""
                  src={offer.titleImg}
                />
              </h1>
            </div>
            {/* {/* <div className="toppack-card-description">
              <h2>{offer.description}</h2>
            </div> */}
            <Link
              to={`/playlists/pack-${encodeURIComponent(offer.packNameSlug)}/${
                offer.packCode
              }`}
            >
              <div>
                <CustomButton
                  text={offer.buttonText}
                  backgroundColor="#fff"
                  borderRadius="30px"
                  borderColor="none"
                  width="182px"
                  height="37px"
                  textColor="var(--button-primary)"
                  iconColor="var(--button-primary)"
                  fontSize="16px"
                  fontFamily="Raleway"
                  fontWeight="bold"
                  icon={<IoIosArrowForward />}
                  justifyContent="space-between"
                />
              </div>
            </Link>
          </div>
        ))}
      </div>
      {/* popular packs */}
      <div className="packs-container outer-full">
        <div className="inner inner-pl">
          <h1 className="packs-container-heading">Explore Popular Packs</h1>
          <div className="packs-description-view">
            <h2>Multi-track packs for all occasions at great savings!</h2>
            {/* <div className="packs-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div> */}
          </div>
          <div className="inner">
            <PackCard packCards={packCards} />
          </div>
        </div>
      </div>
      {/* all packs section */}
      <div style={{ marginTop: "10px" }}>
        <AllPackCard />
      </div>
    </div>
  );
};

export default PackListing;
