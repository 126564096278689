import axios from "axios";
import config from "../utils/config";
import { urls } from "../utils/AppManager";

export const getCart = async (token) => {
  const url = `${config.baseUrl}${urls.cart}/get?cartType=C`;
  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const putCart = async (token, payload) => {
  const url = `${config.baseUrl}${urls.cart}/update`;

  try {
    const res = await axios.put(url, payload, {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error updating cart:", error);
    return { data: [] };
  }
};

export const createOrder = async (token) => {
  console.log(token, "37");

  const url = `${config.baseUrl}${urls.createPruchaseOrder}/create`;

  try {
    const res = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error updating cart:", error);
    return { data: [] };
  }
};
