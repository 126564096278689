import React from "react";
import styles from "../EmptyStates/EmptyState.module.css";
import CustomButton from "../CustomButton/CustomButton";
import config from "../../utils/config";
import { Link } from "react-router-dom";

const EmptyState = ({ type }) => {
  // Destructure 'type' from props
  const renderEmptyState = () => {
    switch (type) {
      case "404":
        return renderPageNotFound();
      case "checkout":
        return renderEmptyCheckout();
      default:
        return null;
    }
  };

  const renderPageNotFound = () => (
    <>
      <a href="/">
        <img
          src={`${config.s3imgUrl}emptystates/error404.png`}
          alt=""
          className={styles.cartImg}
        />
      </a>
      <p className={styles.heading}>Oops! Page not found</p>
      <p className={styles.subHeading}>
        Looks like the page you're looking for doesn't exist. Let's find you a
        new one.
      </p>
      <Link to={"/"}>
        <CustomButton
          text="Back to home"
          backgroundColor="var(--button-primary)"
          borderRadius="50px"
          width="238px"
          height="48px"
          textColor="#fff"
          fontSize="16px"
          fontFamily="Raleway"
          fontWeight="550"
        />
      </Link>

      <div className={styles.bottomText}>
        <p className={styles.subHeading}>Not sure what went wrong?</p>
        <p className={styles.subHeading}>
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
            style={{ textDecoration: "underline" }}
          >
            Contact Support
          </a>{" "}
          or visit our{" "}
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
            style={{ textDecoration: "underline" }}
          >
            How To Page
          </a>{" "}
          for assistance.
        </p>
      </div>
    </>
  );

  const renderEmptyCheckout = () => (
    <>
      <img
        src={`${config.s3imgUrl}emptystates/emptycart.png`}
        alt=""
        className={styles.cartImg}
      />
      <p>Opps Nothing to Buy!</p>
      <p>Start discovering popular tracks to license for your projects</p>
      <Link to={"/"}>
        <CustomButton
          text="Continue Browsing"
          backgroundColor="var(--button-primary)"
          borderRadius="50px"
          width="238px"
          height="48px"
          textColor="#fff"
          fontSize="16px"
          fontFamily="Raleway"
          fontWeight="550"
        />
      </Link>
    </>
  );

  return <div className={styles.container}>{renderEmptyState()}</div>;
};

export default EmptyState;
