// utils/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "./firebase/remoteConfig"; // Adjust this import path as per your project structure.

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authConfig, setAuthConfig] = useState(null);
  const [loading, setLoading] = useState(true); // New state for loading
  const [userPhoneNumber, setUserPhoneNumber] = useState(null);

  // Utility to set cookies
  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Convert days to milliseconds
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
  };

  // Utility to get a cookie by name
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const [key, val] = cookies[i].split("=");
      if (key === name) {
        return decodeURIComponent(val);
      }
    }
    return null;
  };

  // Utility to delete a cookie
  const deleteCookie = (name) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
  };

  useEffect(() => {
    const fetchAuthConfig = async () => {
      try {
        await fetchAndActivate(remoteConfig); // Fetch and activate Remote Config
        const jsonString = getValue(remoteConfig, "auth").asString(); // Fetch the auth config
        const parsedConfig = JSON.parse(jsonString);
        setAuthConfig(parsedConfig); // Save config globally
      } catch (error) {
        console.error("Failed to fetch auth config:", error);
      } finally {
        // Restore login state from cookies
        const storedAuthState = getCookie("isAuthenticated");
        const storedPhoneNumber = getCookie("userPhoneNumber");
        // console.log(storedPhoneNumber, "storedPhoneNumber");

        if (storedAuthState === "true") {
          setIsAuthenticated(true);
          // console.log(userPhoneNumber,'userPhoneNumber');

          setUserPhoneNumber(storedPhoneNumber || null);
        }
        setLoading(false); // Mark loading as complete
      }
    };

    fetchAuthConfig();
  }, []);

  const login = (userPhoneNumber) => {
    // console.log(userPhoneNumber, "userPhoneNumber when login");

    setIsAuthenticated(true);
    setUserPhoneNumber(userPhoneNumber); // Set the phone number used for login

    // Set cookies with an expiry of 1 day
    setCookie("isAuthenticated", "true", 1);
    setCookie("userPhoneNumber", userPhoneNumber, 1);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserPhoneNumber(null); // Clear the phone number on logout

    // Delete cookies
    deleteCookie("isAuthenticated");
    deleteCookie("userPhoneNumber");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, authConfig }}
    >
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const getCookie = (name) => {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const [key, val] = cookies[i].split("=");
    if (key === name) {
      return decodeURIComponent(val);
    }
  }
  return null;
};
