import React from "react";
import styles from "../styles/AboutUs.module.css";
import CustomButton from "../components/CustomButton/CustomButton";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <h1 className={styles.topHeading}>
          Music That Moves Your Story Forward
        </h1>
        <p className={styles.subHeading}>
          Hoopr Smash is a music licensing platform offering premium and
          trending bollywood tracks with 100% copyright safety for brands,
          businesses and creators.
        </p>
        <Link to={"/"}>
          <CustomButton
            text="Start Now"
            backgroundColor="#F84451"
            borderRadius="50px"
            width="242px"
            height="50px"
            textColor="#fff"
            fontSize="18px"
            fontFamily="Raleway"
            fontWeight="550"
          />
        </Link>
        <div className={styles.topImageSection}></div>
        <p className={styles.context}>
          At Hoopr Smash, we believe every great story deserves a soundtrack
          that elevates it. That's why we've built a music licensing platform
          that connects artists, music labels, businesses, and brands to an
          exclusive collection of high-quality tracks spanning Bollywood hits,
          trending tunes, regional gems, and original music from independent
          artists.
        </p>
        <p className={styles.context}>
          With Hoopr Smash, you're not just licensing music—you're gaining the
          peace of mind to use premium tracks without any copyright worries.
          Whether it's for social media campaigns, short-form videos, or content
          designed to inspire, we make it easy for you to find the perfect music
          for every project.
        </p>
      </div>
      {/* trusted brand Section */}
      <div className={styles.trustedBrandSection}>
        <p className={styles.brandHeading}>Trusted by 125+ brands</p>
        <div className={styles.brandImagesContainer}>
          {Array(6)
            .fill()
            .map((_, index) => (
              <div key={index} className={styles.brandImages}></div>
            ))}
        </div>
      </div>
      {/* why choose hoopr Section */}
      <div className={styles.wchSection}>
        <p className={styles.brandHeading}>Why Choose Hoopr Smash?</p>
        <p className={styles.wchHeading}>A Curated Library for Every Story</p>
        <div className={styles.wchImagesSection}>
          <div className={styles.wchImg1}></div>
          <div className={styles.wchImg2}>
            <div className={styles.img2topSection}></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
