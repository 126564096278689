import React, { createContext, useContext, useState } from "react";

const GlobalPlayerContext = createContext();

export const GlobalPlayerProvider = ({ children }) => {
  const [playerData, setPlayerData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const showPlayer = (trackInfo) => {
    setPlayerData(trackInfo);
    setIsPlaying(true); // Automatically set to playing state when player is shown
  };

  const hidePlayer = () => {
    setPlayerData(null);
    setIsPlaying(false);
  };

  return (
    <GlobalPlayerContext.Provider
      value={{
        playerData,
        isPlaying,
        showPlayer,
        hidePlayer,
        setIsPlaying,
      }}
    >
      {children}
    </GlobalPlayerContext.Provider>
  );
};

export const useGlobalPlayer = () => useContext(GlobalPlayerContext);
