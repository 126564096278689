import React, { useState } from "react";
import styles from "../../styles/MyAccountCss/MyAccount.module.css";
import MyPurchase from "./Purchases";
import MyLikes from "./MyLikes";
import MyProfile from "./MyProfile";
import MyCollection from "./MyCollection";

const MyAccount = () => {
  const [selectedTab, setSelectedTab] = useState("purchases");

  const tabs = ["profile", "purchases", "likes", "playlists"];

  const renderContent = () => {
    switch (selectedTab) {
      case "profile":
        return (
          <div>
            <MyProfile />
          </div>
        );
      case "purchases":
        return (
          <div>
            <MyPurchase />
          </div>
        );
      case "likes":
        return (
          <div>
            <MyLikes />
          </div>
        );
      case "playlists":
        return (
          <div>
            <MyCollection />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.myAccountContainer}>
      {/* Left Navigation Bar */}
      <div className={styles.leftNav}>
        <h2 className={styles.navHeading}>My Account</h2>
        <hr className={styles.breakLine}></hr>
        <ul className={styles.tabsList}>
          {tabs.map((tab) => (
            <li
              key={tab}
              className={`${styles.tabItem} ${
                selectedTab === tab ? styles.activeTab : ""
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </li>
          ))}
        </ul>
      </div>

      {/* Right Content Area */}
      <div className={styles.rightContent}>{renderContent()}</div>
    </div>
  );
};

export default MyAccount;
