import { configureStore } from "@reduxjs/toolkit";
import trackFiltersReducer from "./trackFiltersSlice";
import cartReducer from "./cartSlice";
import uiReducer from "./uiSlice";

const store = configureStore({
  reducer: {
    trackFilters: trackFiltersReducer,
    cart: cartReducer,
    ui: uiReducer, // for global player visible state
  },
});

export default store;
