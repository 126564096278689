import axios from "axios";
import { urls } from "../utils/AppManager";
import config from "../utils/config";

export const otpSend = async (payload) => {
  const url = `${config.baseUrl}${urls.sendOtp}`;

  const res = await axios
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error, "error");
    });
  return res;
};

export const otpVerify = async (payload) => {
  const url = `${config.baseUrl}${urls.verifyOtp}`;

  const res = await axios
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error, "error");
    });
  return res;
};

export const userLogin = async (payload) => {
  const url = `${config.baseUrl}${urls.userLogin}`;

  const res = await axios
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error, "error");
    });
  return res;
};
