import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "../styles/SearchPage.module.css";
import { getSearchResultFaceted } from "../service/SearchService";
import randomDefaultImage from "../utils/RandomDefaultImage";
import getDirectLink from "../utils/AudioUrlFormated";
import TrackFilter from "../components/CardsComponent/TrackCard/TrackFilter";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import { FaFilter } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { useSelector } from "react-redux";
import CleanSearchKey from "../utils/CleanSearchKey";
import { TbFilterCheck } from "react-icons/tb";

const SearchPage = () => {
  const [activeTab, setActiveTab] = useState("Track"); // Default tab is 'Track'
  const [searchParams] = useSearchParams();
  const searchKeyword = searchParams.get("searchKeyword");
  const [page, setPage] = useState(1); // Dynamic page number
  const [results, setResults] = useState(null); // Store fetched results
  const [isFetching, setIsFetching] = useState(false); // Loading state
  const [showViewMore, setShowViewMore] = useState(false); // Control View More button visibility
  const [noDataFound, setNoDataFound] = useState(false); // State for "No data found"
  // const [initialLoadCompleted, setInitialLoadCompleted] = useState(false); // Track initial auto-fetch temporary
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [filters, setFilters] = useState({}); // state to manage the filters values
  const [sortBy, setSortBy] = useState({ type: "relevance", order: "" });
  const [priceFilters, setPriceFilters] = useState({});
  const [firstLoad, setFirstLoad] = useState(false); // state to manage to stop multiple api calls on first load

  const isGlobalPlayerActive = useSelector(
    (state) => state.ui.globalPlayerActive
  );

  // to load the filter on component mounts
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem("trackFilters")) || {};
    setFilters(savedFilters);
  }, []);

  useEffect(() => {
    // Reset the page and results when the searchKeyword changes
    setShowViewMore(false);
    setPage(1);
    setResults(null);
    setNoDataFound(false); // Reset "No data found" state
    // setFilters({});
    // setSortBy({ type: "relevance", order: "" });

    // setInitialLoadCompleted(false); // Reset auto-fetch state temporary
    // Scroll the page to the top
    // window.scrollTo(0, 0);
    fetchResults();
    setFirstLoad(true);
  }, [searchKeyword, filters, sortBy]); // This effect runs whenever the searchKeyword changes

  useEffect(() => {
    if (firstLoad) {
      fetchResults();
    }
    // if (initialLoadCompleted) {
    //   fetchResults();
    // } // temporary if
  }, [page]); // Re-run effect when searchKeyword or page changes

  // this use effect will run when ever the filter is changing
  // useEffect(() => {
  //   setPage(1);
  //   setResults(null);
  //   setShowViewMore(false);
  //   setNoDataFound(false); // Reset "No data found" state
  //   // setInitialLoadCompleted(false); // Reset auto-fetch state temporary
  //   if (firstLoad) {
  //     console.log("call 77");

  //     fetchResults();
  //   }
  //   setIsModalOpen(false);
  // }, [filters]);

  // console.log(filters);

  // Effect to react to changes in sortBy
  // useEffect(() => {
  //   setPage(1);
  //   setResults(null);
  //   setShowViewMore(false);
  //   setNoDataFound(false); // Reset "No data found" state
  //   // setInitialLoadCompleted(false); // Reset auto-fetch state temporary
  //   if (firstLoad) {
  //     console.log("call 94");

  //     fetchResults();
  //   }
  //   setIsModalOpen(false);
  // }, [sortBy]);

  // Effect to react to changes in price filters
  useEffect(() => {
    setPage(1);
    setResults(null);
    setShowViewMore(false);
    setNoDataFound(false); // Reset "No data found" state
    // setInitialLoadCompleted(false); // Reset auto-fetch state temporary
    if (firstLoad) {
      fetchResults();
    }
    setIsModalOpen(false);
  }, [priceFilters]);

  // console.log(priceFilters, "priceFilters");

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;

      // Log values
      // console.log("scrollTop:", scrollTop);
      // console.log("clientHeight:", clientHeight);
      // console.log("scrollHeight:", scrollHeight);
      // console.log("scrollTop + clientHeight:", scrollTop + clientHeight);
      // console.log("scrollHeight - 120:", scrollHeight - 120);

      // Avoid API call when fetching is in progress and when page >= 4
      if (
        scrollTop + clientHeight >= scrollHeight - 350 &&
        !isFetching &&
        page < 4 // Make sure the page is less than 4 before triggering
      ) {
        // console.log("Condition met: Loading more content");

        // Reached bottom and page is less than 4, load more
        setPage((prevPage) => prevPage + 1);
      }
    };

    // window.addEventListener("scroll", handleScroll);
    if (!showViewMore) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFetching, page, showViewMore]);

  // Auto-fetch for pages 1 through 4 temporary
  // useEffect(() => {
  //   const fetchAllPages = async () => {
  //     for (let i = 1; i <= 4; i++) {
  //       console.log(`Fetching page: ${i}`);
  //       setPage(i);
  //       await fetchResults(); // Wait for each fetch before moving to the next
  //     }
  //     setInitialLoadCompleted(true); // Mark auto-fetch as completed
  //   };

  //   if (!initialLoadCompleted) {
  //     fetchAllPages();
  //   }
  // }, [page]);

  // calling search api

  const fetchResults = async () => {
    // console.log("call");
    // console.log(filters, "filter val");

    try {
      setIsFetching(true);
      setNoDataFound(false); // Reset "No data found" state before fetching
      // const payload = {
      //   asset_type: "music",
      //   artists: [],
      //   bpm: { min: 30, max: 110 },
      //   genres: [],
      //   languages: [],
      //   moods: [],
      //   playlists: [],
      //   sfxcategories: [],
      //   sfxsubcategories: [],
      //   subgenres: [],
      //   usecases: [],
      // };

      // Construct the payload with dynamic filters
      const payload = {
        asset_type: "music",
        artists: [], // If no artists, use empty array
        bpm: { min: 1, max: 300 },
        price: { min: priceFilters?.min || "", max: priceFilters?.max || "" },
        genres:
          filters?.genres?.map((item) => CleanSearchKey(item.label)) || [],
        languages:
          filters?.languages?.map((item) => CleanSearchKey(item.label)) || [],
        moods: filters?.moods?.map((item) => CleanSearchKey(item.label)) || [],
        playlists: [],
        sfxcategories: [],
        sfxsubcategories: [],
        subgenres: filters?.subgenres || [],
        usecases:
          filters?.useCases?.map((item) => CleanSearchKey(item.label)) || [],
        instruments:
          filters?.instruments?.map((item) => CleanSearchKey(item.label)) || [],
      };

      // console.log(payload);

      // console.log(page);

      // Check and update sortby value if type is "relevance"

      let sortby = sortBy.type || "";
      // console.log(sortby, sortBy);

      if (sortby === "relevance") {
        sortby = "";
      }

      const order = sortBy.order || "";

      const data = await getSearchResultFaceted(
        searchKeyword,
        payload,
        page,
        sortby,
        order
      );
      const trackData = data.filter((item) => item.type === "music");
      // console.log(trackData, "trackData");

      if (trackData.length === 0) {
        setNoDataFound(true); // Set "No data found" if no results are returned
        if (page > 1) setShowViewMore(false); // Hide "View More" if no more results
      } else {
        setNoDataFound(false); // Reset "No data found" if results are returned

        // Filter out duplicates by track_code
        setResults((prevResults) => {
          const existingTrackCodes = new Set(
            prevResults?.map((track) => track.track_code) || []
          );
          const uniqueTracks = trackData.filter(
            (track) => !existingTrackCodes.has(track.track_code)
          );
          return prevResults ? [...prevResults, ...uniqueTracks] : uniqueTracks;
        });

        if (page >= 4) {
          setShowViewMore(true); // Show "View More" if the page limit is reached
        }
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsFetching(false);
    }
  };

  // fucntion to switch between the tabs
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // function to load more track on click of view more btn
  const handleViewMoreClick = () => {
    setPage((prevPage) => prevPage + 1); // Increment page on button click
  };

  // formatting the track cards
  const allTrackList =
    results?.map((track) => {
      // console.log(track);
      // console.log(track.sku);
      const trackSKu = track.sku[0];
      // console.log(trackSKu);
      const parsedSKU = JSON.parse(trackSKu);
      // console.log(parsedSKU);

      // const SKU = [
      //   {
      //     costPrice: 69999,
      //     sellingPrice: 49999,
      //     gstPercent: 18,
      //     maxUsage: 3,
      //   },
      // ];

      const SKU = [
        {
          costPrice: parsedSKU.cost_price,
          sellingPrice: parsedSKU.selling_price,
          gstPercent: parsedSKU.gst_percent,
          maxUsage: parsedSKU.max_usage,
          id: parsedSKU.id,
        },
      ];

      // console.log(SKU);

      // Extract costPrice and sellingPrice
      const { costPrice, sellingPrice, id } = SKU[0];

      // Calculate discount percentage
      const discountPercent = (
        ((costPrice - sellingPrice) / costPrice) *
        100
      ).toFixed(0);

      const artistNames = JSON.parse(track.tracks_artist_role || "[]") // Ensure valid JSON
        .filter((item) => item.role === "primary")
        .map((item) => item.artist.name)
        .join(", ");

      return {
        image:
          track.image_url !== "" && track.image_url != null
            ? track.image_url
            : randomDefaultImage("track"),
        title:
          track.name.length > 18
            ? track.name.substring(0, 17) + "..."
            : track.name,
        description:
          artistNames.length > 30
            ? artistNames.substring(0, 29) + "..."
            : artistNames,
        buttonText: "Explore",
        buttonLink: "#",
        discountPercent: discountPercent || "50% OFF",
        costPrice: costPrice.toLocaleString("en-IN") || "19999",
        sellingPrice: sellingPrice.toLocaleString("en-IN") || "79000",
        name: track.name_slug,
        trackCode: track.track_code,
        waveJson: track.waveform_link,
        trackUrl:
          track.mp3_link || getDirectLink(track.link) || track.waveform_link,
        skuId: id || "",
      };
    }) || [];

  // console.log(allTrackList, "allTrackList");

  // Toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Close the modal if the click happens outside the modalContainer
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleFiltersSelected = (filters) => {
    // console.log("Updated Filters:", filters);
    setFilters(filters);
    // You can use the updated filters object here to fetch data or update the UI
  };

  const handlePriceFilterChange = (priceFilters) => {
    // console.log(priceFilters, "priceFilters");
    setPriceFilters(priceFilters);
  };

  const handleSortByChange = (e) => {
    const [type, order] = e.target.value.split("-");
    setSortBy({
      type,
      order: order || "",
      min: "",
      max: "",
    });
  };

  // console.log("Global Player Active : ", isGlobalPlayerActive); // Log the value
  const overlayClass = isGlobalPlayerActive
    ? styles.modalOverlayWithPlayer
    : styles.modalOverlay;

  const isPriceFilterActive = priceFilters.min && priceFilters.max;

  const hasActiveFilters =
    Object.values(filters).some(
      (filter) => Array.isArray(filter) && filter.length > 0
    ) ||
    sortBy.type !== "relevance" ||
    isPriceFilterActive;

  return (
    <>
      {/* Rotated Button */}
      <div className={styles.rotateButton} onClick={toggleModal}>
        {hasActiveFilters ? (
          <div className={styles.filterActiveContainer}>
            <TbFilterCheck size={22} className={styles.filterActive} />
          </div>
        ) : (
          <FaFilter size={22} className={styles.filterIcon} />
        )}
        <span>Sort & Filters</span>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className={overlayClass} onClick={handleOverlayClick}>
          <div className={styles.modalContainer}>
            {/* header section */}
            <div className={styles.header}>
              <div className={styles.heading}>
                <p className={styles.headingtext}>Sort & Filters</p>
              </div>
              <IoIosClose
                onClick={closeModal}
                color="#000"
                cursor="pointer"
                size={34}
              />
            </div>
            <br></br>
            <div className={styles.filterWapper}>
              {/* Sort By Section */}
              <div className={styles.filtersSection}>
                <div className={styles.filterHeading}>
                  <p>Sort By</p>
                  {/* add radio or checkboxes for price name and owener if price is selected the display the two input fileds for
                max and min value and in a use effect the sort by value will be check on every change all the value of sort by will be in object. */}
                </div>
                <hr style={{ color: "#555" }} />
                <div className={styles.sortOptions}>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="relevance"
                      checked={sortBy.type === "relevance"}
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Relevance
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      // value="price"
                      value="price-asc"
                      // checked={sortBy.type === "price"}
                      checked={
                        sortBy.type === "price" && sortBy.order === "asc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Price (low to high)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      // value="price"
                      value="price-desc"
                      // checked={sortBy.type === "price"}
                      checked={
                        sortBy.type === "price" && sortBy.order === "desc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Price (high to low)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="name-asc"
                      // checked={sortBy.type === "name"}
                      checked={sortBy.type === "name" && sortBy.order === "asc"}
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Name (Asc)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="name-desc"
                      // checked={sortBy.type === "name"}
                      checked={
                        sortBy.type === "name" && sortBy.order === "desc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Name (Desc)
                  </label>
                </div>
              </div>
              {/* Filter Section */}
              <div style={{ marginTop: 20 }} className={styles.filtersSection}>
                <div className={styles.filterHeading}>
                  <p>Filter</p>
                  {/* <IoIosArrowDown /> */}
                </div>
                <hr style={{ color: "#555" }} />
                <div>
                  <TrackFilter
                    onPriceFilterChange={handlePriceFilterChange}
                    onFiltersSelected={handleFiltersSelected}
                    hideApply={true}
                    width="100%"
                    showPriceFilter={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`${styles.searchPageContainer} ${styles.outerFull}`}>
        <div className={styles.searchPageInnerContainer}>
          {/* Tabs Header */}
          <div className={styles.searchTabs}>
            <span
              className={`${styles.tab} ${
                activeTab === "Track" ? styles.tabActive : ""
              }`}
              onClick={() => handleTabClick("Track")}
            >
              Track
            </span>
          </div>

          {/* Content for active tab */}
          <div className={styles.tabContent}>
            {activeTab === "Track" && (
              <div className={styles.searchContainer}>
                <div className={styles.trackData}>
                  <div className={styles.searchHeadingSection}>
                    <p className={styles.searchHeading}>
                      {searchKeyword
                        ? `Search Result for ${searchKeyword}`
                        : "All Tracks"}
                    </p>
                    {hasActiveFilters && (
                      <p
                        onClick={() => {
                          setFilters({});
                          localStorage.removeItem("trackFilters");
                          localStorage.removeItem("priceFilters");
                          setSortBy({ type: "relevance", order: "" });
                          setPriceFilters({});
                        }}
                        className={styles.clearFilterText}
                      >
                        Clear Filters
                      </p>
                    )}
                  </div>
                  <div>
                    <TrackCard
                      trackType="contemporary"
                      trackCard={allTrackList}
                    />
                    {isFetching && (
                      <p style={{ textAlign: "center", marginTop: "30px" }}>
                        Loading more tracks...
                      </p>
                    )}
                    {noDataFound && (
                      <p style={{ textAlign: "center", marginTop: "30px" }}>
                        No data found.
                      </p>
                    )}
                  </div>
                  {showViewMore && !noDataFound && (
                    <button
                      className={styles.viewMoreBtn}
                      onClick={handleViewMoreClick}
                    >
                      View More
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchPage;
