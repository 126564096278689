import React, { useState, useEffect, useContext } from "react";
import styles from "../styles/Cart.module.css";
import { IoIosClose, IoIosArrowUp } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomImg from "../components/CustomImg/CustomImg";
import { FiMinus, FiPlus } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import {
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
} from "../utils/redux/cartSlice";
import { useNavigate } from "react-router-dom";
import { getCart, putCart } from "../service/TransactionService";
import { ToastContext } from "../utils/ToastContext";
import { addToCart, selectItemCount } from "../utils/redux/cartSlice";
import config from "../utils/config";
import { Link } from "react-router-dom";

const Cart = ({ isOpen, onClose }) => {
  const [isCartOpen, setIsCartOpen] = useState(isOpen); // Sync with parent initially
  // const [cartItems, setCartItems] = useState([]); // Manage all cart items
  const cartItems = useSelector((state) => state.cart.items); // Access cart items from Redux store
  const dispatch = useDispatch(); // Hook to dispatch actions
  const navigate = useNavigate(); // Hook to navigate programmatically
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const totalItemCount = useSelector(selectItemCount); // Get the item count from Redux store

  const isGlobalPlayerActive = useSelector(
    (state) => state.ui.globalPlayerActive
  );

  // console.log(cartItems, "cartItems");

  // Sync `isCartOpen` with `isOpen` prop whenever it changes
  useEffect(() => {
    setIsCartOpen(isOpen);
  }, [isOpen]);

  // use effect for fetch the cart data from api
  useEffect(() => {
    const fetchCart = async () => {
      const fetchCart = await getCart();
      // console.log(fetchCart, "fetching cart data");

      if (fetchCart?.error?.code === 0) {
        const cartInfo = fetchCart.data.items.map((item) => {
          // Extract data from API response to build cartInfo object
          return {
            cardType: "track", // assuming all items are track types
            preText: item.item.name, // track name
            secText: item.item.tracksArtistRole
              .map((artist) => artist.artist.name)
              .join(", "), // artist names
            code: item.item.trackCode, // track code
            sp: item.sku.sellingPrice.toLocaleString("en-IN"), // selling price
            cp: item.sku.costPrice.toLocaleString("en-IN"), // assuming selling price is the same as cost price
            cardImg: item.item.image || "", // use item ID or another field for the image
            trackJson: "",
            trackUrl: "",
            skuID: item.sku.id, // sku id
          };
        });
        // Dispatch each cart item separately to Redux
        // note if you see the number twice or doubled then comment the strict mode of react in local and check
        cartInfo.forEach((item) => {
          dispatch(addToCart(item)); // Dispatch each item to the Redux store
        });
        // Log the cartItems or use it as needed
        // console.log(cartInfo, "formatted cart items");
      } else {
        // showToast("Error", "Failed to get cart", "error"); // Show error toast if API fails
      }
    };
    fetchCart();
  }, []);

  // fucntion to call on increment,decrement and deletion of item in the cart
  const updateCart = async (payload) => {
    // prepare payload
    // const payload = {
    //   items: [{ skuId: "id", qty: "Quantity", cartType: "C" }],
    // };

    const addCart = await putCart(payload);
    return addCart;
  };

  // Function to close the modal
  const closeModal = () => {
    setIsCartOpen(false); // Close modal locally
    onClose(); // Notify the parent to close the modal
  };

  if (!isCartOpen) return null; // Don't render if the modal is closed

  const handleIncrement = async (code) => {
    // Find the current item in the cartItems array
    const currentItem = cartItems.find((item) => item.code === code);
    const currentQuantity = currentItem.quantity;
    const newQuantity = currentQuantity + 1; // Increment quantity
    const skuID = currentItem.skuID; // Get the SKU ID for the item

    // Prepare payload
    const payload = {
      items: [{ skuId: skuID, qty: newQuantity, cartType: "C" }],
    };
    // console.log(payload, "increment");

    const updateResponse = await updateCart(payload); // Call the updateCart function to update the backend
    // Check if the API update was successful
    // console.log(updateResponse, "api resp");

    if (
      updateResponse &&
      updateResponse.error &&
      updateResponse.error.code === 0
    ) {
      dispatch(incrementQuantity(code));
      showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg
    } else {
      showToast("Error", "Failed to update the cart", "error"); // Show error toast if API fails
    }

    // dispatch(incrementQuantity(code)); // Increment quantity in Redux
  };

  const handleDecrement = async (code) => {
    // Find the current item in the cartItems array
    const currentItem = cartItems.find((item) => item.code === code);
    const currentQuantity = currentItem.quantity;
    if (currentQuantity > 1) {
      const newQuantity = currentQuantity - 1; // Decrement quantity
      const skuID = currentItem.skuID; // Get the SKU ID for the item

      // Prepare payload
      const payload = {
        items: [{ skuId: skuID, qty: newQuantity, cartType: "C" }],
      };

      const updateResponse = await updateCart(payload); // Call the updateCart function to update the backend
      // Check if the API update was successful
      if (
        updateResponse &&
        updateResponse.error &&
        updateResponse.error.code === 0
      ) {
        dispatch(decrementQuantity(code));
        showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg
      } else {
        showToast("Error", "Failed to update the cart", "error"); // Show error toast if API fails
      }

      // dispatch(decrementQuantity(code)); // Decrement quantity in Redux
    }
  };

  const handleRemoveItem = async (code) => {
    // Find the current item in the cartItems array
    const currentItem = cartItems.find((item) => item.code === code);
    const skuID = currentItem.skuID; // Get the SKU ID for the item

    // qty will be in int not in a string
    // Prepare payload
    const payload = {
      items: [{ skuId: skuID, qty: 0, cartType: "C" }],
    };
    // console.log(payload, "delete");

    const updateResponse = await updateCart(payload); // Call the updateCart function to update the backend
    // Check if the API update was successful

    if (
      updateResponse &&
      updateResponse.error &&
      updateResponse.error.code === 0
    ) {
      dispatch(removeFromCart(code));
      showToast("Deleted", "Track Removed", "success"); //display toast msg
    } else {
      showToast("Error", "Failed to update the cart", "error"); // Show error toast if API fails
    }

    // dispatch(removeFromCart(code)); // Remove item from Redux cart
  };

  const handleCheckout = () => {
    closeModal(); // Close the modal
    // setTimeout(() => {
    navigate("/checkout"); // Navigate after modal closes
    // }, 300); // Add a small delay for modal animation if necessary
  };

  // Close the modal if the click happens outside the modalContainer
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
      // onOutsideClick && onOutsideClick(); // Notify the parent about the outside click
    }
  };

  // console.log("Global Player Active in Cart: ", isGlobalPlayerActive); // Log the value
  const overlayClass = isGlobalPlayerActive
    ? styles.modalOverlayWithPlayer
    : styles.modalOverlay;

  // console.log(cartItems, "cartItems");

  return (
    <div className={overlayClass} onClick={handleOverlayClick}>
      <div className={styles.modalContainer}>
        {/* cart header section */}
        <div className={styles.header}>
          <div className={styles.heading}>
            <p className={styles.headingtext}>My Cart</p>
            <div className={styles.cartCount}>{totalItemCount}</div>
            {/* <div className={styles.cartCount}>{cartItems.length}</div> */}
          </div>
          <IoIosClose
            onClick={closeModal}
            color="#000"
            cursor="pointer"
            size={34}
          />
        </div>
        {cartItems.length > 0 ? (
          <>
            {/* billing cards */}
            <div className={styles.billItems}>
              {cartItems.length > 0 &&
                cartItems.map((item, index) => (
                  <div key={index} className={styles.cardContainer}>
                    <div
                      className={`${styles.cardDetails} ${
                        item.cardType === "pack" ? styles.alignCenter : ""
                      }`}
                    >
                      <CustomImg
                        type={item.cardType}
                        id={item.code}
                        alt={item.preText}
                        imgClass={
                          item.cardType === "track"
                            ? styles.trackImage
                            : styles.packImage
                        }
                        // imgClass={styles.trackImage}
                        src={item.cardImg}
                      />
                      <div className={styles.cardInfo}>
                        <p className={styles.cardPriText}>{item.preText}</p>
                        <p className={styles.cardSectext}>{item.secText}</p>
                        <div className={styles.itemControls}>
                          <div className={styles.itemIncBtn}>
                            <FiMinus
                              size={14}
                              color="#000"
                              cursor="pointer"
                              onClick={() => handleDecrement(item.code)}
                            />
                            <span className={styles.itemCount}>
                              {item.quantity || 1}
                            </span>
                            <FiPlus
                              size={14}
                              color="#000"
                              cursor="pointer"
                              onClick={() => handleIncrement(item.code)}
                            />
                          </div>
                          <AiOutlineDelete
                            onClick={() => handleRemoveItem(item.code)}
                            size={22}
                            // color="#3f3f3f"
                            cursor="pointer"
                            className={
                              styles.deleteIcon
                            } /* Apply the new CSS class */
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.itemTotalPrice}>
                      <span className={styles.cardTotalPriceText}>
                        Pay (Inc. GST)
                      </span>
                      {/* <p className={styles.cardTotalPrice}>
                    ₹
                    {(
                      parseInt(item.sp.replace(",", "")) * item.quantity
                    ).toLocaleString("en-IN")}
                  </p> */}
                      <p className={styles.cardTotalPrice}>
                        {item.cardType === "track"
                          ? item.sp
                          : `₹${Number(item.sp)?.toLocaleString("en-IN")}`}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            {/* total bill summary section */}
            <div className={styles.billSummary}>
              <p className={styles.sumHeadingText}>Billing Summary</p>
            </div>
            <div className={styles.billInfo}>
              <div className={styles.billDetail}>
                <div className={styles.detailHeading}>
                  <p>Subtotal</p>
                </div>
                {/* displaying the cost price here */}
                <div className={styles.price}>
                  <span>
                    ₹
                    {Number(
                      (
                        cartItems.reduce(
                          (subtotal, item) =>
                            subtotal +
                            parseInt(item.sp.replace(",", ""), 10) *
                              (item.quantity || 1),
                          0
                        ) / 1.18
                      ).toFixed(2) // Ensure two decimal places for precision
                    ).toLocaleString("en-IN")}
                    {/* ₹
                {cartItems
                  .reduce(
                    (subtotal, item) =>
                      subtotal +
                      parseInt(item.cp.replace(",", ""), 10) *
                        (item.quantity || 1),
                    0
                  )
                  .toLocaleString("en-IN")} */}
                  </span>
                </div>
              </div>
              {/* <div className={styles.billDetail}>
            <div className={styles.detailHeading}>
              <p>Discount</p>
            </div>
            <div
              style={{ color: "#00B049", fontWeight: 550 }}
              className={styles.price}
            >
              <span>
                -
                {cartItems
                  .reduce(
                    (totalSavings, item) =>
                      totalSavings +
                      (parseInt(item.cp.replace(",", "")) -
                        parseInt(item.sp.replace(",", ""))) *
                        item.quantity,
                    0
                  )
                  .toLocaleString("en-IN")}
              </span>
            </div>
          </div> */}
              <div className={styles.billDetail}>
                <div className={styles.detailHeading}>
                  <p>TAX (18%GST)</p>
                </div>
                <div className={styles.price}>
                  <span>
                    {" "}
                    ₹
                    {Number(
                      (
                        cartItems.reduce(
                          (total, item) =>
                            total +
                            parseInt(item.sp.replace(",", ""), 10) *
                              item.quantity,
                          0
                        ) -
                        cartItems.reduce(
                          (subtotal, item) =>
                            subtotal +
                            (parseInt(item.sp.replace(",", ""), 10) *
                              (item.quantity || 1)) /
                              1.18,
                          0
                        )
                      ).toFixed(2) // Ensure two decimal places for precision
                    ).toLocaleString("en-IN")}
                  </span>
                </div>
              </div>
              <div className={styles.billDetail}>
                <div
                  style={{ fontWeight: "bold" }}
                  className={styles.detailHeading}
                >
                  <p>Total</p>
                </div>
                <div style={{ fontWeight: "bold" }} className={styles.price}>
                  <span>
                    ₹
                    {Number(
                      cartItems
                        .reduce(
                          (total, item) =>
                            total +
                            parseInt(item.sp.replace(",", ""), 10) *
                              item.quantity,
                          0
                        )
                        .toFixed(2) // Ensure two decimal places for precision
                    ).toLocaleString("en-IN")}
                  </span>
                </div>
              </div>
            </div>
            {/* total price and checkout section */}
            <div className={styles.bllingTotal}>
              {/* total amount section */}
              <div>
                <p className={styles.totalCost}>
                  ₹
                  {Number(
                    cartItems
                      .reduce(
                        (total, item) =>
                          total +
                          parseInt(item.sp.replace(",", "")) * item.quantity,
                        0
                      )
                      .toFixed(2) // Ensure two decimal places for precision
                  ).toLocaleString("en-IN")}
                </p>
                {/* <div className={styles.savings}>You are saving ₹1899</div> */}
                {/* <div className={styles.savings}>
              You Saved ₹
              {cartItems
                .reduce(
                  (totalSavings, item) =>
                    totalSavings +
                    (parseInt(item.cp.replace(",", "")) -
                      parseInt(item.sp.replace(",", ""))) *
                      item.quantity,
                  0
                )
                .toLocaleString("en-IN")}
            </div> */}
              </div>
              {/* checkout button */}
              <div>
                <CustomButton
                  text="Checkout"
                  backgroundColor="var(--button-primary)"
                  borderRadius="50px"
                  borderColor="1px solid #CCCCCC"
                  width="212px"
                  height="48px"
                  textColor="#fff"
                  iconColor="#272727"
                  fontSize="16px"
                  fontFamily="Raleway"
                  fontWeight="550"
                  onClick={handleCheckout} // Call the checkout handler
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.Emptycontainer}>
            <img
              src={`${config.s3imgUrl}emptystates/emptycart.png`}
              alt=""
              className={styles.EmptycartImg}
            />
            <p className={styles.Emptyheading}>Your cart is empty!</p>
            <p className={styles.EmptysubHeading}>
              Start discovering popular tracks to license for your projects
            </p>
            <Link to={"/"}>
              <CustomButton
                text="Continue Browsing"
                backgroundColor="var(--button-primary)"
                borderRadius="50px"
                width="238px"
                height="48px"
                textColor="#fff"
                fontSize="16px"
                fontFamily="Raleway"
                fontWeight="550"
                onClick={closeModal}
              />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
