import mixpanel from "mixpanel-browser";
import { useAuth } from "../utils/AuthContext"; // Import the context for user identity
import config from "../utils/config";
import { useEffect } from "react";

// Functional Component approach
const Mixpanel = () => {
  const { isAuthenticated, authConfig, userPhoneNumber } = useAuth();
  // console.log(userPhoneNumber, "9"); // Log the authConfig object to the console

  // Initialize Mixpanel when the component is mounted
  useEffect(() => {
    if (!mixpanel.__initialized) {
      mixpanel.init(config.mixpanelDev, { debug: true });
      mixpanel.__initialized = true;
    }
  }, []); // Empty dependency array ensures this runs only once

  // Track an event with optional properties and identity
  const trackEvent = (eventName, properties = {}) => {
    // If the user is authenticated, add identity and user-specific properties
    if (isAuthenticated) {
      const userId = authConfig?.userId || "anonymous";

      // Fallback: If the name is not available, use the mobile number
      const nameOrMobile = authConfig?.name || userPhoneNumber || "Anonymous";

      const userProperties = {
        $name: authConfig?.name || nameOrMobile,
        $email: authConfig?.email,
        $phone: userPhoneNumber || "N/A", // Include mobile number if available
      };

      // Identify the user in Mixpanel
      mixpanel.identify(userId);
      mixpanel.people.set(userProperties);

      // Merge the user properties with the passed properties
      properties = { ...properties, userId, ...userProperties };
    }

    // Track the event with the specified name and properties
    mixpanel.track(eventName, properties);
  };

  // Return the trackEvent function to be used in components
  return { trackEvent };
};

export default Mixpanel;
