import React, { useEffect } from "react";
import "../styles/GlobalPlayer.css";
import { useGlobalPlayer } from "../utils/GlobalPlayerContext";
import { BiAddToQueue, BiX } from "react-icons/bi"; // Import close icon
import { FaRegHeart } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import CustomButton from "../components/CustomButton/CustomButton";
import { IoIosArrowForward } from "react-icons/io";
import { useLocation, Link } from "react-router-dom";
import Waveform from "../utils/Waveform";
import { setGlobalPlayerActive } from "../utils/redux/uiSlice";
import { useDispatch } from "react-redux";
import { useHandleShareClick } from "../utils/Share";

const GlobalPlayer = () => {
  const { playerData, isPlaying, setIsPlaying, hidePlayer } = useGlobalPlayer(); // Use control from context
  const location = useLocation(); // Get current route for conditional rendering of pricing button
  const dispatch = useDispatch();
  const { handleShareClick } = useHandleShareClick(); // Use the custom hook

  useEffect(() => {
    // Log the state change
    if (playerData) {
      // console.log("Setting Global Player Active to true");
      dispatch(setGlobalPlayerActive(true));
    }

    return () => {
      // console.log("Setting Global Player Active to false");
      dispatch(setGlobalPlayerActive(false));
    };
  }, [dispatch, playerData]);

  if (!playerData) return null;

  // Check if the current route matches `/tracks/:name/:trackCode`
  const isTrackPage = /^\/tracks\/[^/]+\/[^/]+$/.test(location.pathname);

  // console.log(playerData, "useGlobalPlayer");
  //   console.log(isPlaying, "isPlaying from global");

  const handlePlayPauseChange = (playing) => {
    setIsPlaying(playing); // Update the global play state
  };

  const handleClosePlayer = () => {
    dispatch(setGlobalPlayerActive(false)); // Update Redux state on close
    hidePlayer();
  };

  return (
    <div className="global-player-container outer-full">
      <div className="globalplayer inner">
        <div className="g-player-container">
          {/* Close Icon */}
          <div className="g-player-close" onClick={handleClosePlayer}>
            <BiX size={24} color="#fff" />
          </div>
          {/* track info */}
          <div className="g-track-container">
            <div className="g-track-img-container">
              <img
                className="g-track-img"
                src={playerData.trackImg}
                alt="Track"
              />
            </div>
            <div className="g-track-info">
              <h1>{playerData.trackName}</h1>
              <h2>{playerData.trackArtistName}</h2>
            </div>
          </div>
          {/* waveform with player controls */}
          <div className="g-waveform-container">
            <Waveform
              trackUrl={playerData.trackUrl}
              waveJson={playerData.trackJson}
              play={isPlaying} // Pass global isPlaying state
              onPlayPauseChange={handlePlayPauseChange} // Pass the callback
            />
          </div>
        </div>
        <div>
          {/* player-action button */}
          <div className="g-player-actions">
            <FiShare2 onClick={handleShareClick} size={22} color="#B2B2B2" />
            <BiAddToQueue size={22} color="#B2B2B2" />
            <FaRegHeart size={22} color="#B2B2B2" />
            {/* Hide the custom button if the current route is the TrackPage */}
            {!isTrackPage && (
              <Link
                to={`/tracks/${encodeURIComponent(playerData.trackName)}/${
                  playerData.trackCode
                }`}
              >
                <CustomButton
                  justifyContent="space-between"
                  text="Pricing"
                  backgroundColor="#fff"
                  textColor="#272727"
                  fontWeight="600"
                  width="147px"
                  height="30px"
                  borderRadius="50px"
                  fontSize="14px"
                  iconColor="#272727"
                  icon={<IoIosArrowForward />}
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalPlayer;
