// const config = {
//   baseUrl: "https://api.staging.select.hoopr.ai/",
//   // s3imgUrl: "https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/",
//   s3imgUrl: "https://cdn.hooprsmash.com/",
//   mixpanelDev: "c6cb3445ac295218091e602a07c3e360",
// };

// export default config;

const config = {
  env: process.env.REACT_APP_ENV || "development", // Default to "development" if not set
  baseUrl: process.env.REACT_APP_API_URL,
  s3imgUrl: process.env.REACT_APP_S3_IMGURL,
  mixpanelDev: process.env.REACT_APP_MIXPANEL,
  robotsTxt:
    process.env.REACT_APP_ENV === "production"
      ? `
      User-agent: *
      Allow: /
    `
      : `
      User-agent: *
      Disallow: /
    `,
};

// console.log(config);

export default config;

// Sitemap: https://yourdomain.com/sitemap.xml paste this sitemap url after allow line no 18 in production env
