const genres = {
  "067044d8-9c45-49d8-9ece-2bb4857928d4": {
      "name": "Acoustic",
      "slug": "acoustic",
      "description": "Soft and organic tracks featuring guitars and pianos, perfect for storytelling vlogs, heartfelt moments, and minimalistic videos."
  },
  "4b2a7fca-d34b-4baa-972b-03086b86a0ff": {
      "name": "Afro",
      "slug": "afro",
      "description": "Energetic rhythms and vibrant melodies inspired by African culture, ideal for travel content, dance videos, and cultural celebrations."
  },
  "6612f3b1-8745-4305-afe9-acd973f7cecf": {
      "name": "Ambient",
      "slug": "ambient",
      "description": "Dreamy soundscapes and atmospheric tones designed for meditation, yoga content, and cinematic backgrounds."
  },
  "8be0c3e5-1f2a-4f1e-98e8-494abe02406f": {
      "name": "Blues",
      "slug": "blues",
      "description": "Soulful guitar riffs and emotional melodies that bring depth to character-driven stories and vintage narratives."
  },
  "3e3cb3f5-fcf0-4e4d-a5b9-ea1d6c2cf4cc": {
      "name": "Bollywood",
      "slug": "bollywood",
      "description": "Peppy dance numbers and romantic ballads that add energy to weddings, cultural events, and dramatic storytelling."
  },
  "478cf343-37bc-4458-b596-5de8c0edcddb": {
      "name": "Cinematic",
      "slug": "cinematic",
      "description": "Grand orchestral scores and epic themes suited for movie trailers, impactful intros, and adventure sequences."
  },
  "fa082a08-94f8-48f1-ae33-35c1194baa77": {
      "name": "Corporate",
      "slug": "corporate",
      "description": "Upbeat and motivational tracks with polished tones, ideal for presentations, explainer videos, and success stories."
  },
  "ea5d8e0a-c5d9-41b9-a71d-dfab7087ed76": {
      "name": "Country",
      "slug": "country",
      "description": "Rustic melodies with acoustic charm, great for nature-inspired content, travelogues, and lifestyle projects."
  },
  "ea7468e8-b5ef-46d9-ace6-9d10e5fc5760": {
      "name": "Devotional",
      "slug": "devotional",
      "description": "Spiritual compositions with serene melodies, perfect for temple visits, cultural festivals, and prayer videos."
  },
  "ef5b24a7-702b-462f-bf80-29cae8a6dbc7": {
      "name": "Electronic",
      "slug": "electronic",
      "description": "High-energy beats and futuristic sounds for tech showcases, gaming content, and party montages."
  },
  "62a33aa8-4133-4084-9eac-05ecd702eb44": {
      "name": "Festival",
      "slug": "festival",
      "description": "Lively, celebratory tracks to light up festive reels, wedding montages, and cultural videos."
  },
  "33c990ad-4298-4a07-a19b-93b148772e28": {
      "name": "Funk",
      "slug": "funk",
      "description": "Groovy basslines and energetic rhythms that add flair to dance challenges, fashion shoots, and quirky promotions."
  },
  "6a79250a-2be5-43e9-a6ba-e094dfc46120": {
      "name": "Hip Hop / Rap",
      "slug": "hip-hop-rap",
      "description": "Bold beats and urban vibes for street-style videos, sports highlights, and edgy urban content."
  },
  "82e9113f-c1a4-492b-adf9-8e292c7f4a9a": {
      "name": "Indian Classical",
      "slug": "indian-classical",
      "description": "Traditional ragas with intricate instrumentation, perfect for cultural documentaries and serene montages."
  },
  "55c32e7e-3214-405f-9196-356cbdcbfa64": {
      "name": "Indian Contemporary",
      "slug": "indian-contemporary",
      "description": "A fusion of modern and Indian sounds, ideal for fashion, lifestyle content, and modern narratives."
  },
  "0c29a125-b83e-4b4b-8208-ac8b7e810e2b": {
      "name": "Indian Folk",
      "slug": "indian-folk",
      "description": "Raw and rustic folk tunes that enhance travelogues, cultural explorations, and village stories."
  },
  "b34273af-0f15-468c-befd-2f4dbff21f00": {
      "name": "Indian Folk Fusion",
      "slug": "indian-folk-fusion",
      "description": "A blend of traditional folk and modern beats, perfect for festive videos and vibrant cultural showcases."
  },
  "63d98bbb-3754-4366-970b-f7cb1b230b59": {
      "name": "Indie",
      "slug": "indie",
      "description": "Unique, experimental tracks with a creative edge, great for offbeat travel videos and artistic projects."
  },
  "8462b1d8-6210-4a61-9174-76a3f1a1af99": {
      "name": "Jazz",
      "slug": "jazz",
      "description": "Smooth melodies with sophisticated tones, ideal for café shots, nightlife videos, and upscale promotions."
  },
  "31026e90-b30c-4b15-ae87-be7fbd924b1a": {
      "name": "Kids",
      "slug": "kids",
      "description": "Playful and cheerful tunes designed for kids’ shows, animations, and toy reviews."
  },
  "20129102-87b0-42b0-932d-5120cc2e5ace": {
      "name": "Latin",
      "slug": "latin",
      "description": "High-energy Spanish-inspired melodies and rhythmic beats for dance sequences and exotic travel content."
  },
  "109d19cd-02d1-4f8d-b5c7-81d073db6a62": {
      "name": "Pop",
      "slug": "pop",
      "description": "Catchy and vibrant tracks that complement fashion shoots, social media trends, and upbeat lifestyle content."
  },
  "2d67bd5d-353b-47dc-8373-0e60d3ce7970": {
      "name": "Retro",
      "slug": "retro",
      "description": "Nostalgic vintage tunes that suit throwback-themed videos, vintage-style content, and quirky montages."
  },
  "13d50cdd-6fcc-4537-b415-fd4dc28423be": {
      "name": "RnB",
      "slug": "rnb",
      "description": "Smooth and soulful vibes that enhance romantic videos, fashion content, and lifestyle vlogs."
  },
  "747af598-6978-440e-a1c7-932a9d185d11": {
      "name": "Rock",
      "slug": "rock",
      "description": "Power-packed guitar riffs and energetic beats for action sequences, sports highlights, and adventure videos."
  },
  "063115a1-b198-4095-9054-1c4d4359cd73": {
      "name": "Western Classical",
      "slug": "western-classical",
      "description": "Elegant symphonies and orchestral pieces for historical content, luxury brand promotions, and serene backdrops."
  },
  "403989c0-f71f-4b00-b534-d2fe4f0af1b9": {
      "name": "World",
      "slug": "world",
      "description": "Rich global musical traditions that elevate documentaries, travel videos, and cultural explorations."
  }
}

export default genres;