import React from "react";

const termsAndConditions = [
  {
    sectionTitle: "",
    content: [
      {
        type: "paragraph",
        text: "This legally binding agreement (“Hoopr Smash Terms”) is entered into between you (“User”/ “Users”) and GSharp Media Private Limited, operating under the brand name “Hoopr” with its registered office at A-1203, Serenity Complex, Off Link Road, Oshiwara West Mumbai 400102 (“Company”). These Hoopr Smash Terms, in conjunction with the Hoopr.ai General Terms and Conditions of Use, Privacy Policy, and any additional policies (collectively referred to as “Terms”), govern the User’s use of services operated by the Company (“Services”). The Services include, but are not limited to, any domains, websites, apps, plugins, executables, licenses, or other services provided by the Company, excluding those provided under separate written agreements.",
      },
      {
        type: "paragraph",
        text: "“Website” shall mean the network operated by or on behalf of the Company or its subsidiaries and affiliates, including without limitation, https://hooprsmash.com and any other domains, websites or apps owned or operated by the Company.",
      },
      {
        type: "paragraph",
        text: "The Company reserves the right to modify these Terms at its sole discretion. Any amendments will be published on the Company’s website, and continued use of the Services following such modifications constitutes acceptance of the revised Terms.",
      },
    ],
  },
  {
    sectionTitle: "ACCOUNT REGISTRATION",
    content: [
      {
        type: "paragraph",
        text: "Users may access the Website by becoming a registered member and creating an account. Users agree to be responsible for maintaining the confidentiality of their passwords or other account identifiers and all activities that occur under their account. By registering on the Website, Users agree to:",
      },
      {
        type: "list",
        items: [
          "Maintain the confidentiality of their account credentials;",
          "Refrain from sharing account access with any third party;",
          "Abstain from creating accounts for abusive purposes or impersonation;",
          "Promptly notify the Company of any unauthorized use of their account",
        ],
      },
    ],
  },
  {
    sectionTitle: "USAGE",
    content: [
      {
        type: "paragraph",
        text: "Users acknowledge that, as between the Company and Users, the Company is the sole owner, authorized rights holder, or the licensee of all content on the Website, including, without limitation, all applicable copyrights, patents, trademarks, trade secrets, trade names, logos, and other intellectual property rights thereto, as well as text, images, graphics, logos, audio, video, URLs, metadata and other material appearing on the Website (“Company Content”).",
      },
      {
        type: "paragraph",
        text: "The Website and the Company Content are protected by the copyright laws and other intellectual property laws of India and by implication all the analogous laws of the world, including being protected globally by the applicable international copyright treaties.",
      },
      {
        type: "paragraph",
        text: "Users are granted a limited, revocable, non-exclusive, non-transferable license to solely access and view the Website and Company Content. This license does not include any rights to download, store, copy, or distribute any Company Content without the Company’s prior written approval.",
      },
      {
        type: "paragraph",
        text: "Users shall not (and shall also not facilitate, authorize or permit the use of) exploit, download, store, share, copy, scrape, reproduce, alter, modify, collect, mine and/or extract the Website and/or any Company Content (including, any caption information, keywords or other associated metadata) for any purpose without the Company’s prior written approval including without limitation:",
      },
      {
        type: "list",
        items: [
          "for any machine learning, machine learning language models and/or artificial intelligence-related purposes (including the training or development of such technologies or to generate any data or content or to synthesize or combine with any other data or content);",
          "for any text and data aggregation, analysis or mining purposes (including to generate any patterns, trends or correlations);",
          "for any personal or commercial use",
        ],
      },
      {
        type: "paragraph",
        text: "Other than as expressly set out in these Terms, or otherwise approved in writing by the Company, Users shall also not use, or facilitate, authorize or permit the use of, any (i) “robot”, “bot”, “spider”, “scraper”, “crawler” or (ii) other automated device, program, technique, tool, process, algorithm or method (whether for data gathering, mining, collection, reading, scraping, extraction or other purposes), in each case, on or in relation to the Website and/or the Company Content. Users shall not use any service, software, device, tool, method, program, technique, algorithm or process to circumvent any restriction, condition, protection or technological measure that manages or controls access to the Company Content in any way, including bypassing or circumventing any access controls or use limits of the Company Content.",
      },
      {
        type: "paragraph",
        text: "The Company may elect to act as a facilitator to assist in communications between Users and third-party content providers, licensors, or other relevant parties (“Third Party”/“Third Parties”) to facilitate mutually agreeable transactions related to the Company Content available on/through the Company’s Services. Users acknowledge that by agreeing to these Terms the Users confirm the Company as the exclusive facilitator for any such potential deals/transactions with any Third Parties in respect of any part or whole of the Company Content available on/through the Company’s Services and accordingly agree and undertake to not directly approach any Third Party for any revenue-generating or content exploitation deals related to the Company Content accessed through the Company’s Services, without the involvement of and the express prior written approval of the Company at each instance. Further, if any Third Party approaches Users directly for such deals, Users shall immediately direct all such revenue or exploitation conversations/discussions to the Company. Users acknowledge that the aforesaid role of the Company as the exclusive facilitator in respect of the Company Content is an integral part of the Company’s Services and entitlements resulting in revenues and business for the Company, and accordingly any breach or non-compliance or circumvention of the terms as stated herein by the Users shall be deemed a breach of these Terms by the Users and the Users taking cognizance of the same agree to keep the Company involved in and further route all such discussions/communications with the relevant Third Parties only through the Company, unless specifically waived in writing by the Company.",
      },
      {
        type: "paragraph",
        text: "If Users wish to use the Company Content other than as permitted by these Terms, they should contact the Company at support@hooprsmash.com",
      },
    ],
  },
  {
    sectionTitle: "PAYMENT",
    content: [
      {
        type: "paragraph",
        text: "Upon accessing and using the Website, Users may obtain licenses for musical content subject to your acceptance of the applicable license agreement, completion of required payments, and compliance with all Terms and conditions. The Website offers various licensing options and plans, details of which can be found at [INSERT LINK].",
      },
      {
        type: "paragraph",
        text: "The Company may elect to act as a facilitator to assist in communications between Users and third-party content providers, licensors, or other relevant parties (“Third Party”/“Third Parties”) to facilitate mutually agreeable transactions related to the Company Content available on/through the Company’s Services. Users acknowledge that by agreeing to these Terms the Users confirm the Company as the exclusive facilitator for any such potential deals/transactions with any Third Parties in respect of any part or whole of the Company Content available on/through the Company’s Services and accordingly agree and undertake to not directly approach any Third Party for any revenue-generating or content exploitation deals related to the Company Content accessed through the Company’s Services, without the involvement of and the express prior written approval of the Company at each instance. Further, if any Third Party approaches Users directly for such deals, Users shall immediately direct all such revenue or exploitation conversations/discussions to the Company. Users acknowledge that the aforesaid role of the Company as the exclusive facilitator in respect of the Company Content is an integral part of the Company’s Services and entitlements resulting in revenues and business for the Company, and accordingly any breach or non-compliance or circumvention of the terms as stated herein by the Users shall be deemed a breach of these Terms by the Users and the Users taking cognizance of the same agree to keep the Company involved in and further route all such discussions/communications with the relevant Third Parties only through the Company, unless specifically waived in writing by the Company.",
      },
    ],
  },
  {
    sectionTitle: "INTELLECTUAL PROPERTY; LIMITED LICENSE",
    content: [
      {
        type: "paragraph",
        text: "The Company remains the sole owner of all right, title, and interest in the Website and reserves all rights not expressly granted under these Terms. The Company may modify, replace, or discontinue the Website or any part thereof at any time, for any reason, with or without notice, in the Company’s sole discretion. The Company provides the Website on an “as is” and “as available” basis.",
      },
      {
        type: "paragraph",
        text: "All content on this Website, including but not limited to the Company Content, as well as the selection and arrangement of the Company Content, are protected by copyright, trademark, patent, trade secret and other intellectual property laws and treaties. Any unauthorized use of any Company Content violates such laws and the Terms. Except as expressly provided herein or in a separate agreement between Users and the Company, Company does not grant any express or implied permission to use the Website or any Company Content. Users agree not to copy, republish, frame, link to, download, transmit, modify, adapt, create derivative works based on, rent, lease, loan, sell, assign, distribute, display, perform, license, sublicense or reverse engineer the Website or any Company Content.",
      },
      {
        type: "paragraph",
        text: "Unless Users enter into a separate agreement with the Company, they may not download, distribute, display and/or copy any Company Content.",
      },
      {
        type: "paragraph",
        text: "All Company Content included in or made available through Company is the owned and/or licensed property of Company or its content suppliers and protected by Indian Copyright Act, 1957 (as amended) and all analogous laws of the world. All trademarks, graphics, logos, page headers, buttons, icons, scripts, and service names included in or of Company are trademarks or trade dress of the Company. Company’s trademarks and trade dress may not be used in connection with any product or service that is not Company’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Company. All other trademarks not owned by Company but available via the Website, Content or Services are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Company.",
      },
    ],
  },
  {
    sectionTitle: "LIMITATION",
    content: [
      {
        type: "paragraph",
        text: "Users agree that they shall not:",
      },
      {
        type: "list",
        items: [
          "Engage in any conduct that shall constitute a violation of any law or that infringes the rights of the Company or any third party.",
          "Violate any applicable laws or regulations related to the access to or use of the Website, or engage in any activity prohibited by these Terms.",
          "Violate the rights of the Company or any third party (including rights of privacy and publicity) or abuse, defame, harass, stalk or threaten another.",
          "Engage in or facilitate any form of piracy, including but not limited to, the unauthorized reproduction, to download, store, copy, distribution, access, modification, public performance, or public display of any Company Content or third-party content.",
          "Use any device, software, or routine to interfere or attempt to interfere with the proper functioning of the Website, or take any action that imposes an unreasonable or disproportionately large load on the Website’s infrastructure.",
          "Circumvent, disable, or otherwise interfere with security-related features of the Website or features that enforce limitations on the use of the Website or Company Content.",
        ],
      },
    ],
  },
  {
    sectionTitle: "RESTRICTION AND TERMINATION OF USE",
    content: [
      {
        type: "paragraph",
        text: "The Company may block, restrict, disable, suspend, or terminate Users’ access to all or part of the Website and/or Company Content at any time at the Company’s discretion, without prior notice or liability to Users. Any conduct by Users that, in the Company’s sole discretion, restricts or inhibits any other person or entity from using or enjoying the Website is strictly prohibited and may result in the termination of Users’ access to the Website without further notice.",
      },
    ],
  },
  {
    sectionTitle: "THIRD PARTY LINKS AND SERVICES",
    content: [
      {
        type: "paragraph",
        text: "In the event that the Website is available through any third-party platform, or if the Company provides links from the Website to any third-party platform or permits any third party to link from its platform to the Website, Users understand and agree that the Company makes no warranty of any kind, express or implied, and accepts no responsibility for any content or practices of such third parties or their platforms. Such platforms are not under the control of the Company, and the Company provides and/or permits these links only as a convenience to Users. The inclusion of any link does not imply affiliation, endorsement, or adoption by the Company.",
      },
    ],
  },
  {
    sectionTitle: "LIMITATION OF LIABILITY AND DISCLAIMER OF WARRANTIES",
    content: [
      {
        type: "paragraph",
        text: "THE COMPANY WEBSITE, CONTENT OR SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, LINKS, PRODUCTS (INCLUDING APPLICATIONS/SOFTWARE), DATA, FEATURES, FUNCTIONALITY, TOOLS, RESOURCES, AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE/ACCESSIBLE TO USERS ON/THROUGH THE COMPANY WEBSITE, CONTENT OR SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE COMPANY WEBSITE, CONTENT OR SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, LINKS, PRODUCTS (INCLUDING APPLICATIONS/SOFTWARE), DATA, FEATURES, FUNCTIONALITY, TOOLS, RESOURCES, OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE/ACCESIBLE TO USERS ON/THROUGH THE COMPANY WEBSITE, CONTENT OR SERVICES, UNLESS OTHERWISE SPECIFIED IN WRITING. USERS EXPRESSLY AGREE THAT THEIR USE OF THE COMPANY WEBSITE, CONTENT OR SERVICES IS AT THEIR SOLE RISK.",
      },
      {
        type: "paragraph",
        text: "TO THE FULL EXTENT PERMISSIBLE BY LAW, COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. COMPANY DOES NOT WARRANT THAT THE COMPANY WEBSITE, CONTENT OR SERVICES, INFORMATION, CONTENT, MATERIALS, LINKS, PRODUCTS (INCLUDING APPLICATIONS/SOFTWARE), OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE/ACCESSIBLE TO USERS ON/THROUGH THE COMPANY WEBSITE, CONTENT OR SERVICES, COMPANY’S SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM COMPANY ARE FREE OF ANY MALWARE, TROJAN, VIRUSES OR OTHER ELEMENTS WHICH MAY BE HARMFUL TO YOUR DEVICES. TO THE FULL EXTENT PERMISSIBLE BY LAW, COMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY COMPANY SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, LINKS, PRODUCTS (INCLUDING APPLICATIONS/SOFTWARE), OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE/ACCESSIBLE TO USERS ON/THROUGH ANY COMPANY SERVICE, INCLUDING, BUT NOT LIMITED TO PUNITIVE, SPECIAL, INDIRECT, EXEMPLARY, CONSEQUENTIAL OR INCIDENTAL DAMAGES.",
      },
      {
        type: "paragraph",
        text: "SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO USERS. USERS ARE ADVISED TO CONSULT THE LAWS IN THEIR APPLICABLE JURISDICTION BEFORE USING THE COMPANY’S SERVICES.",
      },
      {
        type: "paragraph",
        text: "IN NO EVENT SHALL THE COMPANY OR ITS AFFILIATES BE LIABLE TO USERS OR ANY THIRD PARTY FOR ANY PUNITIVE, SPECIAL, INDIRECT, EXEMPLARY, CONSEQUENTIAL OR INCIDENTAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF USE, DATA OR PROFIT LOSS, WHETHER OR NOT THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THIS WEBSITE OR OF ANY WEBSITE REFERENCED OR LINKED TO FROM THIS WEBSITE WHETHER THE ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR OTHERWISE. IN THE EVENT THAT APPLICABLE LAW DOES NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR DAMAGES, USERS AGREE THAT IN NO EVENT SHALL THE TOTAL LIABILITY OF COMPANY TO USERS FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION OF ANY KIND EXCEED THE AMOUNT OF FEE PAID BY USERS TO THE COMPANY TILL THE DATE SUCH CAUSE OF ACTION OR CLAIM AROSE.",
      },
      {
        type: "paragraph",
        text: "FURTHER, THE COMPANY SHALL NOT BE LIABLE IN ANY WAY FOR THIRD PARTY PROMISES REGARDING CONTENT OR FOR ASSISTANCE IN CONDUCTING COMMERCIAL TRANSACTIONS WITH THE THIRD PARTY THROUGH THIS WEBSITE.",
      },
      {
        type: "paragraph",
        text: "SOME STATES OR JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO USERS. USERS ARE ADVISED TO CONSULT THE LAWS IN THEIR APPLICABLE JURISDICTION.",
      },
    ],
  },
  {
    sectionTitle: "DATA PROTECTION",
    content: [
      {
        type: "paragraph",
        text: "To find out what personal data the Company collects and how it uses it, Users should visit the Company’s privacy policy at https://hooprsmash.com/privacy. ",
      },
    ],
  },
  {
    sectionTitle: "CHANGES TO THESE TERMS AND CONDITIONS OF USE",
    content: [
      {
        type: "paragraph",
        text: "Users are advised that the Company may change these Terms from time to time at its sole discretion and reserves the right to do so without Users’ consent. Any revised Terms will be applicable at the time of posting on the Website. Users should ensure that they review these Terms regularly as they will be deemed to have accepted a variation if they continue to use the Website after it has been posted.",
      },
    ],
  },
  {
    sectionTitle: "MISCELLANEOUS",
    content: [
      {
        type: "paragraph",
        text: "Any controversy or claim arising out of or relating to this Agreement, or the breach thereof, shall be governed by the laws of India and shall be subject to the exclusive jurisdiction of the courts of Mumbai.",
      },
      {
        type: "paragraph",
        text: "In the event of any conflict between these Terms and any agreement Users enter with the Company, the terms of such agreement shall control.",
      },
      {
        type: "paragraph",
        text: "These Terms shall be construed neither against nor in favor of any party, but rather in accordance with the fair meaning of the language hereof. The invalidity or unenforceability of any part of these Terms shall not affect the validity or enforceability of the balance hereof.",
      },
    ],
  },
];

const TermsAndConditions = () => {
  return (
    <div className="outer" style={styles.container}>
      <div className="inner">
        <h1 style={{ fontSize: "36px" }}>Terms & Conditions</h1>
        {termsAndConditions.map((section, sectionIndex) => (
          <div key={sectionIndex} style={styles.section}>
            <h1 style={styles.sectionTitle}>{section.sectionTitle}</h1>
            {section.content.map((content, contentIndex) => {
              if (content.type === "paragraph") {
                return (
                  <p key={contentIndex} style={styles.paragraph}>
                    {content.text}
                  </p>
                );
              } else if (content.type === "list") {
                return (
                  <ul key={contentIndex} style={styles.list}>
                    {content.items.map((item, itemIndex) => (
                      <li key={itemIndex} style={styles.listItem}>
                        {item}
                      </li>
                    ))}
                  </ul>
                );
              }
              return null;
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Inter",
    padding: "20px",
    backgroundColor: "#fff",
    color: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "880px",
    marginTop: "70px",
  },
  section: {
    marginBottom: "40px",
  },
  sectionTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  paragraph: {
    fontSize: "16px",
    lineHeight: "1.5",
    marginBottom: "15px",
  },
  list: {
    paddingLeft: "20px",
    marginBottom: "20px",
  },
  listItem: {
    fontSize: "16px",
    lineHeight: "1.5",
    marginBottom: "5px",
  },
};

export default TermsAndConditions;
