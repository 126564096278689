import React from "react";
import {
  FaFacebook,
  FaEnvelope,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import "../styles/Footer.css";
import config from "../utils/config";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer outer-full">
      <div className="footer-container inner">
        {/* Left Section */}
        <div className="footer-left">
          <img
            src={config.s3imgUrl + "web/logos/smash.png"}
            // src={config.s3imgUrl + "web/logos/smash-xmas.png"}
            alt="Hoopr Smash Logo"
            className="footer-logo-img"
          />
          <p className="footer-description">
          Hoopr Smash is a game-changing music tech platform that makes licensing Bollywood’s hottest hits and independent artist tracks effortless and 100% legal. Designed for D2C brands, production houses, OTT platforms, and content studios, it offers seamless access to premium music, simplifying commercial licensing with ease and convenience.
          </p>
          <div className="footer-social">
            <a href="#" className="footer-icon fi-fb">
              <FaFacebook size={24} />
            </a>
            <a href="#" className="footer-icon fi-em">
              <FaEnvelope size={24} />
            </a>
            <a href="#" className="footer-icon fi-ig">
              <FaInstagram size={24} />
            </a>
            <a href="#" className="footer-icon fi-li">
              <FaLinkedin size={24} />
            </a>
            <a href="#" className="footer-icon fi-yt">
              <FaYoutube size={24} />
            </a>
          </div>
        </div>

        {/* Links Section */}
        <div className="footer-links">
          <div className="footer-column">
            {/* <h4 className="footer-heading">Explore</h4>
            <ul>
            <Link to={"/moods"}>
              <li>
                <a>Moods</a>
              </li>
              </Link>
              <Link to={"/genres"}>
              <li>
                <a>Genres</a>
              </li>
              </Link>
              <Link to={"/categories"}>
              <li>
                <a>Categories</a>
              </li>
              </Link>
              <Link to={"/packs"}>
              <li>
                <a>Packs</a>
              </li>
              </Link>
              <Link to={"/tracks"}>
              <li>
                <a>Tracks</a>
              </li>
              </Link>
            </ul> */}
          </div>
          <div className="footer-column">
          <h4 className="footer-heading">Explore</h4>
            <ul>
            <Link to={"/moods"}>
              <li>
                <a>Moods</a>
              </li>
              </Link>
              <Link to={"/genres"}>
              <li>
                <a>Genres</a>
              </li>
              </Link>
              <Link to={"/categories"}>
              <li>
                <a>Categories</a>
              </li>
              </Link>
              <Link to={"/packs"}>
              <li>
                <a>Packs</a>
              </li>
              </Link>
              <Link to={"/playlists"}>
              <li>
                <a>Playlists</a>
              </li>
              </Link>
              <Link to={"/tracks"}>
              <li>
                <a>Tracks</a>
              </li>
              </Link>
            </ul>
            {/* <h4 className="footer-heading">License & User Terms</h4>
            <ul>
              <li>
                <a href="#">User Guidelines</a>
              </li> */}
              {/* <li>
                <a href="#">Claims Clearance</a>
              </li> */}
              {/* <Link to={"/terms"}>
                <li>
                  <a>Terms & Conditions</a>
                </li>
              </Link>
              <Link to={"/privacy"}>
                <li>
                  <a>Privacy Policy</a>
                </li>
              </Link>
            </ul>
            <br /> */}
            <h4 className="footer-heading">Our Brands</h4>
            <ul>
              <li>
                <a href="https://hoopr.ai" target="_blank">hoopr.ai</a>
              </li>
            </ul>

          </div>
          <div className="footer-column">
          <h4 className="footer-heading">License & User Terms</h4>
            <ul>
              {/* <li>
                <a href="#">User Guidelines</a>
              </li>
              <li>
                <a href="#">Claims Clearance</a>
              </li> */}
              <Link to={"/terms"}>
                <li>
                  <a>Terms & Conditions</a>
                </li>
              </Link>
              <Link to={"/privacy"}>
                <li>
                  <a>Privacy Policy</a>
                </li>
              </Link>
            </ul>
            <br />
            <h4 className="footer-heading">Help & Support</h4>
            <ul>
              {/* <li>
                <a href="#">About Hoopr Smash</a>
              </li> */}
              <Link to={"/faqs"}>
                <li>
                  <a href="#">Frequently Asked Questions</a>
                </li>
              </Link>
              {/* <li>
                <a href="#">Customer Support</a>
              </li> */}
              <li>
                <a href="https://blog.hooprsmash.com" target="_blank">Blog</a>
              </li>
              {/* <li>
                <a href="#">Request a Song</a>
              </li> */}
              {/* <li>
                <a href="#">Request a Quote</a>
              </li> */}
              {/* <li>
                <a href="#">How it Works</a>
              </li> */}
              {/* <li>
                <a href="#">Contact Us</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
