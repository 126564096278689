const getDirectLink = (sourceLink) => {
  if (!sourceLink) return "";
  // Convert Dropbox link to direct link and remove unnecessary parameters
  // console.log(
  //   "Direct Link:",
  //   sourceLink
  //     .replace("www.dropbox.com", "dl.dropboxusercontent.com")
  //     .replace("?dl=0", "")
  // );
  return sourceLink
    .replace("www.dropbox.com", "dl.dropboxusercontent.com")
    .replace("?dl=0", "");
};

export default getDirectLink;
