import { useContext } from "react";
import { ToastContext } from "./ToastContext";

// Custom hook to handle sharing logic
export const useHandleShareClick = () => {
  const { showToast } = useContext(ToastContext); // Accessing showToast function from context

  const handleShareClick = () => {
    try {
      // const currentUrl = window.location.href; // Get the current URL
      // navigator.clipboard.writeText(currentUrl).then(() => {
      //   // On success, show a success toast
      //   showToast("Copied", "URL copied to clipboard", "success");
      // });
    } catch (error) {
      // On failure, show an error toast
      // showToast("Error", "Failed to copy the URL. Please try again.", "error");
      //   console.error("Failed to copy URL to clipboard:", error);
    }
  };

  return { handleShareClick };
};
