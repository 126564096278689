import React, { useState, useEffect, useRef, useContext } from "react";
import "../styles/TrackPage.css";
import config from "../utils/config";
import { FaPlay, FaPause, FaPlus } from "react-icons/fa";
import CustomButton from "../components/CustomButton/CustomButton";
import { FaRegHeart } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import { BiAddToQueue } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { RiShoppingCart2Line } from "react-icons/ri";
import { IoIosArrowDown, IoIosClose } from "react-icons/io";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useParams } from "react-router-dom";
import { getTrack } from "../service/TrackService";
import PackCard from "../components/CardsComponent/PackCard/PackCard";
import { useGlobalPlayer } from "../utils/GlobalPlayerContext";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { Link } from "react-router-dom";
import getDirectLink from "../utils/AudioUrlFormated";
import RequestLicense from "./RequestLicense";
import { addToCart } from "../utils/redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContext } from "../utils/ToastContext";
import CleanSearchKey from "../utils/CleanSearchKey";
import { trackInPack } from "../service/Packs";
import { similarTracks } from "../service/Tracks";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import { useHandleShareClick } from "../utils/Share";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "../utils/firebase/remoteConfig";
import {
  trackLike,
  createPlaylist,
  addTrackToPlaylist,
  getUserPlaylist,
} from "../service/UserService";
import { getCookie } from "../utils/AuthContext";
import { LuPlus } from "react-icons/lu";
import setMetaTags from "../utils/SeoService";
import { putCart } from "../service/TransactionService";

const TrackPage = () => {
  const [openCards, setOpenCards] = useState([true, false, false]); // state to manage the cards opening state
  const [trackData, setTrackData] = useState([]); // state to hold the fetched track data
  const [trackMaxUsage, setTrackMaxUsage] = useState("2"); // short form video max usage for a track
  const [isRequestOpen, setIsRequestOpen] = useState(false); // toggle cart modal
  const [licenseData, setIsLicenseData] = useState(null); // state to manage the request quote data for request license modal
  const dispatch = useDispatch(); // Hook to dispatch actions
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const [tracksInPack, setPackCards] = useState(null);
  const [simTracks, setSimTracks] = useState([]);
  const [useCaseTracks, setUseCaseTracks] = useState([]);
  const [artistTracks, setArtistTracks] = useState([]);
  const [eventTracks, setEventTracks] = useState([]);
  const { handleShareClick } = useHandleShareClick(); // Use the custom hook
  const userPhoneNumber = getCookie("userPhoneNumber"); // Get the user's phone number from cookies to identify the user
  const { trackCode } = useParams(); // gettign the data from navigated route
  const [createPlaylistPopup, setCreatePlaylistPopup] = useState(false); // state to display popup of create playlist
  const [createInputPlaylist, setCreateInputPlaylist] = useState(false); // state to manage to display the input box for create playlist
  const [playlists, setPlaylists] = useState([]); // State for managing the existing user playlist info
  const [inputPlaylistValue, setInputPlaylistValue] = useState(""); // state to manage the input value of the new playlist create value

  // Destructure the context
  const { isPlaying, setIsPlaying, showPlayer } = useGlobalPlayer();

  const items = useSelector((state) => state.cart.items); // Redux selector

  // Utility to fetch item quantity from Redux state
  const getItemQuantity = (trackCode) =>
    items.find((item) => item.code === trackCode)?.quantity || 0;

  useEffect(() => {
    const fetchPacks = async () => {
      try {
        let packCards = await trackInPack(trackCode);
        packCards = packCards.slice(0, 3);
        setPackCards(packCards);
      } catch (error) {
        console.log(error, "error");
      }
    };
    fetchPacks();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTrack(trackCode);
        const data = response.data || [];
        // console.log(data, "data");
        // console.log(data.track.SKU[0].maxUsage);
        setTrackMaxUsage(data.track.SKU[0].maxUsage);
        setTrackData(data.track); // Set the track object in the state
        // console.log(data, "trackData");
        const seoData = {
          title: `${data.track.name} | Song License | Hoopr Smash`,
          description: `License ${data.track.name} now on Hoopr Smash. Buy now & access to our comprehensive royalty free music for better content engagement.`,
          ogImage: `${config.s3imgUrl}web/tracks/${data.track.trackCode}.webp`,
          canonical: window.location.href,
          // lang: "en",
        };
        setMetaTags(seoData);
      } catch (error) {
        console.error("Error fetching track data:", error);
      }
    };
    fetchData();
  }, [trackCode]); // calling the function when the trackCode changes

  // function for toggling the cards
  const toggleContainer = (index) => {
    setOpenCards((prevOpenCards) => {
      // Create a new array with the updated open states
      const newOpenCards = prevOpenCards.map((isOpen, i) =>
        i === index ? !isOpen : false
      );
      return newOpenCards;
    });
  };

  useEffect(() => {
    let genreSet = trackData?.filters?.subFilters?.subgenre[0].name;
    let moodSet = trackData.filters?.mood[0].name;
    let catSet = trackData?.filters?.usecase?.[1]?.name || "";
    // console.log(genreSet, "Genre");

    async function fetchData() {
      if (genreSet) {
        genreSet = await CleanSearchKey(genreSet);
        moodSet = await CleanSearchKey(moodSet);
        catSet = await CleanSearchKey(catSet);

        let payload = {
          asset_type: "music",
          artists: [],
          bpm: {},
          genres: [],
          languages: [],
          moods: [moodSet],
          playlists: [],
          sfxcategories: [],
          sfxsubcategories: [],
          subgenres: [],
          usecases: [catSet],
        };

        const trackData = await similarTracks(payload);
        setSimTracks(trackData);
        // console.log(trackData, "simTracks");
      }
    }
    fetchData();
  }, [trackData]);

  useEffect(() => {
    // let catSet = trackData.filters?.usecase[1].name;
    let catSet = trackData?.filters?.usecase?.[1]?.name || "";

    // console.log(genreSet, "Genre");

    async function fetchData() {
      if (catSet) {
        catSet = await CleanSearchKey(catSet);

        let payload = {
          asset_type: "music",
          artists: [],
          bpm: {},
          genres: [],
          languages: [],
          moods: [],
          playlists: [],
          sfxcategories: [],
          sfxsubcategories: [],
          subgenres: [],
          usecases: [catSet],
        };

        const trackData = await similarTracks(payload);
        setUseCaseTracks(trackData);
      }
    }
    fetchData();
  }, [trackData]);

  useEffect(() => {
    let artists =
      trackData.primaryArtists?.map((artist) => artist.artistName).join(", ") ||
      "";

    async function fetchData() {
      if (artists) {
        // artists = await CleanSearchKey(artists);

        let payload = {
          asset_type: "music",
          artists: [],
          bpm: {},
          genres: [],
          languages: [],
          moods: [],
          playlists: [],
          sfxcategories: [],
          sfxsubcategories: [],
          subgenres: [],
          usecases: [],
        };

        const trackData = await similarTracks(payload, artists);
        setArtistTracks(trackData);
      }
    }
    fetchData();
  }, [trackData]);

  useEffect(() => {
    let events = trackData.events?.map((event) => event).join(", ") || "";
    // console.log(events, "187");

    async function fetchData() {
      if (events) {
        events = await CleanSearchKey(events);

        let payload = {
          asset_type: "music",
          artists: [],
          bpm: {},
          genres: [],
          languages: [],
          moods: [],
          playlists: [],
          sfxcategories: [],
          sfxsubcategories: [],
          subgenres: [],
          usecases: [],
        };

        const trackData = await similarTracks(payload, events);
        // console.log(trackData, "event tracks data");

        setEventTracks(trackData);
      }
    }
    fetchData();
  }, [trackData]);

  // trackData.primaryArtists
  // ?.map((artist) => artist.artistName)
  // .join(", ") || ""

  const aboutSong = [
    {
      title: "About",
      description: trackData.description || "",
    },
    {
      title: "Song Credits",
      description: `From "${trackData.albumDetails?.title || ""}" by ${
        trackData.primaryArtists
          ?.map((artist) => artist.artistName)
          .join(", ") || ""
      } ${
        trackData.trackRoleArtists
          ?.map((artist) => artist.artistName)
          .join(", ")
          ? "ft. " +
            trackData.trackRoleArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : ""
      } | ${trackData.releaseYear || ""} | ${trackData.ownerName || ""}.`,
    },
    {
      title: "Type",
      description: trackData.type
        ? trackData.type.charAt(0).toUpperCase() + trackData.type.slice(1)
        : "",
    },
    {
      title: "Category",
      pills: trackData.filters?.usecase?.map((usecase) => usecase.name) || [], // Map mood from filters
    },
    {
      title: "Genres",
      pills: trackData.filters?.subFilters?.subgenre?.map(
        (genre) => genre.name
      ) || [""], // Map genre from filters
    },
    {
      title: "Events",
      pills: trackData.events?.map((events) => events) || [""], // Map instrument as events
    },
    {
      title: "Language",
      pills: trackData.filters?.language?.map((language) => language.name) || [
        "",
      ], // Map language from filters
    },
  ];

  const tracksBenefits = [
    {
      image: `${config.s3imgUrl}icons/immediatedownload.png`,
      title: "Immediate Download",
    },
    {
      image: `${config.s3imgUrl}icons/copyrightshield.png`,
      title: "100% Copyright Safe",
    },
    {
      image: `${config.s3imgUrl}icons/copyright.png`,
      title: "Zero-claims Guarantee",
    },
    {
      image: `${config.s3imgUrl}icons/globalnetwork.png`,
      title: "Worldwide Clearance",
    },
    {
      image: `${config.s3imgUrl}icons/chat.png`,
      title: "Dedicated Support Team",
    },
  ];

  // const pricingBuyPackCards = [
  //   {
  //     image: `${config.s3imgUrl}trackimages/sampleimage.png`,
  //     title: "Happy Diwali",
  //     buttonText: "Explore Packs",
  //     buttonLink: "#",
  //     totalTracks: 10,
  //   },
  //   {
  //     image: `${config.s3imgUrl}trackimages/sampleimage.png`,
  //     title: "Happy Diwali",
  //     buttonText: "Explore Packs",
  //     buttonLink: "#",
  //     totalTracks: 12,
  //   },
  // ];

  const shortPros = {
    // pros: [
    //   `${trackMaxUsage} use instances allowed`,
    //   "Quick clearance on submission of usage links",
    //   "Clearance only for IG Reels and YT Shorts",
    //   "Publish content within license validity duration",
    // ],
    pros: [
      "Clearance only for IG Reels and YT Shorts",
      `Allowed to use in only ${trackMaxUsage} videos`,
      // "Quick clearance on submission of usage links",
      // "Content to be published within 12 months of purchase",
      "Cleared for only one brand/entity",
    ],
    // cons: [
    //   "No clearance for long form content, OTT and broadcast",
    //   "No clearance for DVC and paid ads",
    //   "No clearance for influencer campaigns",
    // ],
    cons: [
      "Not allowed to use in long-form videos",
      // "Not allowed to use on OTT, broadcast (TV, video)",
      "Not allowed to use on OTT and broadcast",
      // "Not allowed for influencer activities on any platform",
      "No influencer use allowed on any platform",
      // "Not allowed to create derivatives or use in any derogatory or defamatory content",
      // "Not allowed to use entire song to create a music video",
    ],
    separate: [
      "Any of the above usage will require you to get in touch with our music team.",
    ],
  };

  // function for play pause of the track
  const togglePlay = () => {
    // getting the data for the global player

    const trackInfo = {
      trackImg: trackData.imageUrl,
      trackName: trackData.name,
      trackArtistName:
        trackData.primaryArtists && trackData.primaryArtists.length > 0
          ? trackData.primaryArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : "Artist Name",
      trackDuration: "03:25",
      trackCode: trackData.trackCode,
      trackUrl: trackData.mp3Link || getDirectLink(trackData.link),
      waveJson: trackData.waveformLink,
    };
    // console.log(trackInfo, "trackinfo");

    // Show player if it's not already playing this track
    if (!isPlaying) {
      showPlayer(trackInfo);
    }

    // Toggle play/pause state
    setIsPlaying(!isPlaying);
  };

  // function to handle the request quote of the track
  const handleRequestQuote = () => {
    const licenseData = {
      title: trackData.name,
      subTitle:
        trackData.primaryArtists && trackData.primaryArtists.length > 0
          ? trackData.primaryArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : "",
      // img: trackData.imageUrl,
      img: config.s3imgUrl + "web/tracks/" + trackData.trackCode + ".webp",
      // trackCode: trackData.trackCode
    };
    setIsLicenseData(licenseData);
    // console.log(licenseData, "license data");
    setIsRequestOpen(true);
  };

  // fucntion to add the track in the cart
  const handleCartClick = async () => {
    const cartInfo = {
      cardType: "track", // card type to display
      preText: trackData.name, // track name
      secText:
        trackData.primaryArtists && trackData.primaryArtists.length > 0
          ? trackData.primaryArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : "", // artist name
      code: trackData.trackCode, // track code
      sp: trackData.SKU?.[0]?.sellingPrice.toLocaleString(), // selling price
      cp: trackData.SKU?.[0]?.costPrice.toLocaleString(), // cost price
      cardImg: trackData.imageUrl, // card img based on track
      trackJson: trackData.waveformLink,
      trackUrl: trackData.mp3Link || getDirectLink(trackData.link),
      skuID: trackData.SKU?.[0]?.id,
    };
    // console.log(cartInfo);

    // Get the current quantity for the track
    const currentQuantity = getItemQuantity(trackData.trackCode);

    const updatedQuantity = currentQuantity + 1; // Increment quantity
    // console.log("Updated Quantity:", updatedQuantity);

    // preparign the payload for api call
    const payload = {
      items: [
        { skuId: cartInfo.skuID || "", qty: updatedQuantity, cartType: "C" },
      ],
    };

    const addCart = await putCart(payload);
    // console.log(addCart, "cart add");
    if (addCart?.error?.code === 0) {
      // Dispatch action to add the trak in the redux
      dispatch(addToCart(cartInfo));
      showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg
    } else {
      showToast("Added to cart", "Failed to update the cart", "error"); //display toast msg
    }
  };

  // fucntion to add the track to the user my likes
  const handleTrackLike = async () => {
    // console.log(trackData.trackCode, "trackData");

    const payload = {
      trackCode: trackData.trackCode,
    };
    await fetchAndActivate(remoteConfig); // Fetch and activate Remote Config
    const jsonString = getValue(remoteConfig, "auth").asString(); // Fetch the auth config
    const parsedConfig = JSON.parse(jsonString);
    // console.log(parsedConfig, "parsedConfig");
    // Find the object where the phone number matches
    const matchingEntry = Object.entries(parsedConfig).find(
      ([phoneNumber, userData]) => phoneNumber === userPhoneNumber
    );

    if (matchingEntry) {
      const [phoneNumber, userData] = matchingEntry;
      // console.log(
      //   `Matching entry found for phone number: ${phoneNumber}`,
      //   userData.token
      // );
      const trackLikeResponse = await trackLike(payload, userData.token);
      // console.log(packLikeResponse, "packLikeResponse");
      if (trackLikeResponse.data.status === true) {
        showToast("Like Added", "Added to liked Tracks", "success"); //display toast msg
      } else if (trackLikeResponse.data.status === false) {
        showToast("Like Removed", "Removed from liked Tracks", "success");
      } else {
        showToast("Error", "Something went wrong", "error");
      }
      // showToast("Like Added", "Added to liked Packs", "success"); //display toast msg
      // Do something with userData, such as using the token or userId
    } else {
      console.log(
        `No matching entry found for phone number: ${userPhoneNumber}`
      );
    }
  };

  // fucntion handle the track addition in a playlist
  const handleAddTrackPlaylist = async () => {
    try {
      await fetchAndActivate(remoteConfig); // Fetch and activate Remote Config
      const jsonString = getValue(remoteConfig, "auth").asString(); // Fetch the auth config.
      const parsedConfig = JSON.parse(jsonString);

      // Find the object where the phone number matches
      const matchingEntry = Object.entries(parsedConfig).find(
        ([phoneNumber, userData]) => phoneNumber === userPhoneNumber
      );

      if (matchingEntry) {
        const [, userData] = matchingEntry; // comma not to remove for temporary purpose

        const userPlaylist = await getUserPlaylist(userData.token);
        // console.log(userPlaylist, "34");

        if (userPlaylist?.info?.status === 200) {
          // console.log(userPlaylist, "user playlist");
          setPlaylists(userPlaylist.data.playlist);
          setCreatePlaylistPopup(true);
        } else {
          console.warn("No playlists found in the response.");
        }
      } else {
        console.warn("No matching user data found for phone number.");
      }
    } catch (error) {
      console.error("Error fetching user playlist:", error);
    }
  };

  // fucntion to handle the track addition in new playlist
  const createNewPlaylist = async () => {
    // console.log(inputPlaylistValue, "input value of new playlist name");

    // preparing the payload
    const payload = {
      name: inputPlaylistValue,
    };

    await fetchAndActivate(remoteConfig); // Fetch and activate Remote Config
    const jsonString = getValue(remoteConfig, "auth").asString(); // Fetch the auth config
    const parsedConfig = JSON.parse(jsonString);
    // console.log(parsedConfig, "parsedConfig");
    // Find the object where the phone number matches
    const matchingEntry = Object.entries(parsedConfig).find(
      ([phoneNumber, userData]) => phoneNumber === userPhoneNumber
    );

    if (matchingEntry) {
      const [phoneNumber, userData] = matchingEntry;
      // console.log(
      //   `Matching entry found for phone number: ${phoneNumber}`,
      //   userData.token
      // );

      // for creating playlists

      const createResponse = await createPlaylist(payload, userData.token);
      // console.log(createResponse, "createResponse");
      if (createResponse?.data?.playlist?.id) {
        const playlist = {
          id: createResponse?.data?.playlist?.id,
        };
        // the playlist is being created now call the fcuntion to add the track in existing playlist
        onPlaylistClick(playlist);
        // showToast(
        //   "Playlist Created",
        //   `Added to ${createResponse?.data?.playlist?.name}`,
        //   "success"
        // ); //display toast msg
      } else {
        showToast("Like Added", "Fail to create Playlist", "error"); //display toast msg
      }
    }
  };

  // fucntion to handle the track addition in existing playlist
  const onPlaylistClick = async (playlist) => {
    // console.log(playlist);
    // preparign the payload
    const payload = {
      tracks: [trackData.trackCode],
    };
    // preparign the playlist id for the track to be added in. Note this is being pass as params
    const playlistId = playlist.id;

    // console.log(payload, "payload");

    await fetchAndActivate(remoteConfig); // Fetch and activate Remote Config
    const jsonString = getValue(remoteConfig, "auth").asString(); // Fetch the auth config
    const parsedConfig = JSON.parse(jsonString);
    // console.log(parsedConfig, "parsedConfig");
    // Find the object where the phone number matches
    const matchingEntry = Object.entries(parsedConfig).find(
      ([phoneNumber, userData]) => phoneNumber === userPhoneNumber
    );

    if (matchingEntry) {
      const [phoneNumber, userData] = matchingEntry;
      // console.log(
      //   `Matching entry found for phone number: ${phoneNumber}`,
      //   userData.token
      // );

      // for adding track to playlists
      const addtrackResponse = await addTrackToPlaylist(
        payload,
        userData.token,
        playlistId
      );
      // console.log(addtrackResponse, "506");
      if (addtrackResponse?.data?.playlistName) {
        // close the playlist popup modal
        handleClosePlaylistPopup();
        showToast(
          "Track Added",
          `${trackData.name} add to ${addtrackResponse?.data?.playlistName}`,
          "success"
        ); //display toast msg
      } else {
        showToast("Track Added", `Failed to add`, "error"); //display toast msg
      }
    } else {
      console.log(
        `No matching entry found for phone number: ${userPhoneNumber}`
      );
    }
  };

  // fucntion to handle the playlist popup modal close
  const handleClosePlaylistPopup = () => {
    setCreateInputPlaylist(false);
    setPlaylists([]);
    setInputPlaylistValue("");
    setCreatePlaylistPopup(false);
  };

  return (
    <div className="outer-full">
      {/* rendering the create playlist ui popup */}
      {createPlaylistPopup && (
        <div className="create-playlistContainer">
          <div className="cp-content">
            <div className="popup-heading">
              <p className="pheadingText">Add to playlist</p>
              <IoIosClose
                onClick={handleClosePlaylistPopup}
                size={30}
                cursor="pointer"
              />
            </div>
            <div className="existing-playlistsection">
              {playlists.map((playlist) => (
                <div
                  key={playlist.id}
                  className="existing-playlistContainer"
                  onClick={() => onPlaylistClick(playlist)} // Pass the clicked playlist info
                >
                  <div
                    style={{
                      backgroundImage: `url(${randomDefaultImage("pack")})`,
                    }}
                    className="playlist-imagecard"
                  ></div>
                  <div className="playlist-heading">
                    <p>
                      {playlist.name}
                      {""}
                    </p>
                    <p>Tracks</p>
                  </div>
                </div>
              ))}
            </div>
            {createInputPlaylist && (
              <>
                <div>
                  <input
                    placeholder="Name of the playlist"
                    className="cp-input"
                    value={inputPlaylistValue}
                    onChange={(e) => setInputPlaylistValue(e.target.value)}
                  />
                </div>
                <div>
                  <CustomButton
                    text="Create Playlist"
                    backgroundColor="#C6349B"
                    textColor="#fff"
                    fontWeight="600"
                    width="337px"
                    height="39px"
                    borderRadius="50px"
                    fontSize="14px"
                    onClick={createNewPlaylist}
                  />
                </div>
              </>
            )}
            {createInputPlaylist === false && (
              <div>
                <CustomButton
                  text="New Playlist"
                  backgroundColor="#C6349B"
                  textColor="#fff"
                  fontWeight="600"
                  width="337px"
                  height="39px"
                  borderRadius="50px"
                  fontSize="14px"
                  iconColor="#fff"
                  icon={<LuPlus />}
                  onClick={() => setCreateInputPlaylist(true)}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {/* main ui of the track page starts from here */}
      <div className="outer-full trackDetails">
        {/* track name and details section */}
        <div className="inner mbot">
          <div className="track-artist-info">
            <div className="track-details-container">
              {/* track image */}
              <div
                className="track-image"
                style={{
                  // backgroundImage:
                  //   trackData.imageUrl != "" && trackData.imageUrl != null
                  //     ? `url(${trackData.imageUrl})`
                  //     : `url(${randomDefaultImage("track")})`,
                  backgroundImage: `url(${
                    config.s3imgUrl +
                      "web/tracks/" +
                      trackData.trackCode +
                      ".webp" || randomDefaultImage("track")
                  })`,
                }}
              >
                {/* card play button */}
                <div>
                  <CustomButton
                    isIconOnly={true}
                    backgroundColor="#fff"
                    borderRadius="50px"
                    iconColor="#000"
                    icon={isPlaying ? <FaPause /> : <FaPlay />} // using the context for managing the btn state
                    width="52px"
                    height="49px"
                    onClick={togglePlay}
                  />
                </div>
              </div>
              {/* track and artist details */}
              <div className="track-details">
                <div className="track-names">
                  <h1>{trackData.name}</h1>
                  <h2>
                    {trackData.primaryArtists &&
                    trackData.primaryArtists.length > 0
                      ? trackData.primaryArtists.map((artist, index) => (
                          <React.Fragment key={artist.artistName}>
                            <Link
                              to={`/search?searchKeyword=${encodeURIComponent(
                                artist.artistName
                              )}`}
                            >
                              {artist.artistName}
                            </Link>
                            {index < trackData.primaryArtists.length - 1 &&
                              ", "}
                          </React.Fragment>
                        ))
                      : ""}
                    {/* {trackData.primaryArtists &&
                    trackData.primaryArtists.length > 0
                      ? trackData.primaryArtists
                          .map((artist) => artist.artistName)
                          .join(", ")
                      : "Artist Name"} */}
                    {/* {
                      trackData.primaryArtists &&
                      trackData.primaryArtists.length > 0
                      ? trackData.primaryArtists
                          .map((artist) =>
                          (
                            <Link to={`/search?searchKeyword=${artist.artistName}`}>
                              {artist.artistName},&nbsp;
                            </Link>
                          )
                        )
                        : ""
                    } */}
                  </h2>
                </div>
                <div className="track-pills">
                  {
                    // console.log(trackData.displayTags)
                    trackData.displayTags && trackData.displayTags.length > 0
                      ? trackData.displayTags.split(",").map((item, index) => (
                          <Link to={`/search?searchKeyword=${item}`}>
                            <div>
                              <CustomButton
                                text={item}
                                backgroundColor="rgba(49, 47, 47, 0.51)"
                                textColor="#ffffff"
                                fontFamily="Raleway"
                                fontWeight="550"
                                fontSize="14px"
                                padding="8px 12px"
                              />
                            </div>
                          </Link>
                        ))
                      : ""
                  }
                </div>
              </div>
            </div>
            {/* track like share button */}
            <div className="track-action-button">
              <div>
                <CustomButton
                  isIconOnly={true}
                  backgroundColor="rgba(49, 47, 47, 0.51)"
                  borderRadius="50px"
                  iconColor="#fff"
                  icon={<FaRegHeart />}
                  width="34px"
                  height="34px"
                  iconSize="16px"
                  onClick={handleTrackLike}
                />
              </div>
              <div>
                <CustomButton
                  isIconOnly={true}
                  backgroundColor="rgba(49, 47, 47, 0.51)"
                  borderRadius="50px"
                  iconColor="#fff"
                  icon={<FiShare2 />}
                  onClick={handleShareClick}
                  width="34px"
                  height="34px"
                  iconSize="16px"
                />
              </div>
              <div>
                <CustomButton
                  isIconOnly={true}
                  backgroundColor="rgba(49, 47, 47, 0.51)"
                  borderRadius="50px"
                  iconColor="#fff"
                  icon={<BiAddToQueue />}
                  width="34px"
                  height="34px"
                  iconSize="16px"
                  onClick={handleAddTrackPlaylist}
                />
              </div>
            </div>
          </div>
        </div>
        {/* tracks about and price section */}
        <div className="inner">
          {/* track about section */}
          <div className="fl about">
            {/* about song container */}
            <div className="about-song-container">
              <h1>About The Song</h1>
              {aboutSong
                .filter(
                  (item) =>
                    item.description?.trim() || // Non-empty description
                    (item.pills &&
                      item.pills.length > 0 &&
                      item.pills[0].trim()) // Non-empty pills
                )
                .map((item, index) => (
                  <div key={index} className="about-song-item">
                    <h2>{item.title}</h2>
                    {item.description ? (
                      <h3>{item.description}</h3>
                    ) : (
                      <div className="about-song-pills-container">
                        {item.pills.map((pill, pillIndex) => {
                          // Check the title and define routing logic
                          if (item.title === "Genres") {
                            // Navigate to genres with name and genreCode
                            const pillData =
                              trackData.filters?.genre?.[pillIndex];
                            return (
                              <Link
                                to={`/genres/${encodeURIComponent(
                                  CleanSearchKey(pill)
                                )}/${pillData?.id || ""}`}
                              >
                                <div
                                  key={pillIndex}
                                  className="about-song-pills"
                                >
                                  {pill}
                                </div>
                                //{" "}
                              </Link>
                            );
                          } else if (item.title === "Category") {
                            // Navigate to categories with name and categoryCode
                            const pillData =
                              trackData.filters?.usecase?.[pillIndex];
                            return (
                              <Link
                                to={`/categories/${encodeURIComponent(
                                  CleanSearchKey(pill)
                                )}/${pillData?.id || ""}`}
                              >
                                <div
                                  key={pillIndex}
                                  className="about-song-pills"
                                >
                                  {pill}
                                </div>
                              </Link>
                            );
                          } else if (item.title === "Language") {
                            // Navigate to search for language
                            return (
                              <Link
                                to={`/search?searchKeyword=${encodeURIComponent(
                                  pill
                                )}`}
                              >
                                <div
                                  key={pillIndex}
                                  className="about-song-pills"
                                >
                                  {pill}
                                </div>
                              </Link>
                            );
                          } else if (item.title === "Events") {
                            // Events are not clickable
                            return (
                              <Link
                                to={`/search?searchKeyword=${encodeURIComponent(
                                  pill
                                )}`}
                              >
                                <div
                                  key={pillIndex}
                                  className="about-song-pills"
                                >
                                  {pill}
                                </div>
                              </Link>
                            );
                          }
                          return null;
                        })}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
          {/* track pricing section */}
          <div className="fl pricing">
            {/* short form videos cards */}
            <div className="pricing-container">
              <div
                className={`pricing-header-container ${
                  openCards[0] ? "open" : "closed"
                }`}
                onClick={() => toggleContainer(0)}
              >
                <div className="pricing-header">
                  <div>
                    <h1>Short Form Videos</h1>
                    <h2>For use on Instagram Reels & YouTube Shorts</h2>
                  </div>
                  <div className="pricing-header-price">
                    <span>₹</span>
                    <p>
                      {trackData.SKU?.[0]?.sellingPrice.toLocaleString("en-IN")}
                    </p>
                    {/* <h2>(Inclusive of all taxes)</h2> */}
                  </div>
                </div>
                <div className="pricing-header-icon">
                  <div className="arrow-container">
                    <IoIosArrowDown size={16} color="#01508F" />
                  </div>
                </div>
              </div>
              {openCards[0] && (
                <div className="pricing-details shorts-spacing ">
                  <div className="short-pros-cons-container">
                    <div className="short-pros">
                      {shortPros.pros.map((item, index) => (
                        <div key={index} className="short-pros-item">
                          <span>
                            <ImCheckmark size={14} color="#169516" />
                          </span>
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                    <div className="short-cons">
                      {shortPros.cons.map((item, index) => (
                        <div key={index} className="short-cons-item">
                          <span>
                            <ImCross size={10} color="#D45555" />
                          </span>
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <a
                    style={{
                      color: "#000",
                      opacity: "0.7",
                      fontSize: "14px",
                      fontFamily: "Raleway",
                    }}
                    href="#"
                  >
                    Click here for License Terms
                  </a>
                  <div className="shorts-pros-button">
                    <Link to={`/checkout`}>
                      <CustomButton
                        justifyContent="space-between"
                        buyArrow={true}
                        fontSize="14px"
                        text="Buy Now"
                        backgroundColor="var(--button-primary)"
                        textColor="#ffffff"
                        fontWeight="550"
                        width="337px"
                        height="39px"
                        borderRadius="50px"
                        onClick={handleCartClick}
                      />
                    </Link>
                    <CustomButton
                      justifyContent="space-between"
                      text="Add to Cart"
                      backgroundColor="#E6E8EA"
                      textColor="#0D0D0D"
                      fontWeight="600"
                      width="337px"
                      height="39px"
                      borderRadius="50px"
                      fontSize="14px"
                      iconColor="#0D0D0D"
                      icon={<RiShoppingCart2Line />}
                      onClick={handleCartClick}
                    />
                  </div>
                </div>
                // </div>
              )}
            </div>
            {/* buy pack and save more cards */}
            {/* <div className="pricing-saving-band">
              <div style={{ marginLeft: 0 }} className="pricing-container">
                <div
                  className={`pricing-header-container ${
                    openCards[1] ? "open" : "closed"
                  }`}
                  onClick={() => toggleContainer(1)}
                >
                  <div className="pricing-header">
                    <div>
                      <h1>Buy Pack & Save More</h1>
                      <h2>For use on Instagram Reels & YouTube Shorts</h2>
                    </div>
                  </div>
                  <div className="pricing-header-icon">
                    <div className="arrow-container">
                      <IoIosArrowDown size={16} color="#01508F" />
                    </div>
                  </div>
                </div>
                {openCards[1] && (
                  <div className="pricing-details">
                    <PackCard
                      packCards={pricingBuyPackCards}
                      showPrice={false}
                      packType="savemoretrack"
                    />

                    <div>
                      <CustomButton
                        text="View All Packs"
                        backgroundColor="var(--button-primary)"
                        borderRadius="50px"
                        borderColor="none"
                        width="679px"
                        height="39px"
                        textColor="#fff"
                        iconColor="#fff"
                        fontSize="15px"
                        fontFamily="Inter"
                        fontWeight="bold"
                        icon={<IoIosArrowForward />}
                        justifyContent="space-between"
                      />
                    </div>
                  </div>
                )}
              </div>
              <span>EXTRA SAVINGS</span>
            </div> */}
            {/* long form videos cards */}
            <div className="pricing-container">
              <div
                className={`pricing-header-container ${
                  openCards[2] ? "open" : "closed"
                }`}
                onClick={() => toggleContainer(2)}
              >
                <div className="pricing-header">
                  <div>
                    <h1>Long Form Videos</h1>
                    <h2>
                      For use on DVCs, TVCs, Webshows etc. on YouTube, OTT, and
                      Broadcast
                    </h2>
                  </div>
                  {/* <div className="pricing-header-price">
                    <span>₹</span>
                    <p>19,999</p>
                  </div> */}
                </div>
                <div className="pricing-header-icon">
                  <div className="arrow-container">
                    <IoIosArrowDown size={16} color="#01508F" />
                  </div>
                </div>
              </div>
              {openCards[2] && (
                <div className="pricing-details">
                  <h1 className="pricing-long-form-description">
                    Ready to elevate your brand with the power of music? Share
                    your usage details with us and our team will get back to you
                    with a customized quote. Thank you.
                  </h1>
                  <div>
                    <CustomButton
                      text="Request a Quote"
                      backgroundColor="var(--button-primary)"
                      borderRadius="50px"
                      borderColor="none"
                      width="685px"
                      height="44px"
                      textColor="#fff"
                      iconColor="#fff"
                      fontSize="15px"
                      fontFamily="Inter"
                      fontWeight="bold"
                      icon={<IoIosArrowForward />}
                      justifyContent="space-between"
                      onClick={handleRequestQuote}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* benefits section */}
            <div className="pricing-tracks-benifits">
              {tracksBenefits.map((benefit, index) => (
                <div key={index} className="pricingbenefit-container">
                  <div className="benefit-icon-container">
                    <img src={benefit.image} alt={benefit.title} />
                  </div>
                  <h1>{benefit.title}</h1>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* other section displayed from here and inside this div only */}
      <div className="outer-full fl trackPacks">
        {/* packs with similiar track container */}
        {tracksInPack?.length > 0 ? (
          <div className="track-pack-container outer-full">
            <div className="inner">
              <h1 className="pack-contianer-heading">
                Explore Packs with this Track
              </h1>
              <div className="packs-description-view">
                <h2>Buy this track at a discounted price in these packs</h2>
                <div className="packs-viewall">
                  <p>View All</p>
                  <p>
                    <IoIosArrowForward color="#373737" />
                  </p>
                </div>
              </div>
              <div className="inner">
                <PackCard
                  packCards={tracksInPack}
                  packType="similartrackpack"
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* explore similar tracks container */}
        {/* <div className="similar-track-cards-container outer-full">
          <div className="inner">
            <h1 className="simHeading">Explore Similar Tracks</h1>
            <div className="similar-track-cards">
            <TrackCard
              trackCard={simTracks}
              btnWidth="186px"
              trackType="contemporary"
            />
            </div>
          </div>
        </div> */}

        {/* explore similar tracks by use cases */}
        {trackData.filters?.usecase[1] && (
          <div className="similar-track-cards-container outer-full">
            <div className="inner">
              <h1 className="simHeading">
                Explore More{" "}
                {trackData.filters?.usecase[1]?.name?.length > 40
                  ? trackData.filters.usecase[1].name.substring(0, 40) + "..."
                  : trackData.filters.usecase[1].name}{" "}
                Tracks
              </h1>
              <div className="similar-track-cards">
                <TrackCard
                  trackCard={useCaseTracks}
                  btnWidth="186px"
                  trackType="contemporary"
                />
              </div>
            </div>
          </div>
        )}
        {/* explore similar tracks by artists */}
        <div className="similar-track-cards-container outer-full no-bg">
          <div className="inner">
            <h1 className="simHeading">
              Explore More Tracks by{" "}
              {trackData.primaryArtists
                ?.map((artist) => artist.artistName)
                .join(", ").length > 40
                ? trackData.primaryArtists
                    ?.map((artist) => artist.artistName)
                    .join(", ")
                    .substring(0, 40) + "..."
                : trackData.primaryArtists
                    ?.map((artist) => artist.artistName)
                    .join(", ")}{" "}
            </h1>
            <div className="similar-track-cards">
              <TrackCard
                trackCard={artistTracks}
                btnWidth="186px"
                trackType="contemporary"
              />
            </div>
          </div>
        </div>

        {/* explore similar tracks by events */}
        {eventTracks.length > 0 && (
          <div className="similar-track-cards-container outer-full">
            <div className="inner">
              <h1 className="simHeading">
                Explore More{" "}
                {trackData.events?.map((event) => event).join(", ").length > 40
                  ? trackData.events
                      ?.map((event) => event)
                      .join(", ")
                      .substring(0, 40) + "..."
                  : trackData.events?.map((event) => event).join(", ")}{" "}
                Tracks
              </h1>

              <div className="similar-track-cards">
                <TrackCard
                  trackCard={eventTracks}
                  btnWidth="186px"
                  trackType="contemporary"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* request quote screen */}
      <RequestLicense
        isOpen={isRequestOpen}
        onClose={() => setIsRequestOpen(false)}
        licenseData={licenseData}
      />
    </div>
  );
};

export default TrackPage;
