import React, { useState, useEffect } from "react";
import styles from "../../styles/MyAccountCss/Purchases.module.css";
import MiniTrackCard from "../../components/CardsComponent/TrackCard/MiniTrackCard";
import { TbFileInvoice } from "react-icons/tb";
import { LuDownload } from "react-icons/lu";
import { IoMdAdd } from "react-icons/io";
import { FiCheck, FiUpload } from "react-icons/fi";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import randomDefaultImage from "../../utils/RandomDefaultImage";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { getPack } from "../../service/PackService";

const Purchases = () => {
  const [selectedTab, setSelectedTab] = useState("All"); // state to manage the pill/button selected
  const [creditLimit, setCreditLimit] = useState(3); // Track the credit limit
  const [purchaseData, setPurchaseData] = useState([]); // State to store data from localStorage
  const [expandedCardIndex, setExpandedCardIndex] = useState(null); // Tracks which card is expanded
  const [cardInputs, setCardInputs] = useState({}); // Track inputs for each card
  const [viewPackTracks, setViewPackTracks] = useState(false); // to display all the track in the packs
  const [packTrack, setPackTracks] = useState([]); // to store the pack tracks
  const [packName, setPackName] = useState(""); // to store the pack name for which all packs tracks are being displayed

  // Fetch data from localStorage on component mount
  useEffect(() => {
    const data = localStorage.getItem("purchaseData");
    if (data) {
      setPurchaseData(JSON.parse(data)); // Parse and set data
    }
  }, []);

  // function to handle tab swtich
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  // Toggle the expanded state of the clicked card
  const toggleCardExpansion = (index) => {
    setExpandedCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Handle adding a new link input field for a specific card
  const handleAddLink = (cardIndex) => {
    setCardInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs };
      const currentInputs = updatedInputs[cardIndex] || [];
      if (currentInputs.length < creditLimit) {
        updatedInputs[cardIndex] = [...currentInputs, ""]; // Add an empty input for this card
      } else {
        alert("No more credits left!"); // Optional: Show a message if limit is reached
      }
      return updatedInputs;
    });
  };

  // Handle input change for a specific card
  // const handleInputChange = (cardIndex, inputIndex, value) => {
  //   setCardInputs((prevInputs) => {
  //     const updatedInputs = { ...prevInputs };
  //     const currentInputs = updatedInputs[cardIndex] || [];
  //     currentInputs[inputIndex] = value;
  //     updatedInputs[cardIndex] = currentInputs;
  //     return updatedInputs;
  //   });
  // };

  const youtubeRegex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/)|youtu\.be\/)(?:(?:\w|-){11})(?:\S+)?$/;
  const instagramRegex =
    /^(https?:\/\/)?(www\.)?instagram\.com\/(reel|reels)\/([A-Za-z0-9_-]+)\/?(\?.*)?$/;

  const handleInputChange = (cardIndex, inputIndex, value) => {
    setCardInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs };
      const currentInputs = updatedInputs[cardIndex] || [];
      currentInputs[inputIndex] = {
        value, // Store the input value
        isValid: youtubeRegex.test(value) || instagramRegex.test(value), // Validate the URL
      };
      updatedInputs[cardIndex] = currentInputs;
      return updatedInputs;
    });
  };

  // Format today's date for "Date of Purchase"
  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // fucntion to download the track
  const handleTrackDownload = async (url, filename) => {
    try {
      const response = await fetch(url, { method: "GET" });
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }
      const blob = await response.blob(); // Convert response to Blob
      const blobUrl = URL.createObjectURL(blob); // Create a temporary object URL

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename; // Set the custom filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the DOM

      URL.revokeObjectURL(blobUrl); // Release the object URL after download
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const renderTrackCard = (item, index) => {
    // console.log(item.preText, "item track");

    return (
      <div
        key={index}
        className={`${styles.cardContainer} ${
          expandedCardIndex === index ? styles.expanded : ""
        }`}
      >
        <div className={styles.cardHeader}>{item.preText}</div>
        <div className={styles.cardDetails}>
          {/* passing the track data for global player */}
          <MiniTrackCard trackInfo={item} />
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>Date of purchase</p>
            <span className={styles.csdSubHeader}>{getCurrentDate()}</span>
          </div>
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>Use Before</p>
            <span className={styles.csdSubHeader}>03-09-2025</span>
          </div>
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>Total Cost:</p>
            <span className={styles.csdSubHeader}>{item.sp}</span>
          </div>
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>License Type:</p>
            <span className={styles.csdSubHeader}>Short Form Videos</span>
          </div>
          <div className={styles.purchaseDetails}>
            <div className={styles.iconContainer}>
              <TbFileInvoice color="#696969" size={20} />
            </div>
            <div
              onClick={() =>
                handleTrackDownload(item.trackUrl, `${item.preText}.mp3`)
              }
              className={styles.iconContainerDownload}
            >
              Download <LuDownload color="#696969" size={20} />
            </div>
          </div>
        </div>
        <div
          onClick={() => toggleCardExpansion(index)}
          className={styles.creditLinks}
        >
          <div className={styles.creditLimit}>
            <div className={styles.limitNumber}>3</div>
            <p className={styles.limitText}>CREDITS</p>
          </div>
          <div className={styles.linkText}>
            <p>
              {expandedCardIndex === index
                ? "Click to Collapse"
                : "Click to Expand"}
            </p>
            {expandedCardIndex === index ? (
              <IoIosArrowUp size={20} />
            ) : (
              <IoIosArrowDown size={20} />
            )}
          </div>
          <div className={styles.linkTextAddBtn}>
            <p> Add Usage Link</p>
            <IoMdAdd />
          </div>
        </div>
        {/* New content revealed when the card is expanded */}
        {expandedCardIndex === index && (
          <div className={styles.expandedContent}>
            <div className={styles.useageLinkContainer}>
              {/* <p className={styles.useageHeader}>Add Usage Links</p> */}
              <div className={styles.addLinks}>
                <div
                  onClick={() => handleAddLink(index)}
                  className={styles.addlinkText}
                >
                  <p> Add Usage Link</p>
                  <IoMdAdd />
                </div>
              </div>
              <div className={styles.creditLimit}>
                <p className={styles.limitTextExpand}>CREDITS LEFT:</p>
                <div className={styles.limitNumber}>
                  {creditLimit - (cardInputs[index]?.length || 0)}
                </div>
              </div>
            </div>
            {/* Dynamic Input Fields */}
            {(cardInputs[index] || []).map((input, inputIndex) => (
              <>
                <div className={styles.inputContainer} key={inputIndex}>
                  <div className={styles.inputSection}>
                    <p className={styles.inputLabel}>{`Link ${
                      inputIndex + 1
                    }`}</p>
                    <input
                      type="text"
                      placeholder={`Add Link ${inputIndex + 1}`}
                      className={styles.inputBox}
                      value={input.value || ""}
                      onChange={(e) =>
                        handleInputChange(index, inputIndex, e.target.value)
                      }
                    />
                  </div>
                  {/* <div
                  className={styles.iconButton}
                  style={{
                    backgroundColor: value ? "#40FC00" : "#7ECDFF",
                  }}
                >
                  {value ? (
                    <FiCheck color="black" size={15} />
                  ) : (
                    <FiUpload color="black" size={15} />
                  )}
                </div> */}
                  <div
                    className={styles.iconButton}
                    style={{
                      backgroundColor: input.isValid ? "#40FC00" : "#7ECDFF",
                    }}
                  >
                    {input.isValid ? (
                      <FiCheck color="black" size={15} />
                    ) : (
                      <FiUpload color="black" size={15} />
                    )}
                  </div>
                </div>
                {!input.isValid && input.value && (
                  <p className={styles.errorMessage}>Incorrect URL</p>
                )}
              </>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderPackCard = (item, index) => {
    // console.log(item, "pack item");

    return (
      <div
        key={index}
        className={`${styles.cardContainer} ${
          expandedCardIndex === index ? styles.expanded : ""
        }`}
      >
        <div className={styles.cardHeader}>{item.preText}</div>
        <div className={styles.cardDetails}>
          <div
            className={styles.packCard}
            style={{
              backgroundImage: `url(${
                item.cardImg || randomDefaultImage("pack")
              })`,
            }}
          ></div>
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>Date of purchase</p>
            <span className={styles.csdSubHeader}>{getCurrentDate()}</span>
          </div>
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>Use Before</p>
            <span className={styles.csdSubHeader}>03-09-2025</span>
          </div>
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>Total Cost:</p>
            <span className={styles.csdSubHeader}>
              {Number(item.sp).toLocaleString("en-IN")}
            </span>
          </div>
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>License Type:</p>
            <span className={styles.csdSubHeader}>Short Form Videos</span>
          </div>
          <div className={styles.purchaseDetails}>
            <div className={styles.iconContainer}>
              <TbFileInvoice color="#696969" size={20} />
            </div>
            <div className={styles.iconContainerDownload}>
              Download <LuDownload color="#696969" size={20} />
            </div>
          </div>
        </div>
        <div
          onClick={() => handleViewAllTracks(item.code, item.preText)}
          className={styles.viewPackTracks}
        >
          View Tracks <FaChevronRight size={16} />
        </div>
      </div>
    );
  };

  const handleViewAllTracks = async (code, packName) => {
    // console.log("Pack code:", code);
    try {
      const data = await getPack(code); // Fetch the pack data using the packCode
      // console.log(data.data.tracks);
      const formattedTracks = data.data.tracks.map((track) => ({
        cardType: "track",
        preText: track.name, // name from the API response
        secText: track.primaryArtists
          .map((artist) => artist.artistName)
          .join(", "), // Combine artist names
        code: track.trackCode, // trackCode from the API response
        sp: Number(track.SKU[0]?.sellingPrice).toLocaleString("en-IN"), // selling price
        cp: Number(track.SKU[0]?.costPrice).toLocaleString("en-IN"), // cost price
        cardImg: track.imageUrl, // imageUrl from the API response
        trackJson: track.waveformLink, // waveformLink from the API response
        trackUrl: track.mp3Link || track.link || track.waveformLink, // fallback to available links
        quantity: 1, // static
      }));

      // console.log(formattedTracks, "formattedTracks");
      setPackTracks(formattedTracks);
      setPackName(packName);
      setViewPackTracks(true);
    } catch (error) {
      setViewPackTracks(false);
    }
  };

  const handleBack = () => {
    setViewPackTracks(false);
    setPackTracks([]);
  };

  return (
    <div className={styles.purchaseContainer}>
      <div className={styles.purchaseHeaderSection}>
        <h1 className={styles.containerHeading}>My Purchases</h1>
        <p className={styles.containerSubHeading}>
          Add your content links to complete verification and activate full
          licensing coverage. Accurate link submissions ensure a seamless
          claim-free experience.
        </p>
        {viewPackTracks === false && (
          <div className={styles.containerPills}>
            {["All", "Tracks", "Packs"].map((tab) => (
              <div
                key={tab}
                className={`${styles.pills} ${
                  selectedTab === tab ? styles.selectedPill : ""
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Content Section Based on Selected Tab */}
      {/* {viewPackTracks === false && (
        <div className={styles.purchaseDataSection}>
          {purchaseData
            .filter((item) =>
              selectedTab === "All"
                ? true
                : selectedTab === "Tracks"
                ? item.cardType === "track"
                : selectedTab === "Packs"
                ? item.cardType === "pack"
                : false
            )
            .map((item, index) =>
              item.cardType === "track"
                ? renderTrackCard(item, index)
                : renderPackCard(item, index)
            )}
        </div>
      )} */}
      {/* Content Section Based on Selected Tab */}
      {viewPackTracks === false && (
        <div className={styles.purchaseDataSection}>
          {purchaseData.filter((item) =>
            selectedTab === "All"
              ? true
              : selectedTab === "Tracks"
              ? item.cardType === "track"
              : selectedTab === "Packs"
              ? item.cardType === "pack"
              : false
          ).length === 0 ? (
            <p className={styles.noData}>No data found</p>
          ) : (
            purchaseData
              .filter((item) =>
                selectedTab === "All"
                  ? true
                  : selectedTab === "Tracks"
                  ? item.cardType === "track"
                  : selectedTab === "Packs"
                  ? item.cardType === "pack"
                  : false
              )
              .map((item, index) =>
                item.cardType === "track"
                  ? renderTrackCard(item, index)
                  : renderPackCard(item, index)
              )
          )}
        </div>
      )}
      {viewPackTracks && (
        <div className={styles.purchaseDataSection}>
          <h1 onClick={handleBack} className={styles.packName}>
            <FaChevronLeft size={18} />
            {packName}{" "}
          </h1>
          {packTrack.map((item, index) => renderTrackCard(item, index))}
        </div>
      )}
    </div>
  );
};

export default Purchases;
