import axios from "axios";
import config from "../utils/config";

const trackInPack = async (trackCode, limit = 3) => {
  const url = `${config.baseUrl}packs/track/${trackCode}`;

  try {
    const res = await axios.get(url);

    let mappedCards = null;
    if (res?.data) {
      let response = res?.data?.data;
      // console.log(response, "response");

      mappedCards = response.packs.map((pack) => {
        const { costPrice, sellingPrice } = pack.SKU[0];
        const discountPercent = costPrice
          ? `${((1 - sellingPrice / costPrice) * 100).toFixed(0)}% Off`
          : "0% Off"; // Calculate discount or fallback to "0% OFF"

        return {
          image:
            `${config.s3imgUrl}web/playlists/${pack.playlistCode}.webp` || "",
          title: pack.name,
          buttonText: "Explore",
          buttonLink: `#${pack.name_slug}`, // Link to the pack
          totalTracks: pack.trackCount,
          description: pack.description,
          packCode: pack.playlistCode,
          packNameSlug: pack.name_slug,
          type: pack.markerType,
          sellingPrice:
            JSON.stringify(pack.SKU[0].sellingPrice) ||
            pack.SKU[0].sellingPrice ||
            "99999",
          costPrice: JSON.stringify(pack.SKU[0].costPrice) || "129999",
          discountPercent, // Add calculated discount
        };
      });

      // mappedCards = response.packs.map((pack) => ({

      // }));
    }
    // let finalPackCards = { data: {mappedCards} }

    // console.log(mappedCards.length, "mappedCards");
    return mappedCards;
  } catch (error) {
    console.error("Error fetching pack data:", error);
    return { data: [] };
  }
};

export { trackInPack };
