import React, { useState, useEffect } from "react";
import styles from "../../styles/MyAccountCss/Collection.module.css";
import MiniTrackCard from "../../components/CardsComponent/TrackCard/MiniTrackCard";
import { FaHeart, FaChevronLeft } from "react-icons/fa";
import { getUserPlaylist } from "../../service/UserService";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "../../utils/firebase/remoteConfig";
import { getCookie } from "../../utils/AuthContext";
import randomDefaultImage from "../../utils/RandomDefaultImage";
import { AiOutlineDelete } from "react-icons/ai";

const MyCollection = () => {
  const userPhoneNumber = getCookie("userPhoneNumber"); // Get the user's phone number from cookies
  const [playlists, setPlaylists] = useState([]); // State for storing playlists from API
  const [selectedPlaylist, setSelectedPlaylist] = useState(null); // State for selected playlist ID

  useEffect(() => {
    const fetchUserPlaylist = async () => {
      try {
        await fetchAndActivate(remoteConfig); // Fetch and activate Remote Config
        const jsonString = getValue(remoteConfig, "auth").asString(); // Fetch the auth config
        const parsedConfig = JSON.parse(jsonString);

        // Find the object where the phone number matches
        const matchingEntry = Object.entries(parsedConfig).find(
          ([phoneNumber, userData]) => phoneNumber === userPhoneNumber
        );

        if (matchingEntry) {
          const [, userData] = matchingEntry;
          const userPlaylist = await getUserPlaylist(userData.token);
          // console.log(userPlaylist, "34");

          if (userPlaylist?.info?.status === 200) {
            setPlaylists(userPlaylist.data.playlist);
          } else {
            console.warn("No playlists found in the response.");
          }
        } else {
          console.warn("No matching user data found for phone number.");
        }
      } catch (error) {
        console.error("Error fetching user playlist:", error);
      }
    };

    fetchUserPlaylist();
  }, [userPhoneNumber]); // Dependency array ensures the effect runs when the phone number changes

  const renderTrackCard = (track, index) => {
    const trackInfo = {
      preText: track.name,
      secText: track.trackArtist || "Artist",
      code: track.trackCode,
      cardImg: track.imageUrl, // Fallback for missing image
      trackJson: track.waveformLink,
      trackUrl: track.mp3Link,
    };
    return (
      <div key={index} className={styles.tcardContainer}>
        <MiniTrackCard trackInfo={trackInfo} />
        <div className={styles.trackDetails}>
          <div className={styles.trackInfo}>
            <div>
              <p className={styles.trackHeading}>{track.name}</p>
              <span className={`${styles.trackSubHeading} ${styles.trackName}`}>
                {track.trackArtist || "Artist"}
              </span>
            </div>
            {/* <div className={styles.trackHeading}>
              ₹{track.trackPrice || "29999"}
            </div> */}
            <div className={styles.deleteIcon}>
              <AiOutlineDelete size={18} onClick={handleTrackDelete} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleBackToPlaylists = () => {
    setSelectedPlaylist(null); // Reset to show the playlist cards
  };

  const handleTrackDelete = () => {
    console.log("deleting track");
  };

  return (
    <div className={styles.collectionContainer}>
      <h1 className={styles.collectionHeading}>My Playlists</h1>
      {selectedPlaylist ? (
        <div>
          <div
            onClick={handleBackToPlaylists}
            className={styles.backToPlaylists}
          >
            <FaChevronLeft size={16} color="#000" />
            <span className={styles.playlistNameActive}>
              {playlists.find((playlist) => playlist.id === selectedPlaylist)
                ?.name || "Unknown Playlist"}
            </span>
          </div>
          <div className={styles.trackListContainer}>
            {playlists.find((playlist) => playlist.id === selectedPlaylist)
              ?.tracks.length === 0 ? (
              <p className={styles.noDataMessage}>No tracks found.</p>
            ) : (
              playlists
                .find((playlist) => playlist.id === selectedPlaylist)
                ?.tracks.map((track, index) => renderTrackCard(track, index))
            )}
          </div>
        </div>
      ) : (
        <div className={styles.collContent}>
          {playlists.length === 0 ? (
            <p className={styles.noDataMessage}>No playlists found.</p>
          ) : (
            playlists.map((playlist) => (
              <div
                key={playlist.id}
                className={styles.playlistCardContainer}
                onClick={() => setSelectedPlaylist(playlist.id)}
              >
                <div
                  style={{
                    backgroundImage: `url(${randomDefaultImage("pack")})`,
                  }}
                  className={styles.playlistCard}
                >
                  <div className={styles.trackLength}>
                    <p className={styles.trackNumber}>{playlist.totalTracks}</p>
                    <span className={styles.numberHeading}>Tracks</span>
                  </div>
                </div>
                <p className={styles.playlistName}>{playlist.name}</p>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default MyCollection;
