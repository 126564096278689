import React, { useContext } from "react";
import { IoClose } from "react-icons/io5";
import { BiSolidMessageSquareError } from "react-icons/bi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { ToastContext } from "../../utils/ToastContext";

const ToastComponent = () => {
  const { heading, message, isVisible, closeToast, type } =
    useContext(ToastContext);

  if (!isVisible) return null;

  const getIcon = () => {
    return type === "success" ? (
      <IoMdCheckmarkCircleOutline size={24} />
    ) : (
      <BiSolidMessageSquareError size={24} />
    );
  };

  const getBackgroundColor = () => {
    return type === "success" ? "#28a745" : "red";
  };

  return (
    <div style={styles.toast}>
      <div style={{ ...styles.icon, backgroundColor: getBackgroundColor() }}>
        <span style={styles.icon}>{getIcon()}</span>
      </div>
      <div style={styles.messageContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "flex-end",
            fontWeight: 550,
          }}
        >
          {type === "success" ? (
            <p style={{ ...styles.messageHeader, color: "#28a745" }}>
              {heading}
            </p>
          ) : (
            // <p style={{ ...styles.messageHeader, color: "#28a745" }}>
            //   Added to cart
            // </p>
            <p style={{ ...styles.messageHeader, color: "red" }}>Error</p>
          )}
          <IoClose size={24} style={styles.closeButton} onClick={closeToast} />
        </div>
        <p style={styles.message}>{message}</p>
      </div>
    </div>
  );
};

export default ToastComponent;

const styles = {
  toast: {
    position: "fixed",
    top: "20px",
    right: "20px",
    backgroundColor: "#fff",
    color: "#333",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
    zIndex: "10000",
    display: "flex",
    alignItems: "stretch",
    width: "380px",
    justifyContent: "space-between",
    minHeight: "80px",
    fontFamily: "Inter",
  },
  icon: {
    display: "flex",
    flex: 0.8,
    alignItems: "center",
    justifyContent: "center",
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    color: "#ffffff",
  },
  messageContainer: {
    flex: 3,
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
  },
  messageHeader: {
    fontSize: "18px",
    margin: 0,
    flex: 1,
  },
  message: {
    fontSize: "16px",
    marginTop: "4px",
  },
  closeButton: {
    cursor: "pointer",
    margin: "10px 5px 0 0",
  },
};
