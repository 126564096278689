import axios from "axios";
import config from "../utils/config";
import { urls } from "../utils/AppManager";

export const packLike = async (payload, token) => {
  const url = `${config.baseUrl}${urls.playlistLike}`;
  try {
    const res = await axios.post(url, payload, {
      headers: {
        Authorization: token, // Add the token here with brearer key word already present
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const trackLike = async (payload, token) => {
  const url = `${config.baseUrl}${urls.trackLike}`;
  try {
    const res = await axios.post(url, payload, {
      headers: {
        Authorization: token, // Add the token here with brearer key word already present
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const getLikes = async (token) => {
  const url = `${config.baseUrl}${urls.getLikes}`;
  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const createPlaylist = async (payload, token) => {
  const url = `${config.baseUrl}${urls.userCreatePlayList}`;
  try {
    const res = await axios.post(url, payload, {
      headers: {
        Authorization: token, // Add the token here with brearer key word already present
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const addTrackToPlaylist = async (payload, token, playlistId) => {
  // 2f9e21d1-9b8a-4cbd-a0ab-fe8b4fccb43f
  const url = `${config.baseUrl}${urls.addTrackToPlaylist}/${playlistId}`;
  try {
    const res = await axios.post(url, payload, {
      headers: {
        Authorization: token, // Add the token here with brearer key word already present
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const getUserPlaylist = async (token) => {
  const url = `${config.baseUrl}${urls.getUserPlaylist}`;
  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: token, // Add the token here with brearer key word already present
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};
